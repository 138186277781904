<template>
  <el-form
    ref="data"
    :disabled="true"
    :model="data"
    label-width="100px"
    size="mini"
  >
    <el-row>
      <i class="icon"></i>
      <span class="nav">公司简介</span>
    </el-row>
    <div>
      <div class="company">
        <el-form-item label="公司名称">
          {{ itemDesignPlan.companyName || '' }}
        </el-form-item>
      </div>
      <el-form-item label="公司描述">
        {{ itemDesignPlan.companyDesc || '' }}
      </el-form-item>
    </div>
    <el-row>
      <i class="icon"></i>
      <span class="nav">图纸</span>
    </el-row>
    <div>
      <property-photo :designId="designId"></property-photo>
    </div>
    <el-row>
      <i class="icon"></i>
      <span class="nav">方案设计</span>
    </el-row>
    <div>
      <el-form-item label="设计理念">
        {{ itemDesignPlan.idea || '' }}
      </el-form-item>
      <div class="form">
        <el-form-item label="总户数">
          {{ itemDesignPlan.households || '' }}
        </el-form-item>
        <el-form-item label="建筑面积">
          {{ itemDesignPlan.buildingArea || '' }} m<sup>2</sup>
        </el-form-item>
      </div>
      <div class="form">
        <el-form-item label="目标销售单价">
          {{ upMoneyType(itemDesignPlan.unitPriceTarget) || '' }}
        </el-form-item>
        <el-form-item label="房产参考总价">
          {{ upMoneyType(itemDesignPlan.referenceTotalPrice) || '' }}
        </el-form-item>
      </div>
      <div class="form">
        <el-form-item label="户型数">
          {{ itemDesignPlan.houseTypeNum || '' }}
        </el-form-item>
      </div>
      <div
        v-for="(item, index) in itemDesignPlan.houseTypeList"
        :key="index"
      >
        <div class="form">
          <el-form-item label="户型">
            {{ item.room || '' }}室 {{ item.hall || '' }} 厅 {{ item.bathroom || '' }} 卫
          </el-form-item>
          <el-form-item label="面积">
            {{ item.area || '' }}
          </el-form-item>
        </div>
        <div>
          <el-form-item label="户型图">
            <span class="housepic">
              <img :src="item.attachLink" />
            </span>
          </el-form-item>
        </div>
      </div>
      <div class="form">
        <el-form-item label="规划楼栋">
          {{ itemDesignPlan.buildingNumber || '' }}
        </el-form-item>
        <el-form-item label="规划车位">
          {{ itemDesignPlan.parkingSpaceNumber || '' }}个
        </el-form-item>
        <el-form-item label="装修标准">
          {{ decorationStandardType(itemDesignPlan.decorationStandard) || '' }}
        </el-form-item>
      </div>
      <div class="form">
        <el-form-item label="供水">
          {{ (itemDesignPlan.waterSupplyType === 1 ? '民水' : '商水') || '' }}
        </el-form-item>
        <el-form-item label="供电">
          {{ (itemDesignPlan.electricitySupplyType === 1 ? '民电' : '商电') || '' }}
        </el-form-item>
      </div>
    </div>
    <div>
      <div class="housetype-title">
        <el-row>
          <i class="icon"></i>
          <span class="nav">户型分布</span>
        </el-row>
        <el-form>
          <el-form-item label-width="30px">
            <el-select
              v-model="towerName"
              placeholder="请选择楼栋栋数"
              @change="getTowerList"
              size="mini"
            >
              <el-option
                v-for="(item, index) in itemDesignPlan.towerList"
                :key="index"
                :label="index + 1"
                :value="index + 1"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="form">
        <el-form-item label="单元数">
          <span>{{  houseInfo && houseInfo.unitBuildingNum }}</span>
        </el-form-item>
        <el-form-item label="楼层数">
          <span>{{ houseInfo && houseInfo?.floorNum }}</span>
        </el-form-item>
        <el-form-item label="每层户数">
          <span>{{  houseInfo && houseInfo.houseNum }}</span>
        </el-form-item>
      </div>
      <div
        class="distribute"
        v-show="isShowDistribute"
      >
        <div class="left">
          <ul>
            <li
              :class="{ active: index === active }"
              v-for="(item, index) in houseInfo?.unitBuildingNum"
              :key="index"
              @click="getUnitNum(item, index)"
            >
              {{ index + 1 }}单元
            </li>
          </ul>
        </div>
        <div class="right">
          <div
            class="right-content"
            v-for="(item, index) in houseList"
            :key="index"
          >
            <div>
              <span>户号</span>
              <span>{{ index + 1 >= 10 ? index + 1 : '0' + (index + 1) }}</span>
            </div>
            <div>
              <el-form-item label="户型">
                <el-select
                  v-model="item.houseTypeId"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(item, index) in houseType"
                    :key="index"
                    :label="item.houseName"
                    :value="item.houseTypeId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-row>
      <i class="icon"></i>
      <span class="nav">设计工作阶段</span>
    </el-row>
    <el-form-item label="总负责人">
      {{ itemDesignPlan.generalPrincipal || '' }}
    </el-form-item>
    <div class="table">
      <div class="head">
        <div>工作阶段</div>
        <div>设计阶段</div>
        <div>负责人</div>
        <div>所需资金</div>
      </div>
      <div class="tableContent">
        <div
          class="middle"
          style="border-bottom: 1px solid #d8e3ff"
        >
          <div class="title1">图纸设计</div>
          <div class="title2">
            <div class="item">
              <div class="design">概念设计</div>
              <div class="input">
                <el-form-item>
                  <el-select v-model="itemDesignPlan.conceptDesignPrincipal">
                    <el-option
                      v-for="(item, index) in peopleList"
                      :key="index"
                      :label="item.realName"
                      :value="item.realName"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
            <div class="item">
              <div class="design">初步图纸设计</div>
              <div class="input">
                <el-form-item>
                  <el-select v-model="itemDesignPlan.tentativeDesignPrincipal">
                    <el-option
                      v-for="(item, index) in peopleList"
                      :key="index"
                      :label="item.realName"
                      :value="item.realName"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
            <div class="item">
              <div class="design">详细图纸设计</div>
              <div class="input">
                <el-form-item>
                  <el-select v-model="itemDesignPlan.formalDesignPrincipal">
                    <el-option
                      v-for="(item, index) in peopleList"
                      :key="index"
                      :label="item.realName"
                      :value="item.realName"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
          </div>
          <div class="title3">
            <el-form-item>
              <el-input
                placeholder="请输入"
                size="mini"
                v-model="conceptDesignRequiredFunds"
              ><span slot="suffix">万</span>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-input
                placeholder="请输入"
                size="mini"
                v-model="tentativeDesignRequiredFunds"
              ><span slot="suffix">万</span></el-input>
            </el-form-item>
            <el-form-item>
              <el-input
                placeholder="请输入"
                size="mini"
                v-model="formalDesignRequiredFunds"
              ><span slot="suffix">万</span>
              </el-input>
            </el-form-item>
          </div>
        </div>
        <div class="middle">
          <div class="title1">技术支持</div>
          <div class="title2">
            <div class="item">
              <div class="design">项目启动</div>
              <div class="input">
                <el-form-item>
                  <el-select v-model="itemDesignPlan.initialPrincipal">
                    <el-option
                      v-for="(item, index) in peopleList"
                      :key="index"
                      :label="item.realName"
                      :value="item.realName"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
            <div class="item">
              <div class="design">地基处理技术支持</div>
              <div class="input">
                <el-form-item>
                  <el-select v-model="itemDesignPlan.foundationPrincipal">
                    <el-option
                      v-for="(item, index) in peopleList"
                      :key="index"
                      :label="item.realName"
                      :value="item.realName"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
            <div class="item">
              <div class="design">主体结构技术支出</div>
              <div class="input">
                <el-form-item>
                  <el-select v-model="itemDesignPlan.majorStructureTechSupportPrincipal">
                    <el-option
                      v-for="(item, index) in peopleList"
                      :key="index"
                      :label="item.realName"
                      :value="item.realName"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
            <div class="item">
              <div class="design">水电暖通技术支持</div>
              <div class="input">
                <el-form-item>
                  <el-select v-model="itemDesignPlan.installingStructureTechSupportPrincipal">
                    <el-option
                      v-for="(item, index) in peopleList"
                      :key="index"
                      :label="item.realName"
                      :value="item.realName"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
            <div class="item">
              <div class="design">整体验收支持</div>
              <div class="input">
                <el-form-item>
                  <el-select v-model="itemDesignPlan.acceptancePrincipal">
                    <el-option
                      v-for="(item, index) in peopleList"
                      :key="index"
                      :label="item.realName"
                      :value="item.realName"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
          </div>
          <div class="title3">
            <el-form-item>
              <el-input
                size="mini"
                v-model="initialRequiredFunds"
              ><span slot="suffix">万</span>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-input
                size="mini"
                v-model="foundationRequiredFunds"
              ><span slot="suffix">万</span>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-input
                size="mini"
                v-model="majorStructureTechSupportRequiredFunds"
              >
                <span slot="suffix">万</span>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-input
                size="mini"
                v-model="installingStructureTechSupportRequiredFunds"
              >
                <span slot="suffix">万</span>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-input
                size="mini"
                v-model="acceptanceRequiredFunds"
              ><span slot="suffix">万</span>
              </el-input>
            </el-form-item>
          </div>
        </div>
      </div>
    </div>
  </el-form>
</template>

<script>
import ItemDesign from '@/components/IntegrationSolutions/designingSchemeSingle/itemDesign.vue'
import AddPersonnel from '@/components/IntegrationSolutions/addPersonnel.vue'
import { mapGetters } from 'vuex'
import { decorationStandardType } from '@/utils/filters'
import { upMoneyType } from '@/utils/validate'
import PropertyPhoto from './propertyPhoto.vue'
export default {
  components: {
    ItemDesign,
    AddPersonnel,
    PropertyPhoto
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  computed: {
    ...mapGetters(['dictionaries']),
    conceptDesignRequiredFunds () {
      return (this.data?.conceptDesignRequiredFunds / 10000) || 0
    },
    tentativeDesignRequiredFunds () {
      return (this.data?.tentativeDesignRequiredFunds / 10000) || 0
    },
    formalDesignRequiredFunds () {
      return (this.data?.formalDesignRequiredFunds / 10000) || 0
    },
    foundationRequiredFunds () {
      return (this.data?.foundationRequiredFunds / 10000) || 0
    },
    initialRequiredFunds () {
      return (this.data?.initialRequiredFunds / 10000) || 0
    },
    majorStructureTechSupportRequiredFunds () {
      return (this.data?.majorStructureTechSupportRequiredFunds / 10000) || 0
    },
    installingStructureTechSupportRequiredFunds () {
      return (this.data?.installingStructureTechSupportRequiredFunds / 10000) || 0
    },
    acceptanceRequiredFunds () {
      return (this.data?.acceptanceRequiredFunds / 10000) || 0
    }
  },
  data () {
    return {
      tablabel: '2',
      firstId: '',
      itemDesignPlan: {
        id: '',
        companyName: '',
        createCompany: '',
        companyDesc: '',
        attachList: [],
        idea: '',
        households: '',
        buildingArea: '',
        unitPriceTarget: '',
        referenceTotalPrice: '',
        houseTypeNum: 1,
        houseTypeList: [{}],
        buildingNumber: '',
        parkingSpaceNumber: '',
        decorationStandard: '',
        waterSupplyType: '',
        electricitySupplyType: '',
        userList: [],
        generalPrincipal: '',
        generalPrincipalId: '',
        conceptDesignPrincipal: '',
        conceptDesignPrincipalId: '',
        conceptDesignRequiredFunds: '',
        foundationPrincipal: '',
        foundationPrincipalId: '',
        foundationRequiredFunds: '',
        tentativeDesignPrincipal: '',
        tentativeDesignPrincipalId: '',
        tentativeDesignRequiredFunds: '',
        formalDesignPrincipal: '',
        formalDesignPrincipalId: '',
        formalDesignRequiredFunds: '',
        initialPrincipal: '',
        initialPrincipalId: '',
        initialRequiredFunds: '',
        majorStructureTechSupportPrincipal: '',
        majorStructureTechSupportPrincipalId: '',
        majorStructureTechSupportRequiredFunds: '',
        installingStructureTechSupportPrincipal: '',
        installingStructureTechSupportPrincipalId: '',
        installingStructureTechSupportRequiredFunds: '',
        acceptancePrincipal: '',
        acceptancePrincipalId: '',
        acceptanceRequiredFunds: ''
      },
      attachData: [],
      peopleList: [], // 人员名单
      nameList: [], // 公司名称列表
      companyInfo: {}, // 选中公司
      decorationType: [],
      waterSupplyType: [],
      electricitySupplyType: [],
      decorationStandardType,
      upMoneyType,
      houseInfo: {
        floorNum: null,  // 楼层数
        houseNum: '',  // 每层户数
        towerName: '',    // 楼栋名
        unitBuildingNum: '',  // 单元数
        unitBuildingList: [{   // 单元楼
          houseList: [{ houseTypeId: null, houseNumber: null, houseName: '' }],  // 户型id(户型数组索引)  门牌号（后两位）
          unitBuildingName: '' // 单元名
        }]
      },
      unitBuildingList: {   // 单元楼
        houseList: [{ houseTypeId: null, houseNumber: null, houseName: '' }],  // 户型id(户型数组索引)  门牌号（后两位）
        unitBuildingName: '' // 单元名
      },
      towerName: '',
      houseList: [],   // 户型
      isShowDistribute: false,
      attachLink: '',
      active: 0,    // 单元高亮
      houseType: [],
      designId: '' // 设计方案id
    }
  },
  watch: {
    data: {
      handler (newval, oldval) {
        if (newval) {
          this.itemDesignPlan = newval
          this.towerName = 1
          this.houseInfo = newval?.towerList && newval?.towerList[0]
          for (var i = 0; i < newval?.houseTypeNum; i++) {
            this.houseType.push({ houseName: '户型' + (i + 1), houseTypeId: i })
          }
          this.houseList = this.houseInfo?.unitBuildingList[0]?.houseList
          if (this.houseInfo?.unitBuildingNum !== '' && this.houseInfo?.houseNum !== '') {
            this.isShowDistribute = true
          } else {
            this.isShowDistribute = false
          }
          this.designId = newval.id
        }
      },
      deep: true,
      // 立即执行
      immediate: true
    }
  },
  async created () {
    await this.$store.dispatch('dictionaries', 'decoration_standard')
    this.decorationType = this.dictionaries['decoration_standard']
    await this.$store.dispatch('dictionaries', 'water_supply_type')
    this.waterSupplyType = this.dictionaries['water_supply_type']
    await this.$store.dispatch('dictionaries', 'electricity_supply_type')
    this.electricitySupplyType = this.dictionaries['electricity_supply_type']
  },
  methods: {
    // 切换楼栋
    getTowerList (val) {
      this.active = 0
      this.houseInfo = this.itemDesignPlan.towerList[val - 1]
      if (this.houseInfo.unitBuildingNum !== '' && this.houseInfo.houseNum !== '') {
        this.isShowDistribute = true
      } else {
        this.isShowDistribute = false
      }
      this.houseList = this.houseInfo.unitBuildingList[0]?.houseList
    },
    // 切换单元
    getUnitNum (item, index) {
      this.active = index
      this.houseInfo.unitBuildingList[index].unitBuildingName = (index + 1) + '单元'
      this.unitBuildingList = this.houseInfo?.unitBuildingList[index]
      this.houseList = this.houseInfo.unitBuildingList[index]?.houseList
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
.active {
  background-color: rgba($color: #3a71ff, $alpha: 0.1);
  font-weight: 500;
  border-left: 2px solid #3a71ff;
}

.el-form {
  padding: 0 60px 0 30px;

  .el-row {
    margin: 16px 0;
    height: 30px;
    line-height: 30px;

    .icon {
      display: inline-block;
      width: 4px;
      height: 12px;
      background-color: #3a71ff;
      margin-right: 10px;
    }

    .nav {
      font-size: 14px;
      color: #101010;
      font-weight: 500;
    }
  }
  .housetype-title {
    margin: 16px 0;
    display: flex;
    .el-row {
      margin: 0;
    }
    .el-form-item {
      margin: 0;
      .el-select {
        width: 240px;
      }
    }
  }

  .form {
    display: flex;
    justify-content: space-between;

    .el-form-item {
      .el-input {
        width: 240px;
      }

      .el-select {
        width: 240px;
      }
    }
  }
  .housepic {
    display: inline-block;
    width: 250px;
    height: 150px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .distribute {
    width: 100%;
    height: 380px;
    display: flex;
    justify-content: space-between;
    .left {
      flex: 1;
      margin-right: 20px;
      margin-left: 50px;
      box-shadow: 0px 0px 5px 3px rgba($color: #133efb, $alpha: 0.1);
      border-radius: 4px;
      ul {
        text-align: center;
        overflow: auto;
        height: 100%;
        li {
          height: 40px;
          line-height: 40px;
          margin: 20px 0;
          color: #101010;
          font-size: 14px;
          cursor: pointer;
        }
      }
    }
    .right {
      flex: 3;
      box-shadow: 0px 0px 5px 3px rgba($color: #133efb, $alpha: 0.1);
      border-radius: 4px;
      padding: 0 100px;
      overflow: auto;
      height: 100%;
      .right-content {
        display: flex;
        justify-content: space-between;
        height: 40px;
        line-height: 40px;
        margin: 20px 0;
        span {
          font-size: 14px;
          color: #999999;
          margin-right: 50px;
          &:first-child {
            position: relative;
            &::before {
              position: absolute;
              left: -15px;
              top: 6px;
              content: '';
              width: 5px;
              height: 5px;
              background-color: #3a71ff;
              border-radius: 50%;
            }
          }
        }
        .el-form-item {
          .el-input {
            width: 240px;
          }
        }
      }
    }
  }
}

.table {
  box-sizing: border-box;
  width: 100%;

  .head {
    width: 100%;
    display: flex;
    height: 50px;
    background: rgba(58, 113, 255, 0.1);
    align-items: center;
    font-weight: 400;
    color: #101010;
    font-size: 14px;
    justify-content: space-between;

    div {
      width: 25%;
      text-align: center;
    }
  }

  .tableContent {
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.05);
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
  }

  .middle {
    font-weight: 400;
    color: #101010;
    font-size: 14px;
    display: flex;
    padding-bottom: 10px;

    .title1 {
      width: 25%;
      text-align: center;
      line-height: 150px;
    }

    .title2 {
      margin-top: 10px;
      width: 50%;

      .item {
        width: 100%;
        height: 50px;
        background: rgba(255, 255, 255, 0.1);
        box-shadow: 0px 0px 2px 0px rgba(58, 113, 255, 0.25);
        opacity: 1;
        display: flex;
        line-height: 50px;

        .design {
          width: 50%;
          text-align: center;
        }

        .input {
          margin-top: 10px;
        }
      }

      .el-form-item {
        .el-select {
          width: 140px;
        }
      }
    }

    .title3 {
      width: 10%;
      margin-top: 25px;

      .el-form-item {
        margin-bottom: 23px !important;

        .el-input {
          width: 140px;
        }
      }
    }
  }
}

.list-content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-right: 20px;

  .left-list {
    margin-right: 5px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    width: 100px;
    background-color: #e2eaff;
    box-sizing: border-box;
    padding: 5px 5px;
    border-radius: 3px;
    font-size: 12px;

    .el-icon-close {
      margin-top: 2px;
    }

    .item {
      text-align: center;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #101010;
    }
  }
}

::v-deep .el-tabs__item.is-active {
  background: #d8e3ff;
  color: #3a71ff;
}

::v-deep .el-tabs__active-bar.is-right {
  width: 0;
}

::v-deep .el-tabs__nav {
  width: 300px;
  text-align: center;
}

::v-deep .el-tabs__item {
  color: #999999;
}

::v-deep .el-tabs__nav-scroll {
  background: #ffffff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}

::v-deep .el-tabs--right .el-tabs__nav-wrap.is-right::after {
  height: 0;
  width: 0;
  bottom: none;
  top: 0;
}

@media (max-width: 1440px) {
  ::v-deep .el-tabs__nav {
    width: 230px !important;
    text-align: center;
  }

  .title3 {
    ::v-deep .el-input__inner {
      margin-left: 52px !important;
    }
  }
}

@media (max-width: 1366px) {
  ::v-deep .el-tabs__nav {
    width: 180px !important;
    text-align: center;
  }

  .title3 {
    ::v-deep .el-input__inner {
      margin-left: 52px !important;
    }
  }

  ::v-deep .el-input__inner {
    width: 85% !important;
  }
}

@media (max-width: 1024px) {
  ::v-deep .el-tabs__nav {
    width: 120px !important;
    text-align: center;
  }

  .table {
    padding: 20px 19px !important;
  }

  .title2 {
    .item {
      .input {
        padding-left: 0 !important;
      }
    }
  }

  .title3 {
    ::v-deep .el-input__inner {
      margin-left: 2px !important;
    }
  }

  ::v-deep .el-input__inner {
    width: 90% !important;
  }
}

::v-deep .el-textarea__inner {
  background-color: rgba($color: #f2f3f5, $alpha: 0.7);
}
</style>

<template>
  <div class="address-select">
    <div :class="{ 'content': true, 'content1': index + 1 == grade }" v-for="(item, index) in grade" :key="index">
      <el-select size="mini" v-model="region[index].value" :placeholder="region[index].grade"
        @change="addressselect(index)">
        <el-option v-for="item in region[index].options" :key="item.key" :label="item.title" :value="item.id">
        </el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
import { getLazyTree } from '@/api/base/region'
export default {
  name: 'addressSelect',
  data() {
    return {
      addressCode: '00',
      region: [
        {
          grade: '省份',
          options: [],
          value: ''
        },
        {
          grade: '市',
          options: [],
          value: ''
        },
        {
          grade: '县/区',
          options: [],
          value: ''
        },
        {
          grade: '镇/街道',
          options: [],
          value: ''
        },
        {
          grade: '小区/村',
          options: [],
          value: ''
        }
      ],
      address: '',
      clearControl: false
    }
  },
  props: {
    grade: {
      type: Number,
      default: 4
    },
    defaultAddress: {
      type: Array,
      default: () => {
        return []
      }
    },
    clear: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getLazyTree() {
      const addressCode = this.addressCode
      // console.log(addressCode);
      if (addressCode == '99') {
        for (let i = 4; i >= 0; i--) {
          this.region[i].value = ''
        }
      } else {
        if (addressCode == '00') {
          getLazyTree('00').then(res => {
            if (this.clearControl) {
              // console.log(res.data);
              for (let i = 4; i > 0; i--) {
                this.region[i].value = ''
              }
            }
            this.region[0].options = [...res.data.data]
            this.region[0].options.unshift({
              hasChildren: true,
              id: '99',
              key: '99',
              parentId: '0',
              title: '全部',
              value: '99'
            })
          })
        }
        if (addressCode != '00') {
          getLazyTree(addressCode).then(res => {
            // console.log(res.data);
            const index = this.setgrade((res.data.data[0] || {}).id)
            if (this.clearControl) {
              // console.log(index)
              for (let i = 4; i >= index; i--) {
                this.region[i].value = ''
              }
            }
            this.region[index].options = [...res.data.data]
          })
        }
      }
    },
    addressselect(index) {
      const addressCode = this.region[index].value
      this.addressCode = addressCode
      this.getLazyTree()
      this.clearControl = true
    },
    setgrade(data) {
      const length = (data || '').length
      let res = 0
      switch (length) {
        case 2:
          res = 0
          break
        case 4:
          res = 1
          break
        case 6:
          res = 2
          break
        case 12:
          res = 3
          break
        default:
          res = 4
          break
      }
      return res
    },
    defaultAddressHandle() {
      this.defaultAddress.forEach((item, index) => {
        // console.log('item', item)
        this.addressCode = item
        this.getLazyTree()
        this.region[index].value = item
      })
    },
    handlerClear() {
      if (this.clearControl) {
        // console.log(res.data);
        for (let i = 4; i >= 0; i--) {
          this.region[i].value = ''
          this.region[i].options = []
        }
        this.addressCode = '00'
        this.getLazyTree()
      }
    }
  },
  watch: {
    region: {
      deep: true,
      handler() {
        this.$emit('getAddress', this.region)
      }
    },
    clear: {
      handler(clear) {
        if (clear) {
          this.handlerClear()
        }
      }
    },
    defaultAddress: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.defaultAddressHandle()
        }
      },
      deep: true
    }
  },
  created() {
    this.getLazyTree()
    this.defaultAddressHandle()
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/land/addressSelect.scss"
</style>

<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'App',
  computed: {
    ...mapGetters(['userInfo', 'jurisdiction'])
  },
  watch: {
    jurisdiction: {
      handler(newVal, oldVal) {
        if (newVal === 'true') {
          this.roleLink()
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.roleLink()
  },
  methods: {
    roleLink() {
      // 土地机构跳转到地块管理
      if (this.jurisdiction === 'true' && this.$route.path === '/' && this.userInfo.role_name === 'government') {
        this.$store.commit('SET_JURISDICTION', 'false')
        setTimeout(() => {
          this.$router.push({ path: '/plot/management/index' })
        }, 1000);
        // 地产角色 跳转地产商城
      } else if (this.jurisdiction === 'true' && this.$route.path === '/' && this.userInfo.role_name === 'property') {
        this.$store.commit('SET_JURISDICTION', 'false')
        this.$router.push({ path: '/land/mall/plotMall' })
        // 金融角色 跳转到融资池
      } else if (this.jurisdiction === 'true' && this.$route.path === '/' && this.userInfo.role_name === 'finance') {
        this.$store.commit('SET_JURISDICTION', 'false')
        this.$router.push({ path: '/finance/pool/fundingPool' })
        // 设计角色 跳转到设计池
      } else if (this.jurisdiction === 'true' && this.$route.path === '/' && this.userInfo.role_name === 'design') {
        this.$store.commit('SET_JURISDICTION', 'false')
        this.$router.push({ path: '/design/pool/programPool' })
        // 施工角色跳转到施工池
      } else if (this.jurisdiction === 'true' && this.$route.path === '/' && this.userInfo.role_name === 'engineering') {
        this.$store.commit('SET_JURISDICTION', 'false')
        this.$router.push({ path: '/build/pool/programPool' })
        //销售角色跳转到销售池
      } else if (this.jurisdiction === 'true' && this.$route.path === '/' && this.userInfo.role_name === 'seller') {
        this.$store.commit('SET_JURISDICTION', 'false')
        this.$router.push({ path: '/sale/pool/programPool' })
        // 运营角色跳转到运营池
      } else if (this.jurisdiction === 'true' && this.$route.path === '/' && this.userInfo.role_name === 'operator') {
        this.$store.commit('SET_JURISDICTION', 'false')
        this.$router.push({ path: '/operation/pool/programPool' })
        // 个人用户跳转到寻找楼盘
      } else if (this.jurisdiction === 'true' && this.$route.path === '/' && this.userInfo.role_name === 'user') {
        this.$store.commit('SET_JURISDICTION', 'false')
        this.$router.push({ path: '/lookingEstate/index' })
      }
    }
  }
}
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
}

svg {
  width: 1em;
  height: 1em;
}

html,
body,
#app,
.el-container,
.el-menu {
  height: 100%;
}
</style>

import request from '@/router/axios'

// 企业获取角色列表
export const roleList = () => {
  return request({
    url: '/api/blade-system/search/role-list',
    method: 'get',
    headers: {
      'Tenant-Id': '000000'
    },
    params: {
      tenantId: '000000'
    }
  })
}

<template>
  <div class="buildPlan">
    <div class="companyInfo">
      <div class="title">
        <h4>公司简介</h4>
      </div>
      <ul class="company">
        <li>
          <span>公司名称</span>
          <p>{{ formData.companyName }}</p>
        </li>
        <li>
          <span>公司描述</span>
          <p>{{ formData.companyDescribe }}</p>
        </li>
      </ul>
    </div>
    <div class="projectInfo">
      <div class="title">
        <h4>工程基本信息</h4>
      </div>
      <ul class="project">
        <li>
          <span>总工期</span>
          <p>{{ formData.totalDuration }}天</p>
        </li>
        <li>
          <span>总人数</span>
          <p>{{ formData.totalPeople }}人</p>
        </li>
        <li>
          <span>总用料成本</span>
          <p>{{ formData.totalCost }}万</p>
        </li>
      </ul>
    </div>
    <div class="buildInfo">
      <div class="title">
        <h4>施工基本信息</h4>
      </div>
      <ul class="project">
        <li>
          <div class="block">
            <span>施工阶段划分</span>
            <p>{{ formData.constructionStage }}</p>
          </div>
          <div class="block">
            <span>总负责人</span>
            <p>{{ formData.wholeBearPeopleName }}</p>
          </div>
        </li>
        <li class="stage" v-for="(item, index) in formData.constructionPlanStageList" :key="index + 'a'">
          <span class="label">{{ item.label }}</span>
          <div class="container">
            <ul>
              <li class="stagename">
                <span class="stageName">阶段名称</span>
                <p>{{ item.stageName }}</p>
              </li>
              <li class="line1">
                <ul>
                  <li>
                    <span>负责人</span>
                    <p>{{ item.bearPeopleName }}</p>
                  </li>
                  <li>
                    <span>施工人数</span>
                    <p>{{ item.constructorsPeopleNum }}人</p>
                  </li>
                </ul>
              </li>
              <li class="line1">
                <ul>
                  <li>
                    <span>预计周期</span>
                    <p>{{ item.expectedPeriod }}天</p>
                  </li>
                  <li>
                    <span>施工成本</span>
                    <p>{{ item.constructorsCost }}万</p>
                  </li>
                </ul>
              </li>
              <li class="line2">
                <ul>
                  <li v-for="(item1, index1) in item.constructorsContent" :key="index1 + 'b'">
                    <div class="block">
                      <span>施工内容</span>
                      <p>{{ item1.content }}</p>
                    </div>
                    <div class="block">
                      <span>施工天数</span>
                      <p>{{ item1.days }}天</p>
                    </div>
                  </li>
                </ul>
              </li>
              <li>
                <span>施工难点</span>
                <p>{{ item.constructorsDifficulty }}</p>
              </li>
              <li>
                <span>难点解决方案</span>
                <p>{{ item.difficultySolution }}</p>
              </li>
              <li>
                <span>成本变动范围</span>
                <p>{{ item.costChangeRange }}万</p>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
    <div class="purchasePlan">
      <div class="title">
        <h4>采购计划</h4>
      </div>
      <ul class="purchase">
        <li>
          <span class="label">阶段一&nbsp;&nbsp;基地处理</span>
          <div class="container">
            <ul>
              <li class="line2">
                <ul v-for="(item, index) in formData.stageOneContent" :key="index">
                  <li>
                    <div class="block">
                      <span>用料名称</span>
                      <p>{{ item.name }}</p>
                    </div>
                    <div class="block">
                      <span>数量</span>
                      <p>{{ item.num }}</p>
                    </div>
                    <div class="block">
                      <span>成本</span>
                      <p>{{ item.cost }}万</p>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <span class="label">阶段二&nbsp;&nbsp;地下施工</span>
          <div class="container">
            <ul>
              <li class="line2">
                <ul v-for="(item, index) in formData.stageTwoContent" :key="index">
                  <li>
                    <div class="block">
                      <span>用料名称</span>
                      <p>{{ item.name }}</p>
                    </div>
                    <div class="block">
                      <span>数量</span>
                      <p>{{ item.num }}</p>
                    </div>
                    <div class="block">
                      <span>成本</span>
                      <p>{{ item.cost }}万</p>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <span class="label">阶段三&nbsp;&nbsp;主体施工</span>
          <div class="container">
            <ul>
              <li class="line2">
                <ul v-for="(item, index) in formData.stageThreeContent" :key="index">
                  <li>
                    <div class="block">
                      <span>用料名称</span>
                      <p>{{ item.name }}</p>
                    </div>
                    <div class="block">
                      <span>数量</span>
                      <p>{{ item.num }}</p>
                    </div>
                    <div class="block">
                      <span>成本</span>
                      <p>{{ item.cost }}万</p>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <span class="label">阶段四&nbsp;&nbsp;水电暖通施工</span>
          <div class="container">
            <ul>
              <li class="line2">
                <ul v-for="(item, index) in formData.stageFourContent" :key="index">
                  <li>
                    <div class="block">
                      <span>用料名称</span>
                      <p>{{ item.name }}</p>
                    </div>
                    <div class="block">
                      <span>数量</span>
                      <p>{{ item.num }}</p>
                    </div>
                    <div class="block">
                      <span>成本</span>
                      <p>{{ item.cost }}万</p>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <span class="label">阶段五&nbsp;&nbsp;整体完工</span>
          <div class="container">
            <ul>
              <li class="line2">
                <ul v-for="(item, index) in formData.stageFiveContent" :key="index">
                  <li>
                    <div class="block">
                      <span>用料名称</span>
                      <p>{{ item.name }}</p>
                    </div>
                    <div class="block">
                      <span>数量</span>
                      <p>{{ item.num }}</p>
                    </div>
                    <div class="block">
                      <span>成本</span>
                      <p>{{ item.cost }}万</p>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'buildPlan',
  props: {
    itemConstructionPlanVo: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {

    }
  },
  computed: {
    formData() {
      let res = {}
      if (this.itemConstructionPlanVo) {
        // console.log(this.buildPlan);
        let data = { ...this.itemConstructionPlanVo }
        res = {
          id: data.id,
          createCompany: data.createCompany,
          companyName: data.companyName,
          companyDescribe: data.companyDescribe,
          wholeBearPeopleId: data.wholeBearPeopleId, //总负责人
          wholeBearPeopleName: data.wholeBearPeopleName,
          totalCost: data.totalCost / 10000,
          totalDuration: data.totalDuration,
          totalPeople: data.totalPeople,
          constructionPlanStageList: [],
          constructionStage: data.constructionStage,
          stageFive: data.stageFive,
          stageFiveContent: [],
          stageFour: data.stageFour,
          stageFourContent: [],
          stageOne: data.stageOne,
          stageOneContent: [],
          stageThree: data.stageThree,
          stageThreeContent: [],
          stageTwo: data.stageTwo,
          stageTwoContent: [],
          userList: data.userList
        }
        if (data.constructionPlanStageList) {
          data.constructionPlanStageList.forEach((item, index) => {
            res.constructionPlanStageList.push({
              stageCode: item.stageCode,
              bearPeopleId: item.bearPeopleId,
              bearPeopleName: item.bearPeopleName,
              constructorsContent: item.constructorsContent,
              constructorsCost: item.constructorsCost / 10000,
              constructorsDifficulty: item.constructorsDifficulty,
              constructorsPeopleNum: item.constructorsPeopleNum,
              costChangeRange: item.costChangeRange / 10000,
              difficultySolution: item.difficultySolution,
              expectedPeriod: item.expectedPeriod,
              stageName: item.stageName
            })
          })
          data.stageOneContent.forEach((item, index) => {
            res.stageOneContent.push({
              name: item.name,
              num: item.num,
              cost: item.cost / 10000
            })
          })
          data.stageTwoContent.forEach((item, index) => {
            res.stageTwoContent.push({
              name: item.name,
              num: item.num,
              cost: item.cost / 10000
            })
          })
          data.stageThreeContent.forEach((item, index) => {
            res.stageThreeContent.push({
              name: item.name,
              num: item.num,
              cost: item.cost / 10000
            })
          })
          data.stageFourContent.forEach((item, index) => {
            res.stageFourContent.push({
              name: item.name,
              num: item.num,
              cost: item.cost / 10000
            })
          })
          data.stageFiveContent.forEach((item, index) => {
            res.stageFiveContent.push({
              name: item.name,
              num: item.num,
              cost: item.cost / 10000
            })
          })
        }
        this.peopleList = res.userList
      }
      return res
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/planDetail/buildPlan.scss';
</style>

<template>
  <div class="container">
    <div class="companyProfile">
      <el-row>
        <i class="icon"></i>
        <span class="nav">公司简介</span>
      </el-row>
      <el-descriptions :column="1">
        <el-descriptions-item label="公司名称">
          {{ itemPropertyPlan.companyName || '' }}
        </el-descriptions-item>
        <el-descriptions-item label="公司描述">
          {{ itemPropertyPlan.companyDesc || '' }}
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <el-row>
      <i class="icon"></i>
      <span class="nav">项目介绍</span>
    </el-row>
    <el-descriptions :column="2">
      <el-descriptions-item label="项目名称">{{ itemPropertyPlan.itemName || '' }}</el-descriptions-item>
      <el-descriptions-item label="建设地点">{{ itemPropertyPlan.area || '' }}</el-descriptions-item>
    </el-descriptions>
    <el-descriptions :column="2">
      <el-descriptions-item label="占地面积"> {{ itemPropertyPlan.acreage || '' }} m<sup>2</sup> </el-descriptions-item>
      <el-descriptions-item label="产权年限">{{ itemPropertyPlan.leaseTerm || '' }}年 </el-descriptions-item>
    </el-descriptions>
    <el-descriptions :column="2">
      <el-descriptions-item label="楼盘名称"> {{ itemPropertyPlan.towerName || '' }}</el-descriptions-item>
      <el-descriptions-item label="楼盘类型">
        {{ towerType(itemPropertyPlan.towerType) || '' }}
      </el-descriptions-item>
    </el-descriptions>
    <el-descriptions :column="1" class="descriptions">
      <el-descriptions-item label="设计理念">
        {{ itemPropertyPlan.idea || '' }}
      </el-descriptions-item>
      <el-descriptions-item label="项目定位">
        {{ itemPropertyPlan.itemPositioning || '' }}
      </el-descriptions-item>
    </el-descriptions>
    <el-row>
      <i class="icon"></i>
      <span class="nav">地块情况</span>
    </el-row>
    <el-descriptions :column="2">
      <el-descriptions-item label="拆迁户数">
        {{ itemPropertyPlan.moveHouseNum || '' }}
      </el-descriptions-item>
      <el-descriptions-item label="总拆迁补偿">
        {{ upMoneyType(itemPropertyPlan.compensation) || '' }}
      </el-descriptions-item>
    </el-descriptions>
    <el-descriptions :column="1" class="descriptions">
      <el-descriptions-item label="拆迁方案">
        {{ itemPropertyPlan.demolitionPlan || '' }}
      </el-descriptions-item>
    </el-descriptions>
    <el-row>
      <i class="icon"></i>
      <span class="nav">项目周边情况</span>
    </el-row>
    <el-descriptions :column="1">
      <el-descriptions-item label="配套设施">
        {{ itemPropertyPlan.supportingFacilities || '' }}
      </el-descriptions-item>
      <el-descriptions-item label="交通情况">
        {{ itemPropertyPlan.trafficConditions || '' }}
      </el-descriptions-item>
      <el-descriptions-item label="机关单位">
        {{ itemPropertyPlan.agencyUnit || '' }}
      </el-descriptions-item>
    </el-descriptions>
    <el-row>
      <i class="icon"></i>
      <span class="nav">建设预期</span>
    </el-row>
    <el-descriptions :column="1">
      <el-descriptions-item label="项目体量">{{ upMoneyType(itemPropertyPlan.volume) || '' }}</el-descriptions-item>
      <el-descriptions-item label="项目收入目标">{{
        upMoneyType(itemPropertyPlan.revenueTarget) || ''
      }}</el-descriptions-item>
      <el-descriptions-item label="社会效益">{{ itemPropertyPlan.socialBenefits || '' }}</el-descriptions-item>
    </el-descriptions>
    <el-row>
      <i class="icon"></i>
      <span class="nav">各参与方资金分布</span>
    </el-row>
    <el-descriptions :column="2">
      <el-descriptions-item label="设计所需资金">
        {{ upMoneyType(itemPropertyPlan.designMoney) || '' }}
      </el-descriptions-item>
      <el-descriptions-item label="施工所需资金">
        {{ upMoneyType(itemPropertyPlan.engineeringMoney) || '' }}
      </el-descriptions-item>
      <el-descriptions-item label="销售所需资金">{{
        upMoneyType(itemPropertyPlan.sellerMoney) || ''
      }}</el-descriptions-item>
      <el-descriptions-item label="运营所需资金">{{
        upMoneyType(itemPropertyPlan.operatorMoney) || ''
      }}</el-descriptions-item>
    </el-descriptions>
    <el-row>
      <i class="icon"></i>
      <span class="nav">地产工作阶段</span>
    </el-row>
    <el-descriptions :column="1">
      <el-descriptions-item label="总负责人">
        {{ itemPropertyPlan.generalPrincipal || '' }}
      </el-descriptions-item>
    </el-descriptions>
    <div class="stage">
      <div class="main">
        <div class="stage1">
          <ul>
            <li :class="{ after: index != 4 }" v-for="(item, index) in stage" :key="index">
              <i></i>
              <span>{{ item.label }}</span>
              <div class="stageCapital">
                <span>所需资金 </span>
                <p>{{ upMoneyType(item.price) || '' }}</p>
              </div>
              <div class="stageCapital">
                <span> 负责人</span>
                <p>{{ item.chargePerson || '' }}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { upMoneyType } from '@/utils/validate'
import { towerType } from '@/utils/filters'
export default {
  name: 'plotPlan',
  props: {
    detailList: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {
      itemPropertyPlan: {},
      stage: [
        {
          label: '项目启动',
          price: null,
          chargePerson: ''
        },
        {
          label: '地基验收',
          price: null,
          chargePerson: ''
        },
        {
          label: '主体验收',
          price: null,
          chargePerson: ''
        },
        {
          label: '水电暖通验收',
          price: null,
          chargePerson: ''
        },
        {
          label: '整体验收',
          price: null,
          chargePerson: ''
        }
      ],
      upMoneyType: upMoneyType,
      towerType
    }
  },
  watch: {
    detailList: {
      handler(newval, oldval) {
        if (newval) {
          this.itemPropertyPlan = newval
          this.stage[0].price = this.itemPropertyPlan.initialRequiredFunds
          this.stage[1].price = this.itemPropertyPlan.foundationRequiredFunds
          this.stage[2].price = this.itemPropertyPlan.majorStructureRequiredFunds
          this.stage[3].price = this.itemPropertyPlan.installingRequiredFunds
          this.stage[4].price = this.itemPropertyPlan.acceptanceRequiredFunds

          this.stage[0].chargePerson = this.itemPropertyPlan.initialPrincipal
          this.stage[1].chargePerson = this.itemPropertyPlan.foundationPrincipal
          this.stage[2].chargePerson = this.itemPropertyPlan.majorStructurePrincipal
          this.stage[3].chargePerson = this.itemPropertyPlan.installingPrincipal
          this.stage[4].chargePerson = this.itemPropertyPlan.acceptancePrincipal
        }
      },
      deep: true,
      //立即执行
      immediate: true
    }
  },
  created() {},
  mounted() {}
}
</script>

<style lang="scss" scoped>
.container {
  padding: 0 60px 0 30px;

  .el-row {
    margin: 16px 0;
    height: 30px;
    line-height: 30px;

    .icon {
      display: inline-block;
      width: 4px;
      height: 12px;
      background-color: #3a71ff;
      border-radius: 4px;
      margin-right: 10px;
    }

    .nav {
      font-size: 14px;
      color: #101010;
      font-weight: 500;
    }
  }
}

.main {
  width: 90%;
  margin: 0 auto;

  .stage1 {
    width: 100%;

    .stageCapital {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
    }

    ul {
      display: flex;
      justify-content: space-between;

      li {
        width: 20%;
        text-align: center;

        i {
          display: block;
          margin: 10px auto;
          width: 8px;
          height: 8px;
          border-radius: 50% 50%;
          border: 1px solid #3a71ff;
        }

        span {
          font-size: 14px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 500;
          color: rgba(16, 16, 16, 0.8);
        }

        p {
          margin-left: 20px;
          text-align: left;
          font-size: 14px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: rgba(16, 16, 16, 0.8);
        }
      }

      .after {
        position: relative;

        &::after {
          position: absolute;
          top: 14px;
          right: -46%;
          content: '';
          display: block;
          width: 92%;
          height: 1px;
          background-color: #3a71ff;
        }
      }
    }
  }
}

::v-deep .el-descriptions__body {
  display: flex;
  color: #606266;
  background-color: #fff;
}

::v-deep el-descriptions-item__content {
  display: block;
}

::v-deep .el-descriptions__body .el-descriptions__table {
  margin-left: 8%;
}
</style>

<template>
  <div class="map3D">
    <div id="container"></div>
    <div id="result">
      <ul>
        <li
          :class="{ selected: selected == index }"
          v-for="(item, index) in searchData"
          :key="index"
          @click="handlerSelect(item, index)"
        >
          <div class="amap_lib_placeSearch_poi poibox-icon">{{ index + 1 }}</div>
          <div
            class="poi-img"
            :style="{ 'background-image': 'url(' + item.photos[0]?.url + ')', width: '90px', height: '56px' }"
          ></div>
          <h3 class="poi-title">
            <span class="poi-name">{{ item.name }}</span
            ><a class="poi-more" title="详情" :href="'https://gaode.com/detail/'+item.id+'?src=jsapi_detail'" target="_blank">&gt;</a>
          </h3>
          <div class="poi-info">
            <p class="poi-addr">地址：{{item.address}}</p>
            <p class="poi-tel">电话：{{item.tel}}</p>
          </div>
          <div class="clear"></div>
        </li>
        <li v-if="show1">抱歉，未搜索到有效的结果。</li>
      </ul>
      <el-pagination
      :small="true"
        :hide-on-single-page="show1"
        @current-change="handleCurrentChange"
        :current-page.sync="page.current"
        :page-size="page.size"
        layout="total, prev, pager, next"
        :total="page.total"
        :pager-count="5"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import map_2 from './mixins/map_2.js'
import map_3 from './mixins/map_3.js'
window._AMapSecurityConfig = {
  securityJsCode: '5afce96d63848e70d78f33b3cdd46674'
}
export default {
  name: 'Map3D',
  mixins: [map_3]
}
</script>

<style lang="scss" scoped>
.map3D {
  position: relative;
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 100%;
}

#container {
  position: absolute;
  padding: 0px;
  margin: 0px;
  z-index: 1;
  width: 100%;
  height: 100%;
}

#result {
  margin: 0;
  padding: 0;
  z-index: 999;
  position: absolute;
  background-color: white;
  max-height: 100%;
  overflow-y: auto;
  top: 0;
  right: 0;
  width: 300px;
  ul {
    li {
      box-sizing: border-box;
      padding: 5px 0 5px 10px;
      font-size: 12px;
    }
  }
}
.poibox-icon {
  margin-left: 7px;
  margin-top: 4px;
}
::v-deep .selected .amap_lib_placeSearch_poi {
  background-image: url(https://webapi.amap.com/theme/v1.3/markers/n/mark_r.png);
}
::v-deep .amap_lib_placeSearch_poi {
  position: absolute;
  background: url(https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png) no-repeat;
  height: 31px;
  width: 19px;
  cursor: pointer;
  left: -1px;
  text-align: center;
  color: #fff;
  font: 12px arial, simsun, sans-serif;
  padding-top: 3px;
}
.poi-img {
  float: right;
  margin: 3px 8px 0;
  width: 90px;
  height: 56px;
  overflow: hidden;
}
.poi-title {
  margin-left: 25px;
  font-size: 13px;
  overflow: hidden;
}
.poi-more {
  display: inline-block;
  width: 16px;
  height: 16px;
  text-indent: -1000em;
  background: url(https://webapi.amap.com/theme/v1.3/images/newpc/tips.png) center center/85% no-repeat;
  vertical-align: middle;
  cursor: pointer;
  opacity: 0.5;
  margin: 1px 0 1px 2px;
}
.poi-info {
  word-break: break-all;
  margin: 0 0 0 25px;
  overflow: hidden;
  color: #999;
}
.clear {
  clear: both;
}
</style>

// import Vue from 'vue'
// import VueRouter from 'vue-router'

import title from '@/store/modules/title'

// Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: '登录页',
    component: () => import('@/views/login/index.vue'),
    meta: {
      keepAlive: true,
      isTab: false,
      isAuth: false,
      title: '登录'
    }
  },
  {
    path: '/perfect',
    name: 'perfect',
    component: () => import(/* webpackChunkName: "page" */ '@/views/perfect/index.vue'),
    meta: {
      keepAlive: false,
      isTab: false,
      isAuth: false,
      title: '创建企业'
    }
  },
  {
    path: '/perfect/improveInformation/',
    name: 'improveInformation',
    meta: {
      i18n: 'test',
      title: '完善信息'
    },
    component: () => import('@/views/perfect/components/improveInformation.vue')
  },
  {
    path: '/perfect/success',
    name: 'success',
    meta: {
      i18n: 'test',
      title: '注册成功'
    },
    component: () => import('@/views/perfect/components/success.vue')
  },
  {
    path: '/',
    redirect: '/',
    component: () => import('@/components/container/index.vue'),
    meta: {
      keepAlive: false,
      isTab: false,
      isAuth: false
    },
    children: [
      {
        path: '/',
        component: () => import('@/App.vue'),
        meta: {
          title: '首页'
        }
      },
      {
        path: '/plot/realEstateName/index',
        name: 'realEstateName',
        meta: {
          i18n: 'test',
          title: '地产名录'
        },
        component: () => import(/* webpackChunkName: "views" */ '@/views/plot/realEstateName.vue')
      },
      {
        path: '/land/mall/index',
        name: 'mall',
        meta: {
          i18n: 'test',
          title: '地块首页'
        },
        redirect: '/land/mall/plotMall',
        component: () => import(/* webpackChunkName: "views" */ '@/views/land/mall.vue'),
        children: [
          {
            path: '/land/mall/plotMall',
            name: 'plotMall',
            meta: {
              i18n: 'test',
              title: '地块商城',
              parentRoute: '/land/mall/index'
            },
            component: () => import(/* webpackChunkName: "views" */ '@/views/land/mall/plotMall.vue')
          },
          {
            path: '/land/mall/myApply',
            name: 'myApply',
            meta: {
              i18n: 'test',
              parentRoute: '/land/mall/index'
            },
            component: () => import(/* webpackChunkName: "views" */ '@/views/land/mall/myApply.vue')
          },
          {
            path: '/land/mall/myCollect',
            name: 'myCollect',
            meta: {
              i18n: 'test',
              title: '我的收藏',
              parentRoute: '/land/mall/index'
            },
            component: () => import(/* webpackChunkName: "views" */ '@/views/land/mall/myCollect.vue')
          }
        ]
      },
      {
        path: '/land/mall/landDetail',
        name: 'landDetail',
        meta: {
          i18n: 'test',
          title: '地块详情'
        },
        component: () => import('@/views/land/mall/landDetail.vue')
      },

      {
        path: '/policy/interpretation/index',
        name: 'policy',
        meta: {
          i18n: 'test',
          title: '资讯解读'
        },
        component: () => import(/* webpackChunkName: "views" */ '@/views/policy/interpretation.vue')
      },
      {
        path: '/detail',
        name: '地块详情',
        meta: {
          i18n: 'test',
          title: '地块详情'
        },
        component: () => import(/* webpackChunkName: "views" */ '@/views/plot/addPlot')
      },
      {
        path: '/plot/realEstateName/index',
        name: 'realEstateName',
        meta: {
          i18n: 'test',
          title: '地产名录'
        },
        target: true,
        component: () => import(/* webpackChunkName: "views" */ '@/views/plot/realEstateName.vue')
      },
      {
        path: '/plot/realEstateName/propertyListDetail',
        name: 'propertyListDetail',
        meta: {
          i18n: 'test',
          title: '地产名录详情'
        },
        target: true,
        component: () => import(/* webpackChunkName: "views" */ '@/views/plot/components/propertyListDetail.vue')
      },
      {
        path: '/plot/investmentName/index',
        name: 'investmentName',
        meta: {
          i18n: 'test',
          title: '投资机构名录'
        },
        target: true,
        component: () => import(/* webpackChunkName: "views" */ '@/views/plot/investmentName.vue')
      },
      {
        path: '/plot/realEstateName/propertyListDetails',
        name: 'propertyListDetails',
        meta: {
          i18n: 'test',
          title: '投资机构名录详情'
        },
        target: true,
        component: () => import(/* webpackChunkName: "views" */ '@/views/plot/components/propertyListDetails.vue')
      },
      {
        path: '/plot/management/index',
        name: 'plotManagement',
        meta: {
          i18n: 'test',
          title: '地块管理'
        },
        component: () => import(/* webpackChunkName: "about" */ '@/views/plot/management.vue')
      },
      {
        path: '/plot/management/addPlot',
        name: 'addPlot',
        meta: {
          i18n: 'test',
          title: '增加地块'
        },
        component: () => import(/* webpackChunkName: "about" */ '@/views/plot/addPlot.vue')
      },
      {
        path: '/plot/management/editPlot',
        name: 'editPlot',
        meta: {
          i18n: 'test',
          title: '编辑地块'
        },
        component: () => import(/* webpackChunkName: "about" */ '@/views/plot/addPlot.vue')
      },
      {
        path: '/plot/plotDetail',
        name: 'plotDetail',
        meta: {
          i18n: 'test',
          title: '地块详情'
        },
        component: () => import(/* webpackChunkName: "about" */ '@/views/plot/plotDetail')
      },
      {
        path: '/land/property/index',
        name: 'propertyManagement',
        meta: {
          i18n: 'test',
          title: '资产管理'
        },
        component: () => import(/* webpackChunkName: "about" */ '@/views/land/property.vue')
      },
      {
        path: '/land/property/addProperty',
        name: 'addProperty',
        meta: {
          i18n: 'test',
          title: '增加资产'
        },
        component: () => import(/* webpackChunkName: "about" */ '@/views/land/propertys/addProperty')
      },
      {
        path: '/land/property/editProperty',
        name: 'editProperty',
        meta: {
          i18n: 'test',
          title: '编辑资产'
        },
        component: () => import(/* webpackChunkName: "about" */ '@/views/land/propertys/addProperty')
      },
      {
        path: '/land/property/detail',
        name: 'propertyDetail',
        meta: {
          i18n: 'test',
          title: '资产详情'
        },
        component: () => import(/* webpackChunkName: "about" */ '@/views/land/propertys/detailProperty')
      },
      {
        path: '/projectManagement/index',
        name: 'projectManagement',
        meta: {
          title: '项目管理',
          i18n: 'test'
        },
        component: () => import('@/views/projectManagement/index.vue'),
        redirect: '/projectManagement/schemeSolicitation',
        children: [
          {
            path: '/projectManagement/schemeSolicitation',
            name: 'schemeSolicitation',
            meta: {
              title: '项目征集',
              i18n: 'test',
              parentRoute: '/projectManagement/index'
            },
            component: () => import('@/views/projectManagement/components/schemeSolicitation')
          },
          {
            path: '/projectManagement/financingPlan',
            name: 'financingPlan',
            meta: {
              title: '项目融资',
              i18n: 'test',
              parentRoute: '/projectManagement/index'
            },
            component: () => import('@/views/projectManagement/components/financingPlan')
          }
        ]
      },
      {
        path: '/projectManagement/schemeSolicitation/addProject',
        name: 'addProject',
        meta: {
          title: '添加项目',
          i18n: 'test'
        },
        component: () => import('@/views/projectManagement/components/addProject.vue')
      },
      {
        path: '/projectManagement/schemeSolicitation/editProject',
        name: 'editProject',
        meta: {
          title: '编辑项目',
          i18n: 'test'
        },
        component: () => import('@/views/projectManagement/components/addProject.vue')
      },
      {
        path: '/projectManagement/schemeSolicitation/detailsProject',
        name: 'detailsProject',
        meta: {
          title: '项目详情',
          i18n: 'test'
        },
        component: () => import('@/views/projectManagement/components/detailsProject.vue')
      },
      {
        path: '/projectManagement/financingPlan/addNowProject',
        name: 'addNowProject',
        meta: {
          title: '添加现有项目',
          i18n: 'test'
        },
        component: () => import('@/views/projectManagement/components/addNowFinancingProject.vue')
      },
      {
        path: '/projectManagement/financingPlan/addNewProject',
        name: 'addNewProject',
        meta: {
          title: '添加新建项目',
          i18n: 'test'
        },
        component: () => import('@/views/projectManagement/components/addNewFinancingProject')
      },
      {
        path: '/projectManagement/financingPlan/editProject',
        name: 'editFinancingProject',
        meta: {
          title: '编辑项目',
          i18n: 'test'
        },
        component: () => import('@/views/projectManagement/components/addNewFinancingProject')
      },
      {
        path: '/projectManagement/financingPlan/detailFinancing',
        name: 'detailFinancing',
        meta: {
          title: '融资项目详情',
          i18n: 'test'
        },
        component: () => import('@/views/projectManagement/components/detailFinancing')
      },
      {
        path: '/projectProgress/index',
        name: 'projectProgress',
        meta: {
          title: '项目进度',
          i18n: 'test'
        },
        component: () => import('@/views/projectProgress/index.vue')
      },
      {
        path: '/projectProgress/projectProgressDetails',
        name: 'projectProgressDetails',
        meta: {
          title: '项目进度详情',
          i18n: 'test'
        },
        component: () => import('@/views/projectProgress/components/projectProgressDetails.vue')
      },
      {
        path: '/finance/pool/index',
        name: 'pool',
        meta: {
          i18n: 'test'
        },
        redirect: '/finance/pool/fundingPool',
        component: () => import(/* webpackChunkName: "views" */ '@/views/finance/pool.vue'),
        children: [
          {
            path: '/finance/pool/fundingPool',
            name: 'fundingPool',
            meta: {
              i18n: 'test',
              title: '融资池',
              parentRoute: '/finance/pool/index'
            },
            component: () => import(/* webpackChunkName: "views" */ '@/views/finance/pool/fundingPool.vue')
          },
          {
            path: '/finance/pool/myApply',
            name: 'myApply',
            meta: {
              i18n: 'test',
              title: '我的报名',
              parentRoute: '/finance/pool/index'
            },
            component: () => import(/* webpackChunkName: "views" */ '@/views/finance/pool/myApply.vue')
          },
          {
            path: '/finance/pool/myCollect',
            name: 'myCollect',
            meta: {
              i18n: 'test',
              title: '我的收藏',
              parentRoute: '/finance/pool/index'
            },
            component: () => import(/* webpackChunkName: "views" */ '@/views/finance/pool/myCollect.vue')
          }
        ]
      },
      {
        path: '/finance/pool/programDetail',
        name: 'programDetail',
        meta: {
          i18n: 'test',
          title: '项目详情'
        },
        component: () => import('@/views/finance/pool/programDetail.vue')
      },
      {
        path: '/design/pool/index',
        name: 'design',
        meta: {
          i18n: 'test',
          title: '设计项目池'
        },
        redirect: '/design/pool/programPool',
        component: () => import(/* webpackChunkName: "views" */ '@/views/design/pool.vue'),
        children: [
          {
            path: '/design/pool/programPool',
            name: 'programPool',
            meta: {
              i18n: 'test',
              title: '设计项目池',
              parentRoute: '/design/pool/index'
            },
            component: () => import(/* webpackChunkName: "views" */ '@/views/design/pool/programPool.vue')
          },
          {
            path: '/design/pool/myApply',
            name: 'myApply',
            meta: {
              i18n: 'test',
              title: '我的报名',
              parentRoute: '/design/pool/index'
            },
            component: () => import(/* webpackChunkName: "views" */ '@/views/design/pool/myApply.vue')
          },
          {
            path: '/design/pool/myCollect',
            name: 'myCollect',
            meta: {
              i18n: 'test',
              title: '我的收藏',
              parentRoute: '/design/pool/index'
            },
            component: () => import(/* webpackChunkName: "views" */ '@/views/design/pool/myCollect.vue')
          }
        ]
      },
      {
        path: '/design/pool/programDetail',
        name: 'programDetail',
        meta: {
          i18n: 'test',
          title: '项目详情',
          parentRoute: '/design/pool/index'
        },
        component: () => import('@/views/design/pool/programDetail.vue')
      },
      {
        path: '/build/pool/index',
        name: 'build',
        meta: {
          i18n: 'test',
          title: '施工项目池'
          // roles: 'property'
        },
        redirect: '/build/pool/programPool',
        component: () => import(/* webpackChunkName: "views" */ '@/views/build/pool.vue'),
        children: [
          {
            path: '/build/pool/programPool',
            name: 'programPool',
            meta: {
              i18n: 'test',
              title: '施工项目池',
              parentRoute: '/build/pool/index'
            },
            component: () => import(/* webpackChunkName: "views" */ '@/views/build/pool/programPool.vue')
          },
          {
            path: '/build/pool/myApply',
            name: 'myApply',
            meta: {
              i18n: 'test',
              title: '我的报名',
              parentRoute: '/build/pool/index'
            },
            component: () => import(/* webpackChunkName: "views" */ '@/views/build/pool/myApply.vue')
          },
          {
            path: '/build/pool/myCollect',
            name: 'myCollect',
            meta: {
              i18n: 'test',
              title: '我的收藏',
              parentRoute: '/build/pool/index'
            },
            component: () => import(/* webpackChunkName: "views" */ '@/views/build/pool/myCollect.vue')
          }
        ]
      },
      {
        path: '/build/pool/programDetail',
        name: 'programDetail',
        meta: {
          i18n: 'test',
          title: '项目详情',
          parentRoute: '/build/pool/index'
        },
        component: () => import('@/views/build/pool/programDetail.vue')
      },
      {
        path: '/sale/pool/index',
        name: 'sale',
        meta: {
          i18n: 'test',
          title: '销售项目池'
        },
        redirect: '/sale/pool/programPool',
        component: () => import(/* webpackChunkName: "views" */ '@/views/sale/pool.vue'),
        children: [
          {
            path: '/sale/pool/programPool',
            name: 'programPool',
            meta: {
              i18n: 'test',
              title: '销售项目池',
              parentRoute: '/sale/pool/index'
            },
            component: () => import(/* webpackChunkName: "views" */ '@/views/sale/pool/programPool.vue')
          },
          {
            path: '/sale/pool/myApply',
            name: 'myApply',
            meta: {
              i18n: 'test',
              title: '我的报名',
              parentRoute: '/sale/pool/index'
            },
            component: () => import(/* webpackChunkName: "views" */ '@/views/sale/pool/myApply.vue')
          },
          {
            path: '/sale/pool/myCollect',
            name: 'myCollect',
            meta: {
              i18n: 'test',
              title: '我的收藏',
              parentRoute: '/sale/pool/index'
            },
            component: () => import(/* webpackChunkName: "views" */ '@/views/sale/pool/myCollect.vue')
          }
        ]
      },
      {
        path: '/sale/pool/programDetail',
        name: 'programDetail',
        meta: {
          i18n: 'test',
          title: '项目详情',
          parentRoute: '/sale/pool/index'
        },
        component: () => import('@/views/sale/pool/programDetail.vue')
      },
      {
        path: '/operation/pool/index',
        name: 'operation',
        meta: {
          i18n: 'test',
          title: '运营项目池'
        },
        redirect: '/operation/pool/programPool',
        component: () => import(/* webpackChunkName: "views" */ '@/views/operation/pool.vue'),
        children: [
          {
            path: '/operation/pool/programPool',
            name: 'programPool',
            meta: {
              i18n: 'test',
              title: '运营项目池',
              parentRoute: '/operation/pool/index'
            },
            component: () => import(/* webpackChunkName: "views" */ '@/views/operation/pool/programPool.vue')
          },
          {
            path: '/operation/pool/myApply',
            name: 'myApply',
            meta: {
              i18n: 'test',
              title: '我的报名',
              parentRoute: '/operation/pool/index'
            },
            component: () => import(/* webpackChunkName: "views" */ '@/views/operation/pool/myApply.vue')
          },
          {
            path: '/operation/pool/myCollect',
            name: 'myCollect',
            meta: {
              i18n: 'test',
              title: '我的收藏',
              parentRoute: '/operation/pool/index'
            },
            component: () => import(/* webpackChunkName: "views" */ '@/views/operation/pool/myCollect.vue')
          }
        ]
      },
      {
        path: '/operation/pool/programDetail',
        name: 'programDetail',
        meta: {
          i18n: 'test',
          title: '项目详情',
          parentRoute: '/operation/pool/index'
        },
        component: () => import('@/views/operation/pool/programDetail.vue')
      },
      {
        path: '/investmentManagement/index',
        name: 'investmentManagement',
        meta: {
          i18n: 'test',
          title: '投资管理'
        },
        component: () => import('@/views/investmentManagement/index.vue')
      },
      {
        path: '/designManagement/index',
        name: 'designManagement',
        meta: {
          i18n: 'test',
          title: '项目设计管理'
        },
        component: () => import('@/views/designManagement/index.vue')
      },
      {
        path: '/designManagement/projectDetails',
        name: 'projectDetails',
        meta: {
          i18n: 'test',
          title: '项目详情页'
        },
        component: () => import('@/views/designManagement/projectDetails.vue')
      },
      {
        path: '/designManagement/designPage',
        name: 'designPage',
        meta: {
          i18n: 'test',
          title: '项目概念设计'
        },
        component: () => import('@/views/designManagement/designPage.vue')
      },
      {
        path: '/designManagement/components/checkDetail',
        name: 'checkDetail',
        meta: {
          i18n: 'test',
          title: '查看'
        },
        component: () => import('@/views/designManagement/components/checkDetail.vue')
      },
      {
        path: '/pawn/pawnManagement/index',
        name: 'pawnManagement',
        meta: {
          i18n: 'test',
          title: '抵押物管理'
        },
        component: () => import('@/views/pawn/pawnManagement.vue')
      },
      {
        path: '/pawn/pawnManagement/pawnDetail',
        name: 'pawnDeatail',
        meta: {
          i18n: 'test',
          title: '抵押物详情'
        },
        component: () => import('@/views/pawn/pawnManagement/pawnDetail.vue')
      },
      {
        path: '/personnel/index',
        name: 'personnel',
        meta: {
          i18n: 'test',
          title: '人员管理'
        },
        component: () => import('@/views/personnel/index.vue')
      },
      {
        path: '/moneyManagement/index',
        name: 'moneyManagement',
        meta: {
          title: '资金管理',
          i18n: 'test'
        },
        component: () => import('@/views/moneyManagement/index.vue')
      },
      {
        path: '/moneyManagement/transactionRecord',
        name: 'transactionRecord',
        meta: {
          title: '交易记录',
          i18n: 'test'
        },
        component: () => import('@/views/moneyManagement/component/transactionRecord.vue')
      },
      {
        path: '/moneyManagement/pending',
        name: 'pending',
        meta: {
          title: '待处理',
          i18n: 'test'
        },
        component: () => import('@/views/moneyManagement/component/pending.vue')
      },
      {
        path: '/moneyManagement/addUpEarning',
        name: 'addUpEarning',
        meta: {
          title: '项目收益',
          i18n: 'test'
        },
        component: () => import('@/views/moneyManagement/component/addUpEarning.vue')
      },
      {
        path: '/message/center/index',
        name: 'message',
        meta: {
          i18n: 'test',
          title: '消息中心'
        },
        redirect: '/message/center/news',
        component: () => import(/* webpackChunkName: "views" */ '@/views/message/center.vue'),
        children: [
          {
            path: '/message/center/news',
            name: 'news',
            meta: {
              i18n: 'test',
              title: '消息',
              parentRoute: '/message/center/index'
            },
            component: () => import(/* webpackChunkName: "views" */ '@/views//message/center/news.vue')
          },
          {
            path: '/message/center/meetings',
            name: 'meetings',
            meta: {
              i18n: 'test',
              title: '会议',
              parentRoute: '/message/center/index'
            },
            component: () => import(/* webpackChunkName: "views" */ '@/views/message/center/meetings.vue')
          },
          {
            path: '/message/center/approvals',
            name: 'approvals',
            meta: {
              i18n: 'test',
              title: '审批',
              parentRoute: '/message/center/index'
            },
            component: () => import(/* webpackChunkName: "views" */ '@/views/message/center/approvals.vue')
          },
          {
            path: '/message/center/todos',
            name: 'todos',
            meta: {
              i18n: 'test',
              title: '待办',
              parentRoute: '/message/center/index'
            },
            component: () => import(/* webpackChunkName: "views" */ '@/views//message/center/todos.vue')
          },
          {
            path: '/message/center/meetings/join',
            name: 'meetings',
            meta: {
              i18n: 'test',
              title: '加入会议',
              parentRoute: '/message/center/index'
            },
            component: () => import(/* webpackChunkName: "views" */ '@/views/message/center/meetings/join.vue')
          },
          {
            path: '/message/center/meetings/quick',
            name: 'meetings',
            meta: {
              i18n: 'test',
              title: '快速会议',
              parentRoute: '/message/center/index'
            },
            component: () => import(/* webpackChunkName: "views" */ '@/views/message/center/meetings/quick.vue')
          },
          {
            path: '/message/center/meetings/predetermined',
            name: 'meetings',
            meta: {
              i18n: 'test',
              title: '预定会议',
              parentRoute: '/message/center/index'
            },
            component: () => import(/* webpackChunkName: "views" */ '@/views/message/center/meetings/predetermined.vue')
          },
          {
            path: '/message/center/meetings/detail',
            name: 'meetings',
            meta: {
              i18n: 'test',
              title: '预定会议',
              parentRoute: '/message/center/index'
            },
            component: () => import(/* webpackChunkName: "views" */ '@/views/message/center/meetings/detail.vue')
          }
        ]
      },
      {
        path: '/propertyManagement/index',
        name: 'propertyManagement',
        meta: {
          title: '楼盘管理',
          i18n: 'test'
        },
        component: () => import('@/views/propertyManagement/index.vue')
      },
      {
        path: '/propertyManagement/propertyInformation',
        name: 'propertyInformation',
        meta: {
          title: '楼盘信息',
          i18n: 'test'
        },
        component: () => import('@/views/propertyManagement/propertyInformation')
      },
      {
        path: '/customerManagement/index',
        name: 'customerManagement',
        meta: {
          title: '客户管理',
          i18n: 'test'
        },
        component: () => import('@/views/customerManagement/index.vue'),
        redirect: '/customerManagement/customerPool',
        children: [
          {
            path: '/customerManagement/customerPool',
            name: 'customerPool',
            meta: {
              title: '客户池',
              i18n: 'test',
              parentRoute: '/customerManagement/index'
            },
            component: () => import('@/views/customerManagement/customerPool/index.vue')
          },
          {
            path: '/customerManagement/myCustomers',
            name: 'myCustomers',
            meta: {
              title: '我的客户',
              i18n: 'test',
              parentRoute: '/customerManagement/index'
            },
            component: () => import('@/views/customerManagement/customers/index.vue')
          }
        ]
      },
      {
        path: '/customerManagement/newCustomersPool',
        name: 'newCustomersPool',
        meta: {
          title: '添加客户',
          i18n: 'test'
        },
        component: () => import('@/views/customerManagement/components/newCustomersPool.vue')
      },
      {
        path: '/customerManagement/customersPoolDetails',
        name: 'customersPoolDetails',
        meta: {
          title: '客户详情',
          i18n: 'test'
        },
        component: () => import('@/views/customerManagement/components/customersPoolDetails.vue')
      },
      {
        path: '/customerManagement/newCustomers',
        name: 'newCustomers',
        meta: {
          title: '添加我的客户',
          i18n: 'test'
        },
        component: () => import('@/views/customerManagement/components/newCustomers.vue')
      },
      {
        path: '/customerManagement/newCustomers',
        name: 'editsCustomers',
        meta: {
          title: '编辑我的客户',
          i18n: 'test'
        },
        component: () => import('@/views/customerManagement/components/newCustomers.vue')
      },
      {
        path: '/customerManagement/customersDetails',
        name: 'customersDetails',
        meta: {
          title: '我的客户详情',
          i18n: 'test'
        },
        component: () => import('@/views/customerManagement/components/customersDetails.vue')
      },
      {
        path: '/customerManagement/customersDetails',
        name: 'customersPoolDetails',
        meta: {
          title: '客户池客户详情',
          i18n: 'test'
        },
        component: () => import('@/views/customerManagement/components/customersDetails.vue')
      },
      {
        path: '/personalInformation',
        name: 'personalInformation',
        meta: {
          title: '个人信息',
          i18n: 'test'
        },
        component: () => import('@/views/personalInformation/index.vue')
      },
      {
        path: '/personalCenter/index',
        name: 'personalCenter',
        meta: {
          title: '个人中心',
          i18n: 'test'
        },
        component: () => import('@/views/personalCenter/index.vue'),
        redirect: '/personalCenter/idealists',
        children: [
          {
            path: '/personalCenter/idealists',
            name: 'idealists',
            meta: {
              title: '理想家',
              i18n: 'test',
              parentRoute: '/personalCenter/index'
            },
            component: () => import('@/views/personalCenter/idealists/index.vue')
          },
          {
            path: '/personalCenter/myCollection',
            name: 'myCollection',
            meta: {
              title: '我的收藏',
              i18n: 'test',
              parentRoute: '/personalCenter/index'
            },
            component: () => import('@/views/personalCenter/myCollection/index.vue')
          },
          {
            path: '/personalCenter/myProperty',
            name: 'myProperty',
            meta: {
              title: '我的房产',
              i18n: 'test',
              parentRoute: '/personalCenter/index'
            },
            component: () => import('@/views/personalCenter/myProperty/index.vue')
          },
          {
            path: '/personalCenter/contractManagement',
            name: 'contractManagement',
            meta: {
              title: '合同管理',
              i18n: 'test',
              parentRoute: '/personalCenter/index'
            },
            component: () => import('@/views/personalCenter/contractManagement/index.vue')
          }
        ]
      },
      {
        path: '/lookingEstate/index',
        name: 'lookingEstate',
        meta: {
          title: '寻找楼盘',
          i18n: 'test'
        },
        component: () => import('@/views/lookingEstate/index.vue')
      },
      {
        path: '/lookingEstate/lookingEstateDetails',
        name: 'lookingEstateDetails',
        meta: {
          title: '详情',
          i18n: 'test',
          parentRoute: '/lookingEstate/index'
        },
        component: () => import('@/views/lookingEstate/lookingEstateDetails/index.vue')
      },
      {
        path: '/lookingEstate/findDesign',
        name: 'findDesign',
        meta: {
          title: '找设计',
          i18n: 'test',
          parentRoute: '/lookingEstate/index'
        },
        component: () => import('@/views/lookingEstate/findDesign/index.vue')
      },
      {
        path: '/laborService/index',
        name: 'laborService',
        meta: {
          title: '劳务中心',
          i18n: 'test'
        },
        component: () => import(/* webpackChunkName: "views" */ '@/views/LaborServiceSystem/laborService/index.vue')
      }
    ]
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/about/aboutView.vue')
  },
  {
    path: '/land/mall/plotMap',
    name: 'plotMap',
    meta: {
      i18n: 'map',
      parentRoute: '/land/mall/index'
    },
    component: () => import(/* webpackChunkName: "views" */ '@/components/Map/plotMap.vue')
  },
  {
    path: '/information/home',
    name: 'home',
    meta: {
      i18n: 'test',
      title: '首页'
    },
    component: () => import(/* webpackChunkName: "views" */ '@/views//information/home.vue')
  },
  {
    path: '/information/home/detail',
    name: 'detail',
    meta: {
      i18n: 'test',
      title: '详情'
    },
    component: () => import(/* webpackChunkName: "views" */ '@/views/information/home/detail.vue')
  },
  {
    path: '/information/home/search',
    name: 'detail',
    meta: {
      i18n: 'test',
      title: '搜索'
    },
    component: () => import(/* webpackChunkName: "views" */ '@/views/information/home/search.vue')
  },
  {
    path: '/information/home/subject',
    name: 'subject',
    meta: {
      i18n: 'test',
      title: '专题板块'
    },
    component: () => import(/* webpackChunkName: "views" */ '@/views/information/home/subject.vue')
  },
  {
    path: '/information/home/industry',
    name: 'industry',
    meta: {
      i18n: 'test',
      title: '行业报告'
    },
    component: () => import(/* webpackChunkName: "views" */ '@/views/information/home/industry.vue')
  },
  {
    path: '/information/home/hot',
    name: 'hot',
    meta: {
      i18n: 'test',
      title: '热门标签'
    },
    component: () => import(/* webpackChunkName: "views" */ '@/views/information/home/hot.vue')
  },
  {
    path: '/LaborServiceSystem/index',
    name: 'laborServiceSystem',
    meta: {
      title: '劳务中心',
      i18n: 'test'
    },
    component: () => import(/* webpackChunkName: "views" */ '@/views/LaborServiceSystem/index.vue'),
    redirect: '/LaborServiceSystem/home',
    children: [
      {
        path: '/LaborServiceSystem/home',
        name: 'laborServiceHome',
        meta: {
          title: '劳务首页',
          i18n: 'test',
          parentRoute: '/LaborServiceSystem/index'
        },
        component: () => import('@/views/LaborServiceSystem/components/home.vue')
      },
      {
        path: '/LaborServiceSystem/findJob',
        name: 'findJob',
        meta: {
          title: '找活干',
          i18n: 'test',
          parentRoute: '/LaborServiceSystem/index'
        },
        component: () => import('@/views/LaborServiceSystem/components/findJob.vue')
      },
      {
        path: '/LaborServiceSystem/findWorker',
        name: 'findWorker',
        meta: {
          title: '找工人',
          i18n: 'test',
          parentRoute: '/LaborServiceSystem/index'
        },
        component: () => import('@/views/LaborServiceSystem/components/findWorker.vue')
      },
      {
        path: '/LaborServiceSystem/findJobDetail',
        name: 'findJobDetail',
        meta: {
          title: '找工人详情',
          i18n: 'test',
          parentRoute: '/LaborServiceSystem/index'
        },
        component: () => import('@/views/LaborServiceSystem/components/findJobDetail.vue')
      },
      {
        path: '/LaborServiceSystem/immediateCommunicate',
        name: 'immediateCommunicate',
        meta: {
          title: '立即沟通',
          i18n: 'test',
          parentRoute: '/LaborServiceSystem/index'
        },
        component: () => import('@/views/LaborServiceSystem/components/immediateCommunicate.vue')
      },
      {
        path: '/LaborServiceSystem/laborPersonalCenter',
        name: 'laborPersonalCenter',
        meta: {
          title: '个人中心',
          i18n: 'test',
          parentRoute: '/LaborServiceSystem/index'
        },
        component: () => import('@/views/LaborServiceSystem/laborPerson/laborPersonalCenter.vue')
      },
      {
        path: '/LaborServiceSystem/laborPersonalInformation',
        name: 'laborPersonalInformation',
        meta: {
          title: '个人信息',
          i18n: 'test',
          parentRoute: '/LaborServiceSystem/index'
        },
        component: () => import('@/views/LaborServiceSystem/laborPerson/laborPersonalInformation.vue')
      },
      {
        path: '/LaborServiceSystem/myCollection',
        name: 'myCollection',
        meta: {
          title: '我的收藏',
          i18n: 'test',
          parentRoute: '/LaborServiceSystem/index'
        },
        component: () => import('@/views/LaborServiceSystem/laborPerson/myCollection.vue'),
        children: [
          {
            path: '/LaborServiceSystem/myCollection/goodMaster',
            name: 'goodMaster',
            meta: {
              title: '好师傅',
              i18n: 'test',
              parentRoute: '/LaborServiceSystem/index'
            },
            component: () => import('@/views/LaborServiceSystem/laborPerson/components/goodMaster.vue')
          },
          {
            path: '/LaborServiceSystem/myCollection/goodWork',
            name: 'goodWork',
            meta: {
              title: '好工作',
              i18n: 'test',
              parentRoute: '/LaborServiceSystem/index'
            },
            component: () => import('@/views/LaborServiceSystem/laborPerson/components/goodWork.vue')
          }
        ]
      },
      {
        path: '/LaborServiceSystem/laborPersonalCenterDetails',
        name: 'laborPersonalCenterDetails',
        meta: {
          title: '编辑个人信息',
          i18n: 'test',
          parentRoute: '/LaborServiceSystem/index'
        },
        component: () => import('@/views/LaborServiceSystem/laborPerson/laborPersonalCenterDetails.vue')
      },
      {
        path: '/LaborServiceSystem/myRecruitmentInformation',
        name: 'myRecruitmentInformation',
        meta: {
          title: '我的招工信息',
          i18n: 'test',
          parentRoute: '/LaborServiceSystem/index'
        },
        component: () => import('@/views/LaborServiceSystem/laborPerson/myRecruitmentInformation.vue')
      },
      {
        path: '/LaborServiceSystem/publishRecruitment',
        name: 'publishRecruitment',
        meta: {
          title: '发布招工信息',
          i18n: 'test',
          parentRoute: '/LaborServiceSystem/index'
        },
        component: () => import('@/views/LaborServiceSystem/laborPerson/publishRecruitment.vue')
      },
      {
        path: '/LaborServiceSystem/editRecruitment',
        name: 'editRecruitment',
        meta: {
          title: '编辑招工信息',
          i18n: 'test',
          parentRoute: '/LaborServiceSystem/index'
        },
        component: () => import('@/views/LaborServiceSystem/laborPerson/publishRecruitment.vue')
      },
      {
        path: '/LaborServiceSystem/resettingRecruitment',
        name: 'resettingRecruitment',
        meta: {
          title: '重新发布招工信息',
          i18n: 'test',
          parentRoute: '/LaborServiceSystem/index'
        },
        component: () => import('@/views/LaborServiceSystem/laborPerson/publishRecruitment.vue')
      }
    ]
  },
  {
    path: '/laborPerson',
    name: 'laborPerson',
    meta: {
      title: '完善劳务个人信息'
    },
    component: () => import('@/views/LaborServiceSystem/laborPerson/index.vue')
  },
  {
    path: '/mapBuilding/index',
    name: 'mapBuilding',
    meta: {
      title: '地图楼盘'
    },
    component: () => import('@/views/mapBuilding/index.vue')
  },
  // 微信登陆路由请不要动
  {
    path: '/oauth/redirect/wechat_open',
    name: 'oauth',
    meta: {
      title: '登录',
      isAuth: false
    },
    component: () => import('@/views/login/index.vue')
  },
  // 微信webview页面路由
  {
    path: '/webview/index',
    name: 'webview',
    meta: {
      title: 'webview',
      isAuth: false
    },
    component: () => import('@/model/3D/house.vue')
  }
]

// const router = new VueRouter({
//   mode: 'history',
//   routes
// })

export default routes

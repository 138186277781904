// 数据字典转换
export const fmtText = (arr, keyValue) => {
  if (arr) {
    let textArr = arr.filter((item) => item.dictKey === keyValue?.toString())
    // console.log(textArr);
    return textArr.length > 0 && textArr[0].dictValue
  }
}

// 项目进度字典转换
export const progressStatus = (keyValue) => {
  if (keyValue === 0) {
    return '未开始'
  } else if (keyValue === 1) {
    return '进行中'
  } else if (keyValue === 2) {
    return '已关闭'
  }
}

// 项目进度字典转换
export const decorationStandardType = (keyValue) => {
  if (keyValue === 1) {
    return '精装'
  } else if (keyValue === 2) {
    return '简装'
  } else if (keyValue === 3) {
    return '毛坯'
  }
}

// 项目进度项目状态
export const currentStateType = (keyValue) => {
  if (keyValue === 0) {
    return '未开始'
  } else if (keyValue === 1) {
    return '进行中'
  } else if (keyValue === 2) {
    return '已关闭'
  }
}

// 地产楼盘字典转换
export const towerType = (keyValue) => {
  if (keyValue === 1) {
    return '住宅'
  } else if (keyValue === 2) {
    return '洋房'
  } else if (keyValue === 3) {
    return '别墅'
  } else if (keyValue === 4) {
    return '公寓'
  }
}

// 我的客户 客户状态
export const userStatus = (keyValue) => {
  if (keyValue === 1) {
    return '未跟进'
  } else if (keyValue === 2) {
    return '跟进中'
  } else if (keyValue === 3) {
    return '签约中'
  } else if (keyValue === 4) {
    return '已成交'
  }
}

// 购房预算
export const budgetTypes = (keyValue) => {
  if (keyValue === 1) {
    return '30万以下'
  } else if (keyValue === 2) {
    return '30-50万'
  } else if (keyValue === 3) {
    return '50-80万'
  } else if (keyValue === 4) {
    return '80-100万'
  } else if (keyValue === 5) {
    return '100-150万'
  } else if (keyValue === 6) {
    return '150-200万'
  } else if (keyValue === 7) {
    return '200-250万'
  } else if (keyValue === 8) {
    return '250-300万'
  } else if (keyValue === 9) {
    return '300万以上'
  }
}

// 购房用途
export const demandTypes = (keyValue) => {
  if (keyValue === 1) {
    return '投资'
  } else if (keyValue === 2) {
    return '自住'
  } else if (keyValue === 3) {
    return '养老'
  }
}

// 意向户型
export const houseTypes = (keyValue) => {
  if (keyValue === 1) {
    return '一居室'
  } else if (keyValue === 2) {
    return '二居室'
  } else if (keyValue === 3) {
    return '三居室'
  } else if (keyValue === 4) {
    return '四居室'
  } else if (keyValue === 5) {
    return '五居室'
  }
}

// 楼层类型
export const floorTypes = (keyValue) => {
  if (keyValue === 1) {
    return '低楼层'
  } else if (keyValue === 2) {
    return '中楼层'
  } else if (keyValue === 3) {
    return '高楼层'
  }
}

//朝向
export const orientationTypes = (keyValue) => {
  if (keyValue === 1) {
    return '南北'
  } else if (keyValue === 2) {
    return '朝南'
  } else if (keyValue === 3) {
    return '朝东'
  } else if (keyValue === 4) {
    return '朝北'
  } else if (keyValue === 5) {
    return '朝西'
  }
}

// 购房面积

export const areaTypes = (keyValue) => {
  if (keyValue === 1) {
    return '50㎡以下'
  } else if (keyValue === 2) {
    return '50-70㎡'
  } else if (keyValue === 3) {
    return '70-90㎡'
  } else if (keyValue === 4) {
    return '90-120㎡'
  } else if (keyValue === 5) {
    return '120㎡以上'
  }
}

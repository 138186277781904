// 附件
import request from '@/router/axios'

// 附件查询
export const attachment = (id, queryType, type) => {
  return request({
    url: `api/arch-zshb/attach/${id}/${queryType}/${type}`,
    method: 'get'
  })
}

const getters = {
  tag: (state) => state.tags.tag,
  language: (state) => state.common.language,
  website: (state) => state.common.website,
  userInfo: (state) => state.user.userInfo,
  colorName: (state) => state.common.colorName,
  themeName: (state) => state.common.themeName,
  isShade: (state) => state.common.isShade,
  isCollapse: (state) => state.common.isCollapse,
  keyCollapse: (state, getters) => (getters.screen > 1 ? getters.isCollapse : false),
  screen: (state) => state.common.screen,
  isLock: (state) => state.common.isLock,
  isFullScren: (state) => state.common.isFullScren,
  isMenu: (state) => state.common.isMenu,
  lockPasswd: (state) => state.common.lockPasswd,
  tagList: (state) => state.tags.tagList,
  tagWel: (state) => state.tags.tagWel,
  token: (state) => state.user.token,
  title1: (state) => state.title.title1,
  roles: (state) => state.user.roles,
  permission: (state) => state.user.permission,
  menu: (state) => state.user.menu,
  menuId: (state) => state.user.menuId,
  menuAll: (state) => state.user.menuAll,
  logsList: (state) => state.logs.logsList,
  logsLen: (state) => state.logs.logsList.length || 0,
  logsFlag: (state, getters) => getters.logsLen === 0,
  flowRoutes: (state) => state.dict.flowRoutes,
  input: (state) => state.input,
  companyList: (state) => state.user.company,
  companyDetails: (state) => state.user.companyDetails,
  automatic: (state) => state.user.automatic,
  dictionaries: (state) => state.dictionaries.dictionaries || [],
  title: (state) => state.title.title || {},
  title2: (state) => state.title.title2 || [],
  plan: (state) => state.title.plan || {},
  skip: (state) => state.perfect.skip || '',
  status: (state) => state.perfect.status || 'false',
  personal: (state) => state.personal.personal || {},
  laborStatus: (state) => state.laborPerson.laborStatus || '',
  currentCityCode: (state) => state.laborPerson.currentCityCode || '',
  currentCity: (state) => state.laborPerson.currentCity || '',
  jurisdiction: (state) => state.jurisdiction.jurisdiction || ''
}
export default getters

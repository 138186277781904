<template>
  <div class="planCover">
    <div class="container">
      <div class="img"></div>
      <div class="info">
        <div class="title">
          <h1>{{ coverList.itemPropertyPlan?.itemName }}</h1>
        </div>
        <div class="main">
          <ul>
            <li>
              <div class="block">
                <span>项目类型：</span>
                <p v-if="dictionariesList['building_label']">
                  {{ fmtText(dictionariesList['building_label'], coverList.itemType) }}
                </p>
              </div>
            </li>
            <li>
              <div class="block">
                <span>设计单位：</span>
                <p>{{ coverList.itemDesignPlanVO?.companyName || ''}}</p>
              </div>
            </li>
            <li>
              <div class="block">
                <span>施工单位：</span>
                <p>{{ coverList.itemConstructionPlanVo?.companyName || ''}}</p>
              </div>
            </li>
            <li>
              <div class="block">
                <span>销售单位：</span>
                <p>{{ coverList.itemSellingPlanVO?.companyName || ''}}</p>
              </div>
            </li>
            <li>
              <div class="block">
                <span>承建单位：</span>
                <p>{{ coverList.itemPropertyPlan?.companyName || ''}}</p>
              </div>
            </li>
            <li>
              <div class="block">
                <span>运营单位：</span>
                <p>{{ coverList.itemOperationPlanVO?.companyName || ''}}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fmtText } from '@/utils/filters.js'
import { mapGetters } from 'vuex'
export default {
  name: 'planCover',
  props: {
    coverList: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      fmtText: fmtText,
      dictionariesList: []
    }
  },
  watch: {
    dictionaries: {
      handler (newVal, oldVal) {
        this.dictionariesList = newVal
      }
    },
    coverList: {
      handler (newVal, oldVal) {
        console.log(newVal);
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapGetters(['dictionaries'])
  },
  async mounted () {
    await this.$store.dispatch('dictionaries', 'building_label')
    this.dictionariesList = this.dictionaries
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/planDetail/planCover.scss';
</style>

<template>
  <el-dialog title="地址" :visible.sync="vShow" :width="width" :before-close="handleCancle" :modal-append-to-body="false">
    <address-select @getAddress="getAddress" />
    <span slot="footer" class="dialog-footer">
      <el-button @click.prevent="handleCancle">取 消</el-button>
      <el-button type="primary" @click.prevent="handleClose">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'addressDialog',
  data() {
    return {
      dialogVisible1: true,
      address: '',
      codeAddress: []
    }
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '50%'
    }
  },
  computed: {
    vShow() {
      const res = this.dialogVisible && this.dialogVisible1
      return res
    }
  },
  methods: {
    getAddress(data) {
      let res = ''
      let res1 = []
      data.forEach((item) => {
        item.options.forEach((item1) => {
          if (item.value) {
            if (item.value == item1.value && res != item1.title) {
              res += item1.title
              res1.push(item1.value)
              this.address = res
              this.codeAddress = res1
            }
          }
        })
      })
    },
    handleClose() {
      this.$emit('getAddressInfo', this.address)
      this.$emit('getAddressInfoCode', this.codeAddress)
    },
    handleCancle() {
      this.dialogVisible1 = false
      this.$emit('getAddressInfo', null)
      this.dialogVisible1 = true
    }
  }
}
</script>

<style scoped lang="scss">
.el-dialog {
  height: 100vh;
}
</style>

<template>
  <el-form
    label-width="120px"
    size="mini"
  >
    <el-row>
      <i class="icon"></i>
      <span class="nav">公司简介</span>
    </el-row>
    <div class="companyInfo">
      <el-form-item label="公司名称">
        <span>{{formData.companyName}}</span>
      </el-form-item>
      <el-form-item label="公司描述">
        <span>{{formData.companyDesc}}</span>
      </el-form-item>
    </div>
    <el-row>
      <i class="icon"></i>
      <span class="nav">销售目标</span>
    </el-row>
    <div class="sellTarget">
      <el-form-item label="销售套数">
        <span>{{formData.quantityTarget}}套</span>
      </el-form-item>
      <el-form-item label="销售金额">
        <span>{{totalPriceTarget}}</span>
      </el-form-item>
      <el-form-item label="销售周期">
        <span>{{formData.sellCycle}}天</span>
      </el-form-item>
    </div>
    <el-row>
      <i class="icon"></i>
      <span class="nav">销售方案</span>
    </el-row>
    <el-form-item label="销售方案">
      <span>{{formData.planDesc}}</span>
    </el-form-item>
    <el-row>
      <i class="icon"></i>
      <span class="nav">销售卖点</span>
    </el-row>
    <el-form-item label="销售方案">
      <span>{{formData.sellingPoint}}</span>
    </el-form-item>
    <el-row>
      <i class="icon"></i>
      <span class="nav">销售工作阶段</span>
    </el-row>
    <div class="phase">
      <div class="general">
        <span>总负责人</span>
        <span>{{formData.generalPrincipalName}}</span>
      </div>
      <div class="phase-content">
        <div class="phase-title">
          <i></i>
          <span>项目宣传预热</span>
        </div>
        <el-form-item label="负责人"> {{formData.warmUpPrincipalName}} </el-form-item>
        <el-form-item label="周期">{{formData.warmUpRequiredDays}}天</el-form-item>
        <el-form-item label="所需资金">{{warmUpRequiredFunds}}</el-form-item>
      </div>
      <div class="phase-content">
        <div class="phase-title">
          <i></i>
          <span>售出20%</span>
        </div>
        <el-form-item label="负责人">{{formData.sell20PercentPrincipalName}} </el-form-item>
        <el-form-item label="周期">{{formData.sell20PercentRequiredDays}}天</el-form-item>
        <el-form-item label="利润分成">{{sell20PercentRequiredFunds}}</el-form-item>
      </div>
      <div class="phase-content">
        <div class="phase-title">
          <i></i>
          <span>售出40%</span>
        </div>
        <el-form-item label="负责人">{{formData.sell40PercentPrincipalName}}</el-form-item>
        <el-form-item label="周期">{{formData.sell40PercentRequiredDays}}天</el-form-item>
        <el-form-item label="利润分成">{{sell40PercentRequiredFunds}}</el-form-item>
      </div>
      <div class="phase-content">
        <div class="phase-title">
          <i></i>
          <span>售出60%</span>
        </div>
        <el-form-item label="负责人">{{formData.sell60PercentPrincipalName}}</el-form-item>
        <el-form-item label="周期">{{formData.sell60PercentRequiredDays}}天</el-form-item>
        <el-form-item label="利润分成">{{sell60PercentRequiredFunds}}</el-form-item>
      </div>
      <div class="phase-content">
        <div class="phase-title">
          <i></i>
          <span>售出80%</span>
        </div>
        <el-form-item label="负责人">{{formData.sell80PercentPrincipalName}}</el-form-item>
        <el-form-item label="周期">{{formData.sell80PercentRequiredDays}}天</el-form-item>
        <el-form-item label="利润分成">{{sell80PercentRequiredFunds}}</el-form-item>
      </div>
      <div class="phase-content">
        <div class="phase-title">
          <i></i>
          <span>售出100%</span>
        </div>
        <el-form-item label="负责人">{{formData.sell100PercentPrincipalName}}</el-form-item>
        <el-form-item label="周期">{{formData.sell100PercentRequiredDays}}天</el-form-item>
        <el-form-item label="利润分成">{{sell100PercentRequiredFunds}}</el-form-item>
      </div>
    </div>
  </el-form>
</template>
  
<script>
export default {
  props: {
    itemSellingPlanVO: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      formData: {
        companyName: '',
        companyDesc: '',
        quantityTarget: '',
        totalPriceTarget: '',
        sellCycle: '',
        planDesc: '',
        sellingPoint: '',
        warmUpRequiredDays: '',
        warmUpRequiredFunds: '',
        sell20PercentRequiredDays: '',
        sell20PercentRequiredFunds: '',
        sell40PercentRequiredDays: '',
        sell40PercentRequiredFunds: '',
        sell60PercentRequiredDays: '',
        sell60PercentRequiredFunds: '',
        sell80PercentRequiredDays: '',
        sell80PercentRequiredFunds: '',
        sell100PercentRequiredDays: '',
        sell100PercentRequiredFunds: '',
        generalPrincipalName: '',
        warmUpPrincipalName: '',
        sell20PercentPrincipalName: '',
        sell40PercentPrincipalName: '',
        sell60PercentPrincipalName: '',
        sell80PercentPrincipalName: '',
        sell100PercentPrincipalName: '',
      },
    }
  },
  watch: {
    itemSellingPlanVO: {
      handler (newVal, oldval) {
        if(newVal){
          this.formData = newVal
        }
      },
      deep: true,
      //立即执行
      immediate: true
    }
  },
  computed: {
    totalPriceTarget () {
      return (this.formData.totalPriceTarget / 10000) + '万' || 0 + '万'
    },
    warmUpRequiredFunds () {
      return (this.formData.warmUpRequiredFunds / 10000) + '万' || 0 + '万'
    },
    sell20PercentRequiredFunds () {
      return (this.formData.sell20PercentRequiredFunds / 10000) + '万' || 0 + '万'
    },
    sell40PercentRequiredFunds () {
      return (this.formData.sell40PercentRequiredFunds / 10000) + '万' || 0 + '万'
    },
    sell60PercentRequiredFunds () {
      return (this.formData.sell60PercentRequiredFunds / 10000) + '万' || 0 + '万'
    },
    sell80PercentRequiredFunds () {
      return (this.formData.sell80PercentRequiredFunds / 10000) + '万' || 0 + '万'
    },
    sell100PercentRequiredFunds () {
      return (this.formData.sell100PercentRequiredFunds / 10000) + '万' || 0 + '万'
    }
  }
}
  </script>
  
  <style lang="scss" scoped>
.el-form {
  padding: 0 60px 0 30px;
  .el-row {
    margin: 16px 0;
    height: 30px;
    line-height: 30px;
    .icon {
      display: inline-block;
      width: 4px;
      height: 12px;
      background-color: #3a71ff;
      margin-right: 10px;
    }
    .nav {
      font-size: 14px;
      color: #101010;
      font-weight: 500;
    }
  }
  .sellTarget {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .phase {
    padding-left: 50px;
    .phase-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .phase-title {
        width: 120px;
        position: relative;
        span {
          color: #101010;
          font-size: 14px;
        }
        i {
          position: absolute;
          left: -15px;
          top: 10px;
          display: inline-block;
          width: 5px;
          height: 5px;
          background-color: #3a71ff;
          border-radius: 50%;
        }
      }
    }
    ::v-deep .el-form-item {
      .el-form-item__label {
        min-width: 100px;
      }
      .el-form-item__content {
        min-width: 100px;
      }
    }
    .general {
      margin: 20px 0;
      span {
        font-size: 14px;
        margin-right: 50px;
      }
    }
  }
}
</style>
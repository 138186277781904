/**
 *存储字典
 */

import { getStore, setStore } from '@/storage/index'

import { getDictionaries } from '@/api/system/dictionaries.js'
const dictionaries = {
  state: {
    dictionaries: getStore({ name: 'dictionaries' }) || []
  },
  actions: {
    //业务字典
    dictionaries({ commit }, type) {
      return new Promise((resolve, reject) => {
        getDictionaries(type)
          .then((res) => {
            commit('DICTIONARIES', res.data.data)
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  },
  mutations: {
    //业务字典
    DICTIONARIES: (state, data) => {
      state.dictionaries[data[0].code] = data
      // 将字典存储到 localStorage
      setStore({ name: 'dictionaries', content: state.dictionaries })
    }
  }
}

export default dictionaries

import request from '@/router/axios'
// 查询企业邀请成员列表
export const personnelList = (params) => {
  return request({
    url: '/api/arch-zshb/company/company-user-invite-list',
    method: 'get',
    params: {
      ...params
    }
  })
}

// 验证手机号
export const checkPhone = (params) => {
  return request({
    url: '/api/arch-zshb/company/check-phone-add-company',
    method: 'post',
    data: params
  })
}

export const invitePeople = (params) => {
  return request({
    url: '/api/arch-zshb/company/add-user',
    method: 'post',
    data: params
  })
}

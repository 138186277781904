<template>
  <div class="pd-show">
    <div class="bigImg">
      <div class="img" ref="img">
        <img :src="bigImg" alt />
      </div>
      <div class="mask"></div>
      <div class="superBigImg">
        <img class="img1" :src="bigImg" alt />
      </div>
    </div>
    <div class="selectImg">
      <ul class="imgs">
        <li
          :class="{ select: index == select }"
          v-for="(item, index) in imgs"
          :key="index"
          @click="handleSelect(index)"
        >
          <img :src="item.link" alt />
        </li>
      </ul>
      <div class="last hover1" @click="lastImg()">
        <i class="iconfont iconicon_next_arrow"></i>
      </div>
      <div class="next hover1" @click="nextImg()">
        <i class="iconfont iconicon_next_arrow"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'pd-show',
  data() {
    return {
      bigImg: '',
      select: 0,
      imgsX: 0 //图片选择位置参数
    }
  },
  props: {
    imgs: {
      type: Array,
      default: () => {
        return [
          {
            link: 'https://ts2.cn.mm.bing.net/th?id=OIP-C.qQKiZmNZqM39tKUHPUAkIwHaE5&w=307&h=203&c=8&rs=1&qlt=90&o=6&pid=3.1&rm=2'
          },
          {
            link: 'https://tse1-mm.cn.bing.net/th/id/OIP-C.nRlAFygdctTCHmIWN7GxRwHaEK?w=294&h=180&c=7&r=0&o=5&pid=1.7'
          },
          {
            link: 'https://ts2.cn.mm.bing.net/th?id=OIP-C.qQKiZmNZqM39tKUHPUAkIwHaE5&w=307&h=203&c=8&rs=1&qlt=90&o=6&pid=3.1&rm=2'
          },
          {
            link: 'https://tse1-mm.cn.bing.net/th/id/OIP-C.nRlAFygdctTCHmIWN7GxRwHaEK?w=294&h=180&c=7&r=0&o=5&pid=1.7'
          },
          {
            link: 'https://ts2.cn.mm.bing.net/th?id=OIP-C.qQKiZmNZqM39tKUHPUAkIwHaE5&w=307&h=203&c=8&rs=1&qlt=90&o=6&pid=3.1&rm=2'
          },
          {
            link: 'https://tse1-mm.cn.bing.net/th/id/OIP-C.nRlAFygdctTCHmIWN7GxRwHaEK?w=294&h=180&c=7&r=0&o=5&pid=1.7'
          }
        ]
      }
    }
  },
  mounted() {
    this.handleZoom()
  },
  methods: {
    handleSelect(index) {
      this.bigImg = this.imgs[index].link
      this.select = index
    },
    lastImg() {
      let imgs = document.getElementsByClassName('imgs')[0]
      let img_num = this.imgs.length
      // console.log(img_num);
      let blockW = [23 + 8 / 3]
      let imgsX = this.imgsX
      imgsX += blockW
      if (imgsX > 0) {
        this.imgsX = -(img_num - 4) * blockW
      } else {
        this.imgsX = imgsX
      }
      imgs.style.transform = `translate(${this.imgsX}%, ${0}px)`
    },
    nextImg() {
      let imgs = document.getElementsByClassName('imgs')[0]
      let img_num = this.imgs.length
      let blockW = [23 + 8 / 3]
      let imgsW = (img_num - 4) * blockW
      // console.log(img_num);
      let imgsX = this.imgsX
      imgsX += -blockW
      if (imgsX < -imgsW) {
        this.imgsX = 0
      } else {
        this.imgsX = imgsX
      }
      imgs.style.transform = `translate(${this.imgsX}%, ${0}px)`
    },
    // 蒙版移动时候大图移动
    handleMove(el) {
      el.stopPropagation()
      // img.removeEventListener('mouseenter',this.handleEnter)
      let bigImg = document.getElementsByClassName('bigImg')[0]
      let bigImgW = parseFloat(getComputedStyle(bigImg)['width'])
      let bigImgH = parseFloat(getComputedStyle(bigImg)['height'])
      // console.log(bigImgW, bigImgH)
      let mask = document.getElementsByClassName('mask')[0]
      let maskW = parseFloat(getComputedStyle(mask)['width'])
      let maskH = parseFloat(getComputedStyle(mask)['height'])
      // console.log(el.offsetX, el.offsetY)
      let superBigImg = document.getElementsByClassName('img1')[0]
      let moveX = el.offsetX
      let moveY = el.offsetY
      let maskX = moveX - maskW / 2
      let maskY = moveY - maskH / 2
      if (maskX < maskW / 2) {
        maskX = 0
      }
      if (maskY < maskH / 2) {
        maskY = 0
      }
      if (maskX > bigImgW - maskW) {
        maskX = bigImgW - maskW
      }
      if (maskY > bigImgH - maskH) {
        maskY = bigImgH - maskH
      }
      mask.style.transform = `translate(${maskX}px, ${maskY}px)`
      superBigImg.style.transform = `translate(${-(maskX * 1.25 * 2)}px, ${-maskY * 1.25 * 2}px)`
      // mask.style.top = maskY + 'px'
      // mask.style.left = maskX + 'px'
    },
    handleZoom() {
      // console.log(e.target);
      let img = document.getElementsByClassName('img')[0]
      img.addEventListener('mousemove', this.handleMove)
    }
  },
  watch: {
    imgs: {
      handler(data) {
        this.bigImg = data[0]?.link
      },
      immediate: true
    }
  },
  beforeDestroy() {
    let img = this.$refs.img
    // 页面退出取消绑定事件
    img.removeEventListener('mousemove', this.handleMove)
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/land/ldShow.scss';
</style>

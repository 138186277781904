import request from '@/router/axios'

// 征集项目总览
export const projectOverView = (cycle) => {
  return request({
    url: `/api/arch-zshb/item/plan/report/${cycle}`,
    method: 'get'
  })
}

// 获取征集项目列表
export const projectlList = (params) => {
  return request({
    url: '/api/arch-zshb/item/plan/list',
    method: 'get',
    params
  })
}

// 增加征集项目列表
export const addProject = (data) => {
  return request({
    url: '/api/arch-zshb/item/plan/save',
    method: 'post',
    data
  })
}

// 删除征集项目列表
export const delProject = (id) => {
  return request({
    url: '/api/arch-zshb/item/plan/remove',
    method: 'delete',
    params: { id }
  })
}

// 修改征集项目列表
export const updateProject = (data) => {
  return request({
    url: '/api/arch-zshb/item/plan/update',
    method: 'put',
    data
  })
}

// 查看征集项目详情
export const projectDetail = (id) => {
  return request({
    url: '/api/arch-zshb/item/plan/detail',
    method: 'get',
    params: { id }
  })
}

// 查询地块资产
export const getField = () => {
  return request({
    url: '/api/arch-zshb/assets/field',
    method: 'get'
  })
}

// 查询征集企业列表
export const registeList = (params) => {
  return request({
    url: '/api/arch-zshb/item/plan/register/company',
    method: 'get',
    params
  })
}

// 查询收藏企业列表
export const collectList = (params) => {
  return request({
    url: '/api/arch-zshb/item/plan/collect/company',
    method: 'get',
    params
  })
}

// 查询报名方案详情
export const registerPlan = (id) => {
  return request({
    url: '/api/arch-zshb/item/plan/register/plan',
    method: 'get',
    params: { id }
  })
}

// 选中中标人
export const checkPartner = (params) => {
  return request({
    url: '/api/arch-zshb/item/plan/checkPartner',
    method: 'put',
    params
  })
}

// 项目征集确认方案
export const checkPlan = (registerId) => {
  return request({
    url: '/api/arch-zshb/item/plan/checkPlan',
    method: 'put',
    params: { registerId }
  })
}

// 查询报名方案详情
export const plandetail = (id) => {
  return request({
    url: '/api/arch-zshb/item/plan/register/plan',
    method: 'get',
    params: { id }
  })
}

// 查询集成方案
export const allPlan = (itemCode) => {
  return request({
    url: '/api/arch-zshb/item/plan/allPlan',
    method: 'get',
    params: { itemCode }
  })
}

// 取消暂停项目征集
export const cancelPause = (params) => {
  return request({
    url: '/api/arch-zshb/item/plan/update/status',
    method: 'put',
    params
  })
}

// 查询征集时间-报名-收藏-数量
export const financingAllNum = (params) => {
  return request({
    url: '/api/arch-zshb/item/plan/num',
    method: 'get',
    params
  })
}

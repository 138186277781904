<template>
  <div class="mapPlugin" @click="plotMap()">
    <div class="img">
      <img src="@/assets/img/land/icon-map@3x.png" alt />
    </div>
    <div class="contain">
      <p>地图找地</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'mapPlugin',
  data() {
    return {}
  },
  methods: {
    plotMap() {
      let routeData = this.$router.resolve({
        path: '/land/mall/plotMap'
      })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>

<style scoped>
.mapPlugin {
  position: fixed;
  top: 50%;
  right: 20px;
  z-index: 100;
  width: 72px;
  height: 40px;
  padding: 10px;
  background-color: #fff;
  border-radius: 10px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.img {
  width: 50%;
  height: 100%;
}

img {
  width: 100%;
  height: 100%;
}

.contain {
  width: 40%;
}

p {
  width: 100%;
  font-size: 12px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #3a71ff;
  line-height: 14px;
}
</style>
import { getStore, setStore } from '@/storage/index'

import { getDictionary } from '@/api/system/dict'

const land = {
  state: {
    input:"",
  },
  actions: {
    getInput({commit},data){
      commit("GET_INPUT",data)
    },
  },
  mutations: {
    GET_INPUT(state,data){
      state.input = data
      // console.log(data);
    },
  }
}

export default land

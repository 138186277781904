// 资讯
import request from '@/router/axios'

// 查询前10个最热的标签
export const getInformationLable = () => {
  return request({
    url:"/api/arch-zshb/information/getInformationLable",
    method: "get"
  })
}

// 行业分析-洞见未来
export const getIndustryReportsNews = () => {
  return request({
    url:"/api/arch-zshb/hot/news/getIndustryReportsNews",
    method: "get"
  })
}

// 精选推荐
export const getInformationList = (params) => {
  return request({
    url: '/api/arch-zshb/hot/news/getSelectedRecommendNews',
    method: 'get',
    params: {
      ...params
    }
  })
}

// 详情
export const getInformationDetail = (params) => {
  return request({
    url: '/api/arch-zshb/hot/news/homePageDetail',
    method: 'get',
    params: {
      ...params
    }
  })
}
/**
 *首页跳转状态存储
 */

import { getStore, setStore } from '@/storage/index'

const jurisdiction = {
  state: {
    jurisdiction: getStore({ name: 'jurisdiction' }) || 'true'
  },
  actions: {},
  mutations: {
    //存储信息
    SET_JURISDICTION: (state, jurisdiction) => {
      state.jurisdiction = jurisdiction
      // 将状态存储到 localStorage
      setStore({ name: 'jurisdiction', content: state.jurisdiction })
    },
    //清除跳转状态信息
    CLEAR_JURISDICTION: (state, jurisdiction) => {
      state.jurisdiction = jurisdiction
      // 将状态存储到 localStorage
      setStore({ name: 'jurisdiction', content: state.jurisdiction })
    }
  }
}

export default jurisdiction

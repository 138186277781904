import request from '@/router/axios'

// 获取资产总览
export const overViewlList = () => {
  return request({
    url: '/api/arch-zshb/assets/report',
    method: 'get'
  })
}

// 获取资产列表
export const propertylList = (params) => {
  return request({
    url: '/api/arch-zshb/assets/list',
    method: 'get',
    params
  })
}

// 获取资产详情
export const propertyDetail = (id) => {
  return request({
    url: '/api/arch-zshb/assets/detail',
    method: 'get',
    params: { id }
  })
}

// 删除资产列表
export const removeproperty = (id) => {
  return request({
    url: 'api/arch-zshb/assets/remove',
    method: 'delete',
    params: { id }
  })
}

// 增加资产列表
export const saveproperty = data => {
  return request({
    url: 'api/arch-zshb/assets/save',
    method: 'post',
    data
  })
}

// 修改资产列表
export const updateDetail = data => {
  return request({
    url: 'api/arch-zshb/assets/update',
    method: 'put',
    data
  })
}


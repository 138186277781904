// 施工方案分页查询
import request from '@/router/axios';

// 项目征集: 查询 报名 方案详情
export const getBuildPlanContent = (params) => {
  return request({
    url: '/api/arch-zshb/item/plan/register/plan',
    method: 'get',
    params
  })
}

// 施工方案:修改
export const alterBuildPlan = (params) => {
  return request({
    url: '/api/arch-zshb/construction/update',
    method: 'post',
    data:params
  })
}
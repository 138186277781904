import request from '@/router/axios'

// 获取总额统计
export const totalCount = (companyId) => {
  return request({
    url: `/api/arch-zshb/finance/management/totalCount/get/${companyId}`,
    method: 'get'
  })
}

// 获取投资资金分析图表
export const itemFunds = (companyId, isPresent) => {
  return request({
    url: `/api/arch-zshb/finance/management/itemFunds/list/${companyId}/${isPresent}`,
    method: 'get'
  })
}

// 获取交易记录列表
export const dealFlowPage = (data) => {
  return request({
    url: `/api/arch-zshb/finance/management/dealFlow/page`,
    method: 'post',
    data
  })
}

// 获取当前公司的项目
export const itemList = () => {
  return request({
    url: '/api/arch-zshb/finance/item/list',
    method: 'get'
  })
}

// 获取当前公司的项目
export const fundsReport = (itemCode) => {
  return request({
    url: '/api/arch-zshb/finance/item/fundsReport',
    method: 'get',
    params: { itemCode }
  })
}

// 获取项目资金详情-解冻明细
export const thawFundsDetail = (params) => {
  return request({
    url: '/api/arch-zshb/finance/item/thawFunds/detail',
    method: 'get',
    params
  })
}

// 项目资金详情: 项目款待支付 分页查询
export const payList = (params) => {
  return request({
    url: `/api/arch-zshb/finance/item/pay/list`,
    method: 'get',
    params
  })
}

// 项目资金详情: 解冻申请 分页查询
export const thawFundsApply = (params) => {
  return request({
    url: `/api/arch-zshb/finance/item/thawFunds/apply`,
    method: 'get',
    params
  })
}
// 资金管理:资金变动趋势图表
export const fundsMovementList = (companyId) => {
  return request({
    url: `/api/arch-zshb/finance/management/fundsMovement/list/${companyId}`,
    method: 'get'
  })
}

// 资金管理:待处理-支付
export const itemPay = (params) => {
  return request({
    url: '/api/arch-zshb/finance/item/pay',
    method: 'post',
    params
  })
}

// 资金管理:待处理-解冻
export const thawFunds = (id) => {
  return request({
    url: '/api/arch-zshb/finance/item/thawFunds',
    method: 'post',
    params: id
  })
}

import request from '@/router/axios'
// 地产名录分页查询
export const plotName = (params) => {
  return request({
    url: '/api/arch-zshb/company/property/list',
    method: 'get',
    params: {
      ...params
    }
  })
}

// 地产名录导出
export const getExcel = (params) => {
  return request({
    url: '/api/arch-zshb/company/property/excel',
    method: 'get',
    params,
    responseType: 'blob'
  })
}

// 投资机构
export const investmentName = (params) => {
  return request({
    url: '/api/arch-zshb/company/finance/list',
    method: 'get',
    params: {
      ...params
    }
  })
}

// 投资机构导出
export const getExcel1 = (params) => {
  return request({
    url: '/api/arch-zshb/company/finance/excel',
    method: 'get',
    params,
    responseType: 'blob'
  })
}

// 获取建筑类型
export const getBuildingType = (params) => {
  return request({
    url: '/api/arch-zshb/company/buildingType',
    method: 'get',
    params
  })
}

// 地块商城
export const getPlotMallList = (params) => {
  return request({
    url: '/api/arch-zshb/field/mall/list',
    method: 'get',
    params
  })
}

// 我的报名列表
export const getApply = (params) => {
  return request({
    url: '/api/arch-zshb/register/field',
    method: 'get',
    params
  })
}

// 报名状态
export const applyStatus = (params) => {
  return request({
    url: '/api/arch-zshb/register',
    method: 'post',
    data: params
  })
}

// 地块商城: 修改报名方案
export const applyUpdatePlan = (params) => {
  return request({
    url: '/api/arch-zshb/register/updatePlan',
    method: 'put',
    data: params
  })
}

// 查询报名方案
export const getPropertyPlan = (params) => {
  return request({
    url: '/api/arch-zshb/register/plan',
    method: 'get',
    params
  })
}

//收藏状态
export const collectStatus = (params) => {
  return request({
    url: '/api/arch-zshb/collect',
    method: 'post',
    params
  })
}

// 地块详情
export const landDetail = (params) => {
  return request({
    url: '/api/arch-zshb/field/detail',
    method: 'get',
    params
  })
}

// 地产名录详情：企业详情
export const companyDetail = (id) => {
  return request({
    url: '/api/arch-zshb/company/detail',
    method: 'get',
    params: { id }
  })
}

// 地产名录详情: 查询合作方信息
export const partnersFinance = (params) => {
  return request({
    url: '/api/arch-zshb/company/partners/finance/page',
    method: 'get',
    params
  })
}

// 投资名录详情: 查询合作方信息
export const partnersProperty = (params) => {
  return request({
    url: '/api/arch-zshb/company/partners/property/page',
    method: 'get',
    params
  })
}

<template>
  <div class="peopleSelect">
    <div class="container">
      <div class="left">
        <ul>
          <li class="people" v-for="(item, index) in peopleList" :key="index">
            <p>{{ item.userName }}</p>
            <i class="el-icon-circle-close" @click="handlerPeopleDelect(index)"></i>
          </li>
          <li class="input">
            <input type="text" v-model="keyWord" placeholder="搜索名字、手机号..." @input="getSearchPeopleList()" />
            <div class="input-select">
              <ul>
                <li v-for="(item, index) in searchList" :key="index" @click="handlerSelectPeople(item)">
                  <img :src="item.avatar" alt="" />
                  <p>{{ item.userName }}-{{ item.companyName }}</p>
                </li>
              </ul>
            </div>
          </li>
        </ul>
        <div class="button">
          <el-button type="primary" size="small" @click="handlerPeopleList()"
            >确定({{ peopleList.length }}/50)</el-button
          >
          <el-button size="small" @click="handlerCancle()">取消</el-button>
        </div>
      </div>
      <div class="right">
        <div class="main">
          <ul>
            <li v-show="item.itemCompanys" v-for="(item, index) in itemCompanyList" :key="index">
              <div
                class="itemCompanys"
                v-for="(item2, index2) in item.itemCompanys"
                :key="index2 + 'b'"
                @click="handleChange1(index, index2)"
              >
                <div class="companyTab">
                  <div class="img">
                    <img :src="item2.itemCompany.attachLink" alt />
                  </div>
                  <div class="selectItemCompanyPeople">
                    <p>{{ item2.itemCompany.companyName || '(无)' }}</p>
                    <i :class="item2.showPeopleList == false ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"></i>
                  </div>
                </div>
                <div class="peopleList" v-if="item2.showPeopleList">
                  <ul>
                    <li
                      v-for="(item1, index1) in item2.peopleList"
                      :key="index1 + 'a'"
                      @click="handlerSelectPeople(item1)"
                    >
                      <img :src="item1.avatar" alt="" />
                      <p>{{ item1.userName }}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getItemCompanyList, getItemCompanyPeopleList, getSearchPeopleList } from '@/api/message/index.js'
export default {
  name: 'peopleSelect',
  data() {
    return {
      keyWord: '',
      itemCompanyList: [
        {
          companyType: 'property',
          itemCompanys: []
        },
        {
          companyType: 'finance',
          itemCompanys: []
        },
        {
          companyType: 'design',
          itemCompanys: []
        },
        {
          companyType: 'engineering',
          itemCompanys: []
        },
        {
          companyType: 'seller',
          itemCompanys: []
        },
        {
          companyType: 'operator',
          itemCompanys: []
        }
      ],
      peopleList: [],
      showItemCompanyList: false,
      searchList: []
    }
  },
  props: {
    itemCode: {
      type: String,
      default: ''
    }
  },
  methods: {
    getItemCompanyList() {
      this.reset()
      // console.log('所有公司');
      getItemCompanyList({ itemCode: this.itemCode }).then((res) => {
        console.log(res.data.data, '所有公司')
        let data = res.data.data
        data.forEach((item, index) => {
          this.itemCompanyList.forEach((item1, index1) => {
            if (item.companyType == item1.companyType) {
              let data = {
                itemCompany: item,
                showPeopleList: false,
                peopleList: []
              }
              this.itemCompanyList[index1].itemCompanys.push(data)
            }
          })
          // console.log(this.itemCompanyList)
        })
      })
    },
    getItemCompanyPeopleList(index, index2) {
      let code = this.itemCompanyList[index].itemCompanys[index2].itemCompany.itemCode
      let id = this.itemCompanyList[index].itemCompanys[index2].itemCompany.companyId
      // console.log(code, id)
      getItemCompanyPeopleList({ itemCode: code, companyId: id }).then(async (res) => {
        console.log(res.data.data)
        this.itemCompanyList[index].itemCompanys[index2].peopleList = res.data.data
      })
      // console.log(this.itemCompanyList)
    },
    handleChange1(index, index2) {
      this.getItemCompanyPeopleList(index, index2)
      this.itemCompanyList[index].itemCompanys[index2].showPeopleList =
        !this.itemCompanyList[index].itemCompanys[index2].showPeopleList
    },
    handlerSelectPeople(item1) {
      let peopleIdList = []
      this.peopleList.forEach((item) => {
        peopleIdList.push(item.userId + item.companyId)
      })
      if (peopleIdList.includes(item1.userId + item1.companyId)) {
        this.$message('已添加')
      } else if (this.peopleList.length > 50) {
        this.$message('已达最大人数')
      } else {
        this.peopleList.push(item1)
      }
      this.searchList = []
    },
    handlerPeopleDelect(index) {
      this.peopleList.splice(index, 1)
    },
    handlerPeopleList() {
      this.$emit('getUserList', this.peopleList)
      this.reset()
    },
    getSearchPeopleList() {
      let data = {
        itemCode: this.itemCode,
        pramValue: this.keyWord
      }
      getSearchPeopleList(data).then((res) => {
        // console.log(res.data.data, 'search')
        this.searchList = res.data.data
      })
    },
    handlerCancle() {
      this.$emit('handlerCancle')
      this.reset()
    },
    reset() {
      this.peopleList = []
      // this.itemCompanyList = [
      //   {
      //     companyType: 'property',
      //     itemCompanys: []
      //   },
      //   {
      //     companyType: 'finance',
      //     itemCompanys: []
      //   },
      //   {
      //     companyType: 'design',
      //     itemCompanys: []
      //   },
      //   {
      //     companyType: 'engineering',
      //     itemCompanys: []
      //   },
      //   {
      //     companyType: 'seller',
      //     itemCompanys: []
      //   },
      //   {
      //     companyType: 'operator',
      //     itemCompanys: []
      //   }
      // ]
    }
  },
  created() {
  },
  watch: {
    itemCode: {
      immediate: true,
      deep: false,
      handler(data) {
        this.itemCompanyList = [
          {
            companyType: 'property',
            itemCompanys: []
          },
          {
            companyType: 'finance',
            itemCompanys: []
          },
          {
            companyType: 'design',
            itemCompanys: []
          },
          {
            companyType: 'engineering',
            itemCompanys: []
          },
          {
            companyType: 'seller',
            itemCompanys: []
          },
          {
            companyType: 'operator',
            itemCompanys: []
          }
        ]
        // console.log(data);
        this.getItemCompanyList()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.peopleSelect {
  width: 100%;
  height: 300px;
}
.container {
  width: 100%;
  display: flex;
}
.left {
  width: 50%;
  border-right: 1px solid rgba(153, 153, 153, 0.4);
  height: 300px;
  position: relative;
  ul {
    width: 100%;
    height: 250px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    overflow: auto;
    li {
      width: 100%;
      height: 26px;
      line-height: 26px;
      // margin: 5px 5px 0 0;
    }
    .input {
      width: 100%;
      position: relative;
      input {
        border: none;
        font-size: 14px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #999999;
        line-height: 24px;
      }
      .input-select {
        ul {
          width: 100%;
          position: absolute;
          left: 0;
          top: 26px;
          z-index: 2;
          overflow: auto;
          li {
            width: 80%;
            height: 26px;
            padding: 5px;
            box-sizing: border-box;
            line-height: 26px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            img {
              width: 20px;
              height: 20px;
              background-color: azure;
              border-radius: 50% 50%;
            }
            p {
              width: calc(100% - 40px);
              margin-left: 20px;
              font-size: 14px;
              font-family: Source Han Sans CN-Regular, Source Han Sans CN;
              font-weight: 400;
              color: #101010;
              white-space: nowrap;
            }
            &:hover {
              box-shadow: 0px 0px 5px 0px rgba(58, 113, 255, 0.25);
            }
          }
        }
      }
    }
    .people {
      width: 70px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: rgba(153, 153, 153, 0.2);
      border-radius: 4px 4px;
      box-sizing: border-box;
      p {
        width: 50px;
        text-align: center;
        font-size: 14px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #101010;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      i {
        width: 20px;
        cursor: pointer;
        border-radius: 50% 50%;
        min-width: 14px;
        height: 14px;
        text-align: center;
        // background-color: #cacaca;
        overflow: hidden;
        color: #101010;
      }
    }
  }
  .button {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
}
.right {
  width: 50%;
  box-sizing: border-box;
  padding: 0 16px;
  .main {
    width: 100%;
    height: 250px;
    overflow: scroll;
    ul {
      li {
        margin-top: 10px;
        width: 100%;
        .itemCompanys {
          width: 100%;
          .companyTab {
            height: 26px;
            line-height: 26px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .img {
              width: 20%;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              img {
                width: 20px;
                height: 20px;
                background: #d9d9d9;
                border-radius: 1px 1px 1px 1px;
              }
            }
            .selectItemCompanyPeople {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 80%;
              p {
                width: 70%;
                font-size: 14px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #101010;
              }
            }
            &:hover {
              background-color: rgba(153, 153, 153, 0.2);
              color: #3a71ff;
            }
          }
          .peopleList {
            width: 100%;
            ul {
              width: 100%;
              li {
                width: 100%;
                height: 26px;
                line-height: 26px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-left: 20px;
                img {
                  width: 20px;
                  height: 20px;
                  border-radius: 50% 50%;
                }
                p {
                  margin-left: 20px;
                  width: 70%;
                  font-size: 14px;
                  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                  font-weight: 400;
                  color: #101010;
                }
                &:hover {
                  box-shadow: 0px 0px 5px 0px rgba(58, 113, 255, 0.25);
                }
              }
            }
          }
        }
      }
    }
  }
}

p,
span {
  overflow: hidden;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>

// 消息中心
import request from '@/router/axios'

// 审批
// 发起审批
export const initiateApproval = function name(params) {
  return request({
    url: '/api/arch-zshb/approve/save',
    method: 'post',
    data: params
  })
}

// 我的创建
export const myCreateApproval = function name(params) {
  return request({
    url: '/api/arch-zshb/approve/myCreateList',
    method: 'get',
    params
  })
}
// 我的审批
export const approvalList = function name(params) {
  return request({
    url: '/api/arch-zshb/approve/getMyApprovalList',
    method: 'get',
    params
  })
}
// 我的抄送
export const getCcMyList = function name(params) {
  return request({
    url: '/api/arch-zshb/approve/getCcMyList',
    method: 'get',
    params
  })
}

// 审核
export const audit = function name(params) {
  return request({
    url: '/api/arch-zshb/approve/audit',
    method: 'post',
    data: params
  })
}

// 查询当前公司的所有项目
export const getItemList = function name(params) {
  return request({
    url: '/api/arch-zshb/approve/getItemList',
    method: 'get'
  })
}

// 查询项目相关公司
export const getItemCompanyList = function name(params) {
  return request({
    url: '/api/arch-zshb/approve/getAssociatedCompany',
    method: 'get',
    params
  })
}

// 查询项目相关公司人员信息
export const getItemCompanyPeopleList = function name(params) {
  return request({
    url: '/api/arch-zshb/approve/getPersonnel',
    method: 'get',
    params
  })
}

// 根据条件检索人员信息

export const getSearchPeopleList = function name(params) {
  return request({
    url: '/api/arch-zshb/approve/getPerListByNameAndPhone',
    method: 'get',
    params
  })
}

// 待审核项目分页列表

export const designPlanPageTodo = function name(size, current) {
  return request({
    url: `/api/arch-zshb/item/designPlan/page/todo?size=${size}&current=${current}`,
    method: 'get'
  })
}
// 待审核项目分页列表

export const designPlanAudit = function name(data) {
  return request({
    url: '/api/arch-zshb/item/designPlan/audit',
    method: 'put',
    data
  })
}

// 会议
// 项目列表
export const getMeetingProject = function name(data, size, current) {
  return request({
    url: `/api/arch-zshb/itemProgress/list?size=${size}&current=${current}`,
    method: 'get',
    data
  })
}

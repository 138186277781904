// 终端个人中心接口
import request from '@/router/axios'

// 终端: 我的理想加新增
export const idealAddition = (data) => {
  return request({
    url: '/api/arch-zshb/customerDemand/insert',
    method: 'post',
    data
  })
}

// 终端: 理想家列表
export const getIdealAdditionList = (data) => {
  return request({
    url: '/api/arch-zshb/customerDemand/list',
    method: 'get'
  })
}

// 终端:  合同列表
export const myContractList = () => {
  return request({
    url: '/api/arch-zshb/house/get/mine',
    method: 'get'
  })
}

//终端: 查询收藏的房屋列表
export const myCollectList = () => {
  return request({
    url: '/api/arch-zshb/realEstate/collect/list',
    method: 'get'
  })
}

// 终端: (取消)收藏房屋
export const cancelCollection = (params) => {
  return request({
    url: '/api/arch-zshb/realEstate/collect',
    method: 'post',
    params
  })
}

// 终端:  保存贷款方案
export const loanSave = (data) => {
  return request({
    url: '/api/arch-zshb/house/loan/save',
    method: 'post',
    data
  })
}

// 购买
export const buySubmit = (data) => {
  return request({
    url: '/api/arch-zshb/house/deal/submit',
    method: 'post',
    data
  })
}

// 取消交易
export const cancelTransactions = (houseId) => {
  return request({
    url: `/api/arch-zshb/house/deal/cancel/${houseId}`,
    method: 'post'
  })
}

// 签订合同
export const signContracts = (houseId) => {
  return request({
    url: `/api/arch-zshb/house/deal/signUp/${houseId}`,
    method: 'post'
  })
}

// 付款
export const toPay = (houseId) => {
  return request({
    url: `/api/arch-zshb/house/deal/pay/${houseId}`,
    method: 'post'
  })
}

import request from '@/router/axios'

// 楼盘管理: 首页分页查询
export const sellerInfo = (params) => {
  return request({
    url: '/api/arch-zshb/realEstate/page',
    method: 'get',
    params
  })
}

// 获取楼盘详情
export const sellerDetail = (id) => {
  return request({
    url: '/api/arch-zshb/realEstate/detail',
    method: 'get',
    params: { id }
  })
}

// 获取楼盘户型信息
export const housetypeInfo = (realEstateId) => {
  return request({
    url: '/api/arch-zshb/realEstate/findHouseTypeByRealEstateId',
    method: 'get',
    params: { realEstateId }
  })
}

// 楼盘管理: 售卖情况-楼盘售卖情况
export const salesInfo = (realEstateId) => {
  return request({
    url: '/api/arch-zshb/houseSell/findSalesByRealEstateId',
    method: 'get',
    params: { realEstateId }
  })
}

// 楼盘管理: 售卖情况-售卖月趋势
export const monthlyTrend = (realEstateId) => {
  return request({
    url: '/api/arch-zshb/houseSell/findMonthlyTrendByRealEstateId',
    method: 'get',
    params: { realEstateId }
  })
}

// 楼盘管理: 售卖情况-楼盘最受欢迎
export const mostPopular = (realEstateId) => {
  return request({
    url: '/api/arch-zshb/houseSell/findMostPopularByRealEstateId',
    method: 'get',
    params: { realEstateId }
  })
}

// 楼盘管理: 售卖情况-售卖列表
export const sellList = (params) => {
  return request({
    url: '/api/arch-zshb/houseSell/list',
    method: 'get',
    params
  })
}

// 楼盘管理: 售卖情况-查询楼栋号
export const findBuilding = (realEstateId) => {
  return request({
    url: '/api/arch-zshb/houseSell/findBuildingByRealEstateId',
    method: 'get',
    params: { realEstateId }
  })
}

// 楼盘管理: 售卖情况-查询单元号
export const findUnitNo = (params) => {
  return request({
    url: '/api/arch-zshb/houseSell/findUnitNoByRealEstateId',
    method: 'get',
    params
  })
}

<template>
  <div v-if="showSelf" class="dialog" :style="{'z-index': zIndex}">
    <div class="dialog-mark" @click.self="closeMyself" :style="{'z-index': zIndex + 1}"></div>
    <transition name="dialog">
      <div class="dialog-sprite" :style="{'z-index': zIndex + 2}">
        <!-- 标题 -->
        <section class="header">
          <div class="header-title" v-if="title"> {{ title }}</div>
          <img class="header-close" src="@/assets/img/popNotification/icon-close.png" @click="cancel" />
        </section>
        <!-- 弹窗的主题内容 -->
        <section class="dialog-body">
          <slot></slot>
        </section>

        <!-- 按钮 -->
        <section class="dialog-footer">
          <div v-if="showCancel" class="btn btn-refuse" @click="cancel">{{cancelText}}</div>
          <div class="btn btn-confirm" @click="confirm">{{confirmText}}</div>
        </section>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'popNotification',
  props: {
    //弹窗组件是否显示 默认不显示 必传属性
    show: {
      type: Boolean,
      default: false,
      required: true,
    },
    //标题
    title: {
      type: String,
      required: true,
    },
    showCancel: {
      typs: Boolean,
      default: false,
      required: false,
    },
    cancelText: {
      type: String,
      default: '取消',
      required: false,
    },
    confirmText: {
      type: String,
      default: '确定',
      required: false,
    },
  },
  data() {
    return {
      name: 'dialog',
      showSelf: false,
      zIndex: this.getZIndex(),
      bodyOverflow: ''
    }
  },
  watch: {
    show(val) {
      if (!val) {
        this.closeMyself()
      } else {
        this.showSelf = val
      }
    }
  },
  created() {
    this.showSelf = this.show;
  },
  mounted() {
    this.forbidScroll()
  },
  methods: {
    /** 禁止页面滚动 */
    forbidScroll() {
      this.bodyOverflow = document.body.style.overflow
      document.body.style.overflow = 'hidden'
    },

    /**  每次获取以后 zindex 自动增长 */
    getZIndex() {
      let zIndexInit = 20190315;
      return zIndexInit++
    },

    /** 取消按钮操做 */
    cancel() {
      this.$emit('cancel', false);
    },

    /** 确认按钮操做 */
    confirm() {
      this.$emit('confirm', false)
    },

    /** 点击遮罩关闭弹窗 */
    closeMyself(event) {
      this.showSelf = false;
      this.sloveBodyOverflow()
    },

    /** 恢复页面的滚动 */
    sloveBodyOverflow() {
      document.body.style.overflow = this.bodyOverflow;
    },
  }
}
</script>

<style lang="scss" scoped>
// 弹窗动画
.dialog-enter-active,
.dialog-leave-active {
  transition: opacity .5s;
}

.dialog-enter,
.dialog-leave-to {
  opacity: 0;
}

// 最外层 设置position定位 
// 遮罩 设置背景层，z-index值要足够大确保能覆盖，高度 宽度设置满 作到全屏遮罩
.dialog {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;

  // 内容层 z-index要比遮罩大，不然会被遮盖
  .dialog-mark {
    position: absolute;
    top: 0;
    height: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .6);
  }
}

.dialog-sprite {
  // 使用felx布局
  position: absolute;
  top: 50%;
  left: 50%;
  right: 15%;
  bottom: 25%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  max-width: 284px;
  max-height: 200px;
  min-width: 184px;
  min-height: 121px;
  overflow: hidden;
  z-index: 23456765435;
  background: #fff;
  border-radius: 8px;

  .header {
    display: flex;
    flex-direction: row;
    padding: 12px;
    text-align: center;
    font-size: 15px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 700;
    color: #1C2030;
    border-bottom: 1px solid #EDEFF6;

    &-title {
      flex: content;
    }
  }


  .header-close {
    width: 10px;
    height: 10px;
  }

  .dialog-body {
    flex: 1;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 0 15px 20px 15px;
  }

  .dialog-footer {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    // flex-shrink: 1;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      transform: scaleY(.5);
    }

    .btn {
      flex: 1;
      text-align: center;
      margin: 10px 15px 20px 15px;
      background: #3A71FF;
      border-radius: 10px;
      font-size: 8px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      max-width: 50px;
      min-width: 50px;

      &:nth-child(2) {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 1px;
          height: 100%;
          background: #ddd;
          transform: scaleX(.5);
        }
      }
    }

    .btn-refuse {
      background-color: #fff;
      color: rgba(0, 0, 0, 0.6);
      border: 1px solid rgba(0, 0, 0, 0.6);
      padding: 10px;
    }

    .btn-confirm {
      padding: 10px;
      color: #fff;
    }
  }
}
</style>
import request from '@/router/axios'

// 获取地块列表
export const plotlList = (params) => {
  return request({
    url: '/api/arch-zshb/field/list',
    method: 'get',
    params
  })
}

// 获取总览
export const overView = (cycle) => {
  return request({
    url: `/api/arch-zshb/field/report/${cycle}`,
    method: 'get'
  })
}

// 获取地块详情
export const getDetail = (id) => {
  return request({
    url: '/api/arch-zshb/field/detail',
    method: 'get',
    params: { id }
  })
}

// 增加地块
export const addPlot = (row) => {
  return request({
    url: '/api/arch-zshb/field/save',
    method: 'post',
    data: row
  })
}

// 删除地块
export const delPlot = (ids) => {
  return request({
    url: '/api/arch-zshb/field/remove',
    method: 'delete',
    params: { ids }
  })
}

// 修改地块
export const editPlot = (data) => {
  return request({
    url: '/api/arch-zshb/field/update',
    method: 'put',
    data
  })
}

// 获取附件详情
export const attachment = (fieldId, type) => {
  return request({
    url: `api/arch-zshb/attach/field/${fieldId}/${type}`,
    method: 'get'
  })
}

// 土地售卖地块报名
export const register = (params) => {
  return request({
    url: 'api/arch-zshb/register/company',
    method: 'get',
    params
  })
}

// 土地售卖地块收藏
export const plotCollect = (params) => {
  return request({
    url: '/api/arch-zshb/collect/field',
    method: 'get',
    params
  })
}

// 选中中标人
export const checkPartner = (id) => {
  return request({
    url: '/api/arch-zshb/register/check',
    method: 'put',
    params: { id }
  })
}

// 修改地块状态
export const updateStatus = (params) => {
  return request({
    url: '/api/arch-zshb/field/update/status',
    method: 'put',
    params
  })
}

// 查询地产方案
export const registerPlan = (id) => {
  return request({
    url: '/api/arch-zshb/register/plan',
    method: 'get',
    params: { id }
  })
}

import request from '@/router/axios'

export const getList = (current, size, params) => {
  return request({
    url: '/api/blade-system/region/list',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}
/* 返回下级地区 */
export const getLazyTree = (parentCode, params) => {
  return request({
    url: '/api/blade-system/region/lazy-tree',
    method: 'get',
    params: {
      ...params,
      parentCode
    }
  })
}

export const getDetail = (code) => {
  return request({
    url: '/api/blade-system/region/detail',
    method: 'get',
    params: {
      code
    }
  })
}

export const remove = (id) => {
  return request({
    url: '/api/blade-system/region/remove',
    method: 'post',
    params: {
      id
    }
  })
}

export const submit = (row) => {
  return request({
    url: '/api/blade-system/region/submit',
    method: 'post',
    data: row
  })
}

// 获取类型
export const getType = (code) => {
  return request({
    url: '/api/blade-system/dict-biz/dictionary',
    method: 'get',
    params: { code }
  })
}

// 获取附件
export const getAttach = (id, queryType, type) => {
  return request({
    url: `api/arch-zshb/attach/${id}/${queryType}/${type}`,
    method: 'get'
  })
}

// 获取方案企业名称
export const companyNameList = (type) => {
  return request({
    url: 'api/arch-zshb/company/nameList',
    method: 'get',
    params: { type }
  })
}

// 获取方案企业名称
export const personnelList = (companyId) => {
  return request({
    url: 'api/arch-zshb/company/personnelList',
    method: 'get',
    params: { companyId }
  })
}

// 高德地图模型加载demo

import * as turf from '@turf/turf'
import AMapLoader from '@amap/amap-jsapi-loader'
export default {
  data() {
    return {
      map: null,
      AMap: null,
      mapCenter: null,
      placeSearch: null,
      searchData: [],
      page: {
        size: 5,
        current: 1,
        total: null
      },
      show1: false,
      overlays: [],
      walking: null,
      selected: null,
      markerList: []
    }
  },
  props: {
    center: {
      type: Array,
      default: () => {
        return [116.472605, 39.992075]
      }
    },
    polygon: {
      type: Array,
      default: () => {
        return [
          // {
          //   color: 'red',
          //   points: [
          //     [116.371587, 39.928234],
          //     [116.382122, 39.918775],
          //     [116.36964, 39.917182],
          //     [116.362609, 39.924676]
          //   ]
          // },
          // {
          //   color: 'blue',
          //   points: [
          //     [116.3689, 39.91342],
          //     [116.38212, 39.90117],
          //     [116.38727, 39.9125],
          //     [116.39825, 39.904]
          //   ]
          // }
        ]
      }
    },
    text: {
      type: String,
      default: null
    },
    around: {
      type: String,
      default: '学校'
    },
    range: {
      type: Number,
      default: 0
    },
    distenceType: {
      type: Number,
      default: 1
    }
  },
  mounted() {
    AMapLoader.reset()
    if (this.map) {
      this.map.destroy()
    }
    this.initAMap()
  },
  methods: {
    initAMap() {
      if (!this.map) {
        AMapLoader.load({
          key: 'b0994daf461a68b2817f4aa36167ca82', // 申请好的Web端开发者Key，首次调用 load 时必填
          version: '1.4.15', //指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
          plugins: ['AMap.GltfLoader', 'AMap.PlaceSearch', 'AMap.Walking'], //需要使用的的插件列表，如比例尺'AMap.Scale'等
          AMapUI: {
            // 是否加载 AMapUI，缺省不加载
            version: '1.1',
            plugins: [] // 需要加载的 AMapUI ui插件
          },
          Loca: {
            // 是否加载 Loca， 缺省不加载
            version: '1.0'
          }
        })
          .then((AMap) => {
            this.map = new AMap.Map('container', {
              //设置地图容器id
              rotateEnable: true,
              pitchEnable: true,
              zoom: 17,
              pitch: 55,
              rotation: -45,
              viewMode: '3D', //开启3D视图,默认为关闭
              zooms: [2, 20],
              showBuildingBlock: false
            })
            this.AMap = AMap
            this.setCenter()
            // 模型
            this.loadModel()
            // 搜索
            this.search()
          })
          .catch((e) => {
            console.log(e)
          })
      }
    },
    loadModel() {
      // 创建Object3DLayer图层
      var object3Dlayer = new AMap.Object3DLayer()
      this.map.add(object3Dlayer)

      var urlBuilding = 'http://192.168.31.10:9000/arch/upload/20221214/44ee6c995c83ccc62239997d61f0c43d.gltf'
      var paramCity = {
        position: new AMap.LngLat(this.mapCenter[0], this.mapCenter[1]), // 必须
        scale: 5, // 非必须，默认1
        height: 0, // 非必须，默认0
        scene: 0 // 非必须，默认0
      }
      var gltfObj = new AMap.GltfLoader()
      gltfObj.load(urlBuilding, function (gltfCity) {
        gltfCity.setOption(paramCity)
        gltfCity.rotateX(90)
        gltfCity.rotateZ(140)
        object3Dlayer.add(gltfCity)
      })
    },
    search() {
      if (this.around && this.range) {
        if (this.placeSearch) {
          // console.log(this.placeSearch)
          this.placeSearch.clear()
        }
        this.map.clearMap()
        this.addPlot()
        this.placeSearch = new AMap.PlaceSearch({
          type: '', // 兴趣点类别
          pageSize: this.page.size, // 单页显示结果条数
          pageIndex: this.page.current, // 页码
          // city: '010', // 兴趣点城市
          // citylimit: true, //是否强制限制在设置的城市内搜索
          map: this.map, // 展现结果的地图实例
          // panel: 'result', // 结果列表将在此容器中进行展示。
          autoFitView: true, // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
          extensions: 'all'
        })

        var cpoint = this.mapCenter //中心点坐标
        this.placeSearch.searchNearBy(this.around, cpoint, this.range, (status, result) => {
          console.log(status, result)
          if (status == 'complete') {
            this.show1 = false
            let data = result.poiList.pois
            this.page.total = result.poiList.count
            this.page.current = result.poiList.pageIndex
            this.searchData = data
            data.forEach((item, index) => {
              if (this.distenceType == 1) {
                // this.addMarker(item.location.lng, item.location.lat, index)
                this.addPhoto(item.photos[0]?.url, item.location.lng, item.location.lat)
                this.addline(item.location.lng, item.location.lat, item.distance, item.name)
              }
            })
          } else {
            this.show1 = true
            this.page.total = null
            this.page.current = 1
            this.searchData = []
          }
        })
        this.setFitView(this.markerList)
        AMap.event.addListener(this.placeSearch, 'markerClick', (res) => {
          // console.log(res, '选择改变')
          let data = res.data
          // this.selected = res.index
          this.handlerSelect(data, res.index)
        }) //返回结果
      }
    },
    // 地块中心点
    setCenter() {
      if (this.polygon.length !== 0) {
        let arr = []
        this.polygon[0].points.forEach((item) => {
          arr.push(turf.point(item))
        })
        var features = turf.featureCollection(arr)
        this.mapCenter = turf.center(features).geometry.coordinates
        this.map.setCenter(this.mapCenter)
      }
    },
    // 添加地块和项目名称
    addPlot() {
      this.addPolygon(this.polygon)
      this.addText(this.text)
    },
    addText(data) {
      var A = new AMap.Text({
        text: data,
        position: this.mapCenter,
        height: 650,
        verticalAlign: 'bottom',
        map: this.map,
        style: {
          'background-color': 'red',
          'border-color': 'white',
          'font-size': '12px'
        }
      })
    },
    addPolygon(data) {
      if (this.polygon.length !== 0 && this.AMap) {
        var polygonList = []
        data.forEach((item, index) => {
          if (item && item.points && item.points.length > 2) {
            var path = []
            item.points.forEach((item1) => {
              // 多边形轮廓线的节点坐标数组
              if (item1.length !== 0) {
                path.push(new this.AMap.LngLat(item1[0], item1[1]))
              }
            })
            polygonList.push(
              // 创建折线实例
              new this.AMap.Polygon({
                path: path,
                fillColor: item.color || 'blue', // 多边形填充颜色
                fillTransparency: 0.2,
                borderWeight: 2, // 线条宽度，默认为 1
                strokeColor: item.color || 'blue', // 线条颜色
                fillOpacity: 0.2, //填充透明度
                strokeOpacity: 0.05
              })
            )
          }
        })
        // 多边形轮廓线的节点坐标数组

        this.overlays.push(...polygonList)
        // 覆盖物初始化
        this.map.add(polygonList)
        this.setFitView()
      }
    },
    // 添加标记点
    addMarker(lng, lat, index) {
      let marker = new AMap.Marker({
        position: [lng, lat],
        map: this.map,
        zIndex: 15,
        content: `<div class="amap_lib_placeSearch_poi" >${index + 1}</div>`
      })
      this.markerList.push(marker)
      AMap.event.addListener(marker, 'click', (res) => {
        console.log(res)
        let mapMarkers = document.getElementsByClassName('amap_lib_placeSearch_poi')
        console.log(mapMarkers, '43214123')
        let arr = [...mapMarkers]
        arr.forEach((item) => {
          console.log(item.parentElement)
          item.parentElement.className = ''
        })
        let el = res.originEvent.target.parentElement
        // console.log(el);
        el.className += ' selected'
        this.selected = index
      })
    },
    // 添加图片
    addPhoto(url, lng, lat) {
      var img = new AMap.Marker({
        // icon: url,
        position: [lng, lat],
        map: this.map,
        content: `<img src="${url}" alt="" style="width:80px;height:50px">`,
        zIndex: 14
      })
      this.overlays.push(img)
    },
    // 直线距离
    addline(lng, lat, distance, name) {
      let line = new AMap.Polyline({
        path: [this.mapCenter, [lng, lat]],
        strokeColor: 'blue',
        lineCap: 'round',
        isOutline: true,
        outlineColor: 'white',
        showDir: true,
        map: this.map,
        strokeWeight: 5,
        borderWeight: 2,
        strokeOpacity: 0.5
      })
      let lng1 = (this.mapCenter[0] + lng) / 2
      let lat1 = (this.mapCenter[1] + lat) / 2
      let text = new AMap.Text({
        text: `${name + distance}米`,
        position: [lng1, lat1],
        map: this.map,
        style: {
          'background-color': '#ccccff',
          'border-color': 'white',
          'font-size': '12px'
        }
      })
      this.overlays.push(line)
      this.overlays.push(text)
    },
    // 路线
    addRoute(lng, lat) {
      if (this.distenceType == 2) {
        if (this.walking) {
          console.log(this.walking)
          this.walking.clear()
        }
        //步行导航
        this.walking = new AMap.Walking({
          map: this.map
          // panel: 'panel'
        })
        //根据起终点坐标规划步行路线
        this.walking.search(this.mapCenter, [lng, lat], (status, result) => {
          if (status === 'complete') {
            console.log('绘制步行路线完成')
          } else {
            console.log('步行路线数据查询失败' + result)
          }
        })
      }
    },
    handlerSelect(item, index) {
      this.selected = index
      // console.log(item)
      this.map.panTo([item.location.lng, item.location.lat])
      this.addRoute(item.location.lng, item.location.lat)
      let mapMarkers = document.getElementsByClassName('amap_lib_placeSearch_poi')
      let arr = [...mapMarkers]
      arr.forEach((item1) => {
        if (index == item1.innerHTML - 1) {
          item1.parentElement.className = 'selected'
        } else {
          item1.parentElement.className = ''
        }
      })
    },
    handleCurrentChange(val) {
      this.selected = null
      console.log(`当前页: ${val}`)
      this.page.current = val
      this.search()
      this.setFitView(this.markerList)
    },
    setFitView(data) {
      if (data) {
        this.map.setFitView(data)
        return
      }
      if (!this.overlays) {
        this.map.setFitView()
      } else {
        // 传入覆盖物数组，仅包括polyline和marker1的情况
        this.map.setFitView(this.overlays)
      }
    }
  },
  watch: {
    center: {
      immediate:true,
      handler(data) {
        if (data) {
          this.mapCenter = data
        }
      }
    },
    around: {
      handler(data) {
        this.selected = null
        this.search()
      }
    },
    range: {
      handler(data) {
        this.selected = null
        this.search()
      }
    },
    distenceType: {
      handler() {
        this.selected = null
        this.search()
      }
    }
  },
  beforeDestroy() {
    this.map.destroy()
    this.mapCenter = null
    this.placeSearch = null
    this.searchData = null
    this.page = null
    this.show1 = null
    this.overlays = null
    this.walking = null
    this.selected = null
  }
}

<template>
  <div class="buildingProgram">
    <el-form :model="data" :rules="rules" ref="data" class="demo-ruleForm" :disabled="$route.query.status==5">
      <div class="companyInfo">
        <div class="title">
          <h4>公司简介</h4>
        </div>
        <ul class="company">
          <li>
            <span></span>
            <el-form-item prop="createCompany" label="公司名称" label-width="120px">
              <el-select
                v-if="!companyBack"
                v-model="data.createCompany"
                filterable
                placeholder="请选择"
                size="mini"
                @change="getCompany"
              >
                <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.companyName"
                  :value="item.id"
                ></el-option>
              </el-select>
              <el-select v-else v-model="data.createCompany" filterable placeholder="请选择" size="mini" disabled>
                <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.companyName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <div class="join" v-if="isShowInvitecompany">
              <span @click="isShowInvite = true">邀请企业入驻</span>
            </div>
          </li>
          <li>
            <el-form-item prop="companyDescribe" label="公司描述" label-width="120px" style="width: 100%">
              <el-input
                type="textarea"
                resize="none"
                size="mini"
                :rows="3"
                v-model="data.companyDescribe"
                placeholder="请输入"
              />
            </el-form-item>
          </li>
        </ul>
      </div>
      <div class="addPersonnel">
        <div class="title">
          <h4>项目人员</h4>
        </div>
        <el-button class="btn1" size="mini" @click="handleAddPeople">添加人员</el-button>
        <div class="list-content">
          <div class="left-list" v-for="(item, index) in this.data.userList" :key="index">
            <div class="item">{{ item.realName }}</div>
            <i class="el-icon-close" @click="handleDelete(item)"></i>
          </div>
        </div>
        <AddPersonnel
          :dialogVisible="dialogVisible"
          @children="children"
          @chooseList="chooseList"
          :peopleList="peopleList"
          :companyInfo="companyInfo"
        ></AddPersonnel>
      </div>
      <div class="projectInfo">
        <div class="title">
          <h4>施工基本信息</h4>
        </div>
        <ul class="project">
          <li>
            <span>总工期</span>
            <el-form-item prop="totalDuration" style="width: 100%">
              <el-input type="text" size="mini" v-model.number="data.totalDuration" placeholder="请输入">
                <span slot="suffix" style="color: #aaa">{{ unit[0] }}</span>
              </el-input>
            </el-form-item>
          </li>
          <li>
            <span>总人数</span>
            <el-form-item prop="totalPeople" style="width: 100%">
              <el-input type="text" size="mini" v-model.number="data.totalPeople" placeholder="请输入">
                <span slot="suffix" style="color: #aaa">{{ unit[1] }}</span>
              </el-input>
            </el-form-item>
          </li>
          <li>
            <span>总用料成本</span>
            <el-form-item prop="totalCost" style="width: 100%">
              <el-input type="text" size="mini" v-model="data.totalCost" placeholder="请输入">
                <span slot="suffix" style="color: #aaa">{{ unit[2] }}</span>
              </el-input>
            </el-form-item>
          </li>
        </ul>
      </div>
      <div class="buildInfo">
        <div class="title">
          <h4>施工阶段信息</h4>
        </div>
        <ul class="project">
          <li>
            <div class="block">
              <span>施工阶段划分</span>
              <el-select v-model="data.constructionStage" size="mini" placeholder="请选择" disabled>
                <el-option v-for="item in 5" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </div>
            <div class="block">
              <span>总负责人</span>
              <el-form-item prop="wholeBearPeopleId" style="width: 100%">
                <el-select
                  v-model="data.wholeBearPeopleId"
                  size="mini"
                  placeholder="请选择"
                  @change="handlerWholeBearPeople"
                >
                  <el-option
                    v-for="item in peopleList"
                    :key="item.id"
                    :label="item.realName"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
          </li>
          <li class="stage" v-for="(item, index) in data.constructionPlanStageList" :key="index + 'a'">
            <span class="label">第{{ index + 1 }}阶段</span>
            <div class="container">
              <ul>
                <li class="stagename">
                  <span class="stageName">阶段名称</span>
                  <p>{{ item.stageName }}</p>
                </li>
                <li class="line1">
                  <ul>
                    <li>
                      <span>负责人</span>
                      <el-form-item :prop="`constructionPlanStageList[${index}].bearPeopleId`" style="width: 100%">
                        <el-select
                          v-model="data.constructionPlanStageList[index].bearPeopleId"
                          size="mini"
                          placeholder="请选择"
                          @change="handlerBearPeople(data.constructionPlanStageList[index].bearPeopleId, index)"
                        >
                          <el-option
                            v-for="item in peopleList"
                            :key="item.id"
                            :label="item.realName"
                            :value="item.id"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                    </li>
                    <li>
                      <span>施工人数</span>
                      <el-form-item
                        :prop="`constructionPlanStageList[${index}].constructorsPeopleNum`"
                        style="width: 100%"
                      >
                        <el-input
                          type="text"
                          size="mini"
                          v-model.number="data.constructionPlanStageList[index].constructorsPeopleNum"
                          placeholder="请输入"
                        >
                          <span slot="suffix" style="color: #aaa">{{ unit[1] }}</span>
                        </el-input>
                      </el-form-item>
                    </li>
                    <li>
                      <span>预计周期</span>
                      <el-form-item :prop="`constructionPlanStageList[${index}].expectedPeriod`" style="width: 100%">
                        <el-input
                          type="text"
                          size="mini"
                          v-model.number="data.constructionPlanStageList[index].expectedPeriod"
                          placeholder="请输入"
                        >
                          <span slot="suffix" style="color: #aaa">{{ unit[0] }}</span>
                        </el-input>
                      </el-form-item>
                    </li>
                    <li>
                      <span>施工成本</span>
                      <el-form-item :prop="`constructionPlanStageList[${index}].constructorsCost`" style="width: 100%">
                        <el-input
                          type="text"
                          size="mini"
                          v-model="data.constructionPlanStageList[index].constructorsCost"
                          placeholder="请输入"
                        >
                          <span slot="suffix" style="color: #aaa">{{ unit[2] }}</span>
                        </el-input>
                      </el-form-item>
                    </li>
                  </ul>
                </li>
                <li class="line2">
                  <ul>
                    <li
                      v-for="(item1, index1) in data.constructionPlanStageList[index].constructorsContent"
                      :key="index1 + 'b'"
                    >
                      <div class="block">
                        <span>施工内容</span>
                        <el-form-item
                          :prop="`constructionPlanStageList[${index}].constructorsContent[${index1}].content`"
                          style="width: 100%"
                        >
                          <el-input
                            type="text"
                            size="mini"
                            v-model="data.constructionPlanStageList[index].constructorsContent[index1].content"
                            placeholder="请输入"
                          />
                        </el-form-item>
                      </div>
                      <div class="block">
                        <span>施工天数</span>
                        <el-form-item
                          :prop="`constructionPlanStageList[${index}].constructorsContent[${index1}].days`"
                          style="width: 100%"
                        >
                          <el-input
                            type="text"
                            size="mini"
                            v-model.number="data.constructionPlanStageList[index].constructorsContent[index1].days"
                            placeholder="请输入"
                          />
                        </el-form-item>
                      </div>
                      <div class="block1">
                        <el-button
                          type="primary"
                          size="mini"
                          icon="el-icon-plus"
                          circle
                          @click="addWork(index, index1)"
                        ></el-button>
                        <el-button
                          type="danger"
                          size="mini"
                          icon="el-icon-minus"
                          circle
                          @click="reduceWork(index, index1)"
                        ></el-button>
                      </div>
                    </li>
                  </ul>
                </li>
                <li>
                  <span>施工难点</span>
                  <el-form-item
                    :prop="`constructionPlanStageList[${index}].constructorsDifficulty`"
                    style="width: 100%"
                  >
                    <el-input
                      type="textarea"
                      resize="none"
                      size="mini"
                      :rows="3"
                      v-model="data.constructionPlanStageList[index].constructorsDifficulty"
                      placeholder="请输入"
                    />
                  </el-form-item>
                </li>
                <li>
                  <span>难点解决方案</span>
                  <el-form-item :prop="`constructionPlanStageList[${index}].difficultySolution`" style="width: 100%">
                    <el-input
                      type="textarea"
                      resize="none"
                      size="mini"
                      :rows="3"
                      v-model="data.constructionPlanStageList[index].difficultySolution"
                      placeholder="请输入"
                    />
                  </el-form-item>
                </li>
                <li>
                  <span>成本变动范围</span>
                  <el-form-item :prop="`constructionPlanStageList[${index}].costChangeRange`" style="width: 100%">
                    <el-input
                      type="text"
                      resize="none"
                      size="mini"
                      :rows="3"
                      v-model="data.constructionPlanStageList[index].costChangeRange"
                      placeholder="请输入"
                      ><span slot="suffix" style="color: #aaa">{{ unit[2] }}</span></el-input
                    >
                  </el-form-item>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      <div class="purchasePlan">
        <div class="title">
          <h4>采购计划</h4>
        </div>
        <ul class="purchase">
          <li>
            <span class="label">阶段一&nbsp;&nbsp;{{ data.stageOne }}</span>
            <div class="container">
              <ul>
                <li class="line2">
                  <ul>
                    <li v-for="(item, index) in data.stageOneContent" :key="index + 'b'">
                      <div class="block">
                        <span>用料名称</span>
                        <el-form-item :prop="`stageOneContent[${index}].name`" style="width: 100%">
                          <el-input
                            type="text"
                            size="mini"
                            v-model="data.stageOneContent[index].name"
                            placeholder="请输入"
                          />
                        </el-form-item>
                      </div>
                      <div class="block">
                        <span>数量</span>
                        <el-form-item :prop="`stageOneContent[${index}].num`" style="width: 100%">
                          <el-input
                            type="text"
                            size="mini"
                            v-model.number="data.stageOneContent[index].num"
                            placeholder="请输入"
                          />
                        </el-form-item>
                      </div>
                      <div class="block">
                        <span>成本</span>
                        <el-form-item :prop="`stageOneContent[${index}].cost`" style="width: 100%">
                          <el-input
                            type="text"
                            size="mini"
                            v-model="data.stageOneContent[index].cost"
                            placeholder="请输入"
                          >
                            <span slot="suffix" style="color: #aaa">{{ unit[2] }}</span>
                          </el-input>
                        </el-form-item>
                      </div>
                      <div class="block1">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          circle
                          size="mini"
                          @click="addMaterial(index, 1)"
                        ></el-button>
                        <el-button
                          type="danger"
                          icon="el-icon-minus"
                          circle
                          size="mini"
                          @click="reduceMaterial(index, 1)"
                        ></el-button>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <span class="label">阶段二&nbsp;&nbsp;{{ data.stageTwo }}</span>
            <div class="container">
              <ul>
                <li class="line2">
                  <ul>
                    <li v-for="(item, index) in data.stageTwoContent" :key="index + 'b'">
                      <div class="block">
                        <span>用料名称</span>
                        <el-form-item :prop="`stageTwoContent[${index}].name`" style="width: 100%">
                          <el-input
                            type="text"
                            size="mini"
                            v-model="data.stageTwoContent[index].name"
                            placeholder="请输入"
                          />
                        </el-form-item>
                      </div>
                      <div class="block">
                        <span>数量</span>
                        <el-form-item :prop="`stageTwoContent[${index}].num`" style="width: 100%">
                          <el-input
                            type="text"
                            size="mini"
                            v-model.number="data.stageTwoContent[index].num"
                            placeholder="请输入"
                          />
                        </el-form-item>
                      </div>
                      <div class="block">
                        <span>成本</span>
                        <el-form-item :prop="`stageTwoContent[${index}].cost`" style="width: 100%">
                          <el-input
                            type="text"
                            size="mini"
                            v-model="data.stageTwoContent[index].cost"
                            placeholder="请输入"
                          >
                            <span slot="suffix" style="color: #aaa">{{ unit[2] }}</span>
                          </el-input>
                        </el-form-item>
                      </div>
                      <div class="block1">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          circle
                          size="mini"
                          @click="addMaterial(index, 2)"
                        ></el-button>
                        <el-button
                          type="danger"
                          icon="el-icon-minus"
                          circle
                          size="mini"
                          @click="reduceMaterial(index, 2)"
                        ></el-button>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <span class="label">阶段三&nbsp;&nbsp;{{ data.stageThree }}</span>
            <div class="container">
              <ul>
                <li class="line2">
                  <ul>
                    <li v-for="(item, index) in data.stageThreeContent" :key="index + 'b'">
                      <div class="block">
                        <span>用料名称</span>
                        <el-form-item :prop="`stageThreeContent[${index}].name`" style="width: 100%">
                          <el-input
                            type="text"
                            size="mini"
                            v-model="data.stageThreeContent[index].name"
                            placeholder="请输入"
                          />
                        </el-form-item>
                      </div>
                      <div class="block">
                        <span>数量</span>
                        <el-form-item :prop="`stageThreeContent[${index}].num`" style="width: 100%">
                          <el-input
                            type="text"
                            size="mini"
                            v-model.number="data.stageThreeContent[index].num"
                            placeholder="请输入"
                          />
                        </el-form-item>
                      </div>
                      <div class="block">
                        <span>成本</span>
                        <el-form-item :prop="`stageThreeContent[${index}].cost`" style="width: 100%">
                          <el-input
                            type="text"
                            size="mini"
                            v-model="data.stageThreeContent[index].cost"
                            placeholder="请输入"
                          >
                            <span slot="suffix" style="color: #aaa">{{ unit[2] }}</span>
                          </el-input>
                        </el-form-item>
                      </div>
                      <div class="block1">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          circle
                          size="mini"
                          @click="addMaterial(index, 3)"
                        ></el-button>
                        <el-button
                          type="danger"
                          icon="el-icon-minus"
                          circle
                          size="mini"
                          @click="reduceMaterial(index, 3)"
                        ></el-button>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <span class="label">阶段四&nbsp;&nbsp;{{ data.stageFour }}</span>
            <div class="container">
              <ul>
                <li class="line2">
                  <ul>
                    <li v-for="(item, index) in data.stageFourContent" :key="index + 'b'">
                      <div class="block">
                        <span>用料名称</span>
                        <el-form-item :prop="`stageFourContent[${index}].name`" style="width: 100%">
                          <el-input
                            type="text"
                            size="mini"
                            v-model="data.stageFourContent[index].name"
                            placeholder="请输入"
                          />
                        </el-form-item>
                      </div>
                      <div class="block">
                        <span>数量</span>
                        <el-form-item :prop="`stageFourContent[${index}].num`" style="width: 100%">
                          <el-input
                            type="text"
                            size="mini"
                            v-model.number="data.stageFourContent[index].num"
                            placeholder="请输入"
                          />
                        </el-form-item>
                      </div>
                      <div class="block">
                        <span>成本</span>
                        <el-form-item :prop="`stageFourContent[${index}].cost`" style="width: 100%">
                          <el-input
                            type="text"
                            size="mini"
                            v-model="data.stageFourContent[index].cost"
                            placeholder="请输入"
                          >
                            <span slot="suffix" style="color: #aaa">{{ unit[2] }}</span>
                          </el-input>
                        </el-form-item>
                      </div>
                      <div class="block1">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          circle
                          size="mini"
                          @click="addMaterial(index, 4)"
                        ></el-button>
                        <el-button
                          type="danger"
                          icon="el-icon-minus"
                          circle
                          size="mini"
                          @click="reduceMaterial(index, 4)"
                        ></el-button>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <span class="label">阶段五&nbsp;&nbsp;{{ data.stageFive }}</span>
            <div class="container">
              <ul>
                <li class="line2">
                  <ul>
                    <li v-for="(item, index) in data.stageFiveContent" :key="index + 'b'">
                      <div class="block">
                        <span>用料名称</span>
                        <el-form-item :prop="`stageFiveContent[${index}].name`" style="width: 100%">
                          <el-input
                            type="text"
                            size="mini"
                            v-model="data.stageFiveContent[index].name"
                            placeholder="请输入"
                          />
                        </el-form-item>
                      </div>
                      <div class="block">
                        <span>数量</span>
                        <el-form-item :prop="`stageFiveContent[${index}].num`" style="width: 100%">
                          <el-input
                            type="text"
                            size="mini"
                            v-model.number="data.stageFiveContent[index].num"
                            placeholder="请输入"
                          />
                        </el-form-item>
                      </div>
                      <div class="block">
                        <span>成本</span>
                        <el-form-item :prop="`stageFiveContent[${index}].cost`" style="width: 100%">
                          <el-input
                            type="text"
                            size="mini"
                            v-model="data.stageFiveContent[index].cost"
                            placeholder="请输入"
                          >
                            <span slot="suffix" style="color: #aaa">{{ unit[2] }}</span>
                          </el-input>
                        </el-form-item>
                      </div>
                      <div class="block1">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          circle
                          size="mini"
                          @click="addMaterial(index, 5)"
                        ></el-button>
                        <el-button
                          type="danger"
                          icon="el-icon-minus"
                          circle
                          size="mini"
                          @click="reduceMaterial(index, 5)"
                        ></el-button>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      <div class="bottom" v-if="isShowBottombutton">
        <el-button @click="resetForm('data')">取消</el-button>
        <el-button type="primary" @click="submitForm('data')">保存并提交</el-button>
      </div>
    </el-form>
    <!-- 邀请企业入驻弹层 -->
    <invite-company :isShowInvite="isShowInvite" @cancelInvite="cancelInvite"></invite-company>
  </div>
</template>

<script>
import { companyNameList } from '@/api/system/dictionaries.js'
import AddPersonnel from '@/components/IntegrationSolutions/addPersonnel.vue'
import InviteCompany from '@/components/IntegrationSolutions/inviteCompany.vue'
export default {
  name: 'buildingProgram',
  components: {
    AddPersonnel,
    InviteCompany
  },
  data() {
    return {
      dialogVisible: false,
      isShowInvite: false,
      data: {
        id: '',
        createCompany: '',
        companyName: '',
        companyDescribe: '',
        totalDuration: null,
        totalPeople: null,
        totalCost: null,
        constructionStage: 5,
        wholeBearPeopleId: '', //总负责人
        wholeBearPeopleName: '',
        constructionPlanStageList: [
          {
            stageCode: 1,
            stageName: '地基处理',
            bearPeopleId: '',
            bearPeopleName: '',
            constructorsPeopleNum: null,
            expectedPeriod: null,
            constructorsCost: null,
            constructorsContent: [
              {
                content: '',
                days: null
              }
            ],
            constructorsDifficulty: '',
            difficultySolution: '',
            costChangeRange: ''
          },
          {
            stageCode: 2,
            stageName: '地下施工',
            bearPeopleId: '',
            bearPeopleName: '',
            constructorsPeopleNum: null,
            expectedPeriod: null,
            constructorsCost: null,
            constructorsContent: [
              {
                content: '',
                days: null
              }
            ],
            constructorsDifficulty: '',
            difficultySolution: '',
            costChangeRange: ''
          },
          {
            stageCode: 3,
            stageName: '主体施工',
            bearPeopleId: '',
            bearPeopleName: '',
            constructorsPeopleNum: null,
            expectedPeriod: null,
            constructorsCost: null,
            constructorsContent: [
              {
                content: '',
                days: null
              }
            ],
            constructorsDifficulty: '',
            difficultySolution: '',
            costChangeRange: ''
          },
          {
            stageCode: 4,
            stageName: '水电暖通施工',
            bearPeopleId: '',
            bearPeopleName: '',
            constructorsPeopleNum: null,
            expectedPeriod: null,
            constructorsCost: null,
            constructorsContent: [
              {
                content: '',
                days: null
              }
            ],
            constructorsDifficulty: '',
            difficultySolution: '',
            costChangeRange: ''
          },
          {
            stageCode: 5,
            stageName: '整体完工',
            bearPeopleId: '',
            bearPeopleName: '',
            constructorsPeopleNum: null,
            expectedPeriod: null,
            constructorsCost: null,
            constructorsContent: [
              {
                content: '',
                days: null
              }
            ],
            constructorsDifficulty: '',
            difficultySolution: '',
            costChangeRange: ''
          }
        ],
        stageOne: '地基处理',
        stageOneContent: [
          {
            name: '',
            num: null,
            cost: null
          }
        ],
        stageTwo: '地下施工',
        stageTwoContent: [
          {
            name: '',
            num: null,
            cost: null
          }
        ],
        stageThree: '主体施工',
        stageThreeContent: [
          {
            name: '',
            num: null,
            cost: null
          }
        ],
        stageFour: '水电暖通施工',
        stageFourContent: [
          {
            name: '',
            num: null,
            cost: null
          }
        ],
        stageFive: '整体完工',
        stageFiveContent: [
          {
            name: '',
            num: null,
            cost: null
          }
        ],
        userList: []
      },
      unit: ['天', '人', '万'],
      rules: {
        companyName: [{ required: true, message: '请输入公司名称', trigger: 'blur' }],
        companyDescribe: [{ required: true, message: '请输入公司描述', trigger: 'blur' }],
        totalDuration: [
          { required: true, message: '请输入总工期', trigger: 'blur' },
          { type: 'number', message: '总工期必须为数字值', trigger: 'blur' }
        ],
        totalPeople: [
          { required: true, message: '请输入总人数', trigger: 'blur' },
          { type: 'number', message: '总人数必须为数字值', trigger: 'blur' }
        ],
        totalCost: [
          { required: true, message: '请输入总用料成本', trigger: 'blur' },
          { pattern: /^\d+(\.\d+)?$/, message: '总用料成本必须为数字值', trigger: 'blur' },
          { min: 1, max: 7, message: '长度在 1 到 7 个字符', trigger: 'blur' }
        ],
        wholeBearPeopleId: [{ required: true, message: '请输入总负责人', trigger: 'change' }],
        constructionPlanStageList: [
          {
            bearPeopleId: [{ required: true, message: '请输入负责人', trigger: 'change' }],
            constructorsPeopleNum: [
              { required: true, message: '请输入施工人数', trigger: 'blur' },
              { type: 'number', message: '施工人数必须为数字值', trigger: 'blur' }
            ],
            expectedPeriod: [
              { required: true, message: '请输入预计周期', trigger: 'blur' },
              { type: 'number', message: '预计周期必须为数字值', trigger: 'blur' }
            ],
            constructorsCost: [
              { required: true, message: '请输入施工成本', trigger: 'blur' },
              { pattern: /^\d+(\.\d+)?$/, message: '施工成本必须为数字值', trigger: 'blur' },
              { min: 1, max: 7, message: '长度在 1 到 7 个字符', trigger: 'blur' }
            ],
            constructorsContent: [
              {
                content: [{ required: true, message: '请输入施工内容', trigger: 'blur' }],
                days: [
                  { required: true, message: '请输入施工天数', trigger: 'blur' },
                  { type: 'number', message: '施工天数必须为数字值', trigger: 'blur' }
                ]
              }
            ],
            constructorsDifficulty: [{ required: true, message: '请输入施工难点', trigger: 'blur' }],
            difficultySolution: [{ required: true, message: '请输入难点解决方案', trigger: 'blur' }],
            costChangeRange: [
              { required: true, message: '请输入成本变动范围', trigger: 'blur' },
              { pattern: /^\d+(\.\d+)?$/, message: '成本变动范围必须为数字值', trigger: 'blur' },
              { min: 1, max: 7, message: '长度在 1 到 7 个字符', trigger: 'blur' }
            ]
          }
        ],
        stageOneContent: [
          {
            name: [{ required: true, message: '请输入用料名称', trigger: 'blur' }],
            num: [
              { required: true, message: '请输入用料数量', trigger: 'blur' },
              { type: 'number', message: '用料数量必须为数字值', trigger: 'blur' }
            ],
            cost: [
              { required: true, message: '请输入用料成本', trigger: 'blur' },
              { pattern: /^\d+(\.\d+)?$/, message: '用料成本必须为数字值', trigger: 'blur' },
              { min: 1, max: 7, message: '长度在 1 到 7 个字符', trigger: 'blur' }
            ]
          }
        ],
        stageTwoContent: [
          {
            name: [{ required: true, message: '请输入用料名称', trigger: 'blur' }],
            num: [
              { required: true, message: '请输入用料数量', trigger: 'blur' },
              { type: 'number', message: '用料数量必须为数字值', trigger: 'blur' }
            ],
            cost: [
              { required: true, message: '请输入用料成本', trigger: 'blur' },
              { pattern: /^\d+(\.\d+)?$/, message: '用料成本必须为数字值', trigger: 'blur' },
              { min: 1, max: 7, message: '长度在 1 到 7 个字符', trigger: 'blur' }
            ]
          }
        ],
        stageThreeContent: [
          {
            name: [{ required: true, message: '请输入用料名称', trigger: 'blur' }],
            num: [
              { required: true, message: '请输入用料数量', trigger: 'blur' },
              { type: 'number', message: '用料数量必须为数字值', trigger: 'blur' }
            ],
            cost: [
              { required: true, message: '请输入用料成本', trigger: 'blur' },
              { pattern: /^\d+(\.\d+)?$/, message: '用料成本必须为数字值', trigger: 'blur' },
              { min: 1, max: 7, message: '长度在 1 到 7 个字符', trigger: 'blur' }
            ]
          }
        ],
        stageFourContent: [
          {
            name: [{ required: true, message: '请输入用料名称', trigger: 'blur' }],
            num: [
              { required: true, message: '请输入用料数量', trigger: 'blur' },
              { type: 'number', message: '用料数量必须为数字值', trigger: 'blur' }
            ],
            cost: [
              { required: true, message: '请输入用料成本', trigger: 'blur' },
              { pattern: /^\d+(\.\d+)?$/, message: '用料成本必须为数字值', trigger: 'blur' },
              { min: 1, max: 7, message: '长度在 1 到 7 个字符', trigger: 'blur' }
            ]
          }
        ],
        stageFiveContent: [
          {
            name: [{ required: true, message: '请输入用料名称', trigger: 'blur' }],
            num: [
              { required: true, message: '请输入用料数量', trigger: 'blur' },
              { type: 'number', message: '用料数量必须为数字值', trigger: 'blur' }
            ],
            cost: [
              { required: true, message: '请输入用料成本', trigger: 'blur' },
              { min: 1, max: 7, message: '长度在 1 到 7 个字符', trigger: 'blur' },
              { pattern: /^\d+(\.\d+)?$/, message: '用料成本必须为数字值', trigger: 'blur' }
            ]
          }
        ]
      },
      options: [],
      peopleList: [],
      companyInfo: {}
    }
  },
  props: {
    buildPlan: {
      type: Object,
      default: () => {
        return null
      }
    },
    companyBack: {
      type: Boolean,
      default: false
    },
    isShowBottombutton: {
      type: String,
      default: false
    },
    isShowInvitecompany: {
      type: Boolean,
      default: false
    },
    isShowBottombutton: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.handlerRules()
    this.companyNameList()
    this.getCompanyBack()
  },
  methods: {
    addWork(index, index1) {
      // console.log(index, index1, 'addWork')
      this.data.constructionPlanStageList[index].constructorsContent.splice(index1 + 1, 0, {
        content: '',
        days: ''
      })
      this.rules.constructionPlanStageList[index].constructorsContent.splice(index1 + 1, 0, {
        content: [{ required: true, message: '请输入施工内容', trigger: 'blur' }],
        days: [
          { required: true, message: '请输入施工天数', trigger: 'blur' },
          { type: 'number', message: '施工天数必须为数字值', trigger: 'blur' }
        ]
      })
    },
    reduceWork(index, index1) {
      // console.log(index, index1, 'reduceWork')
      if (this.data.constructionPlanStageList[index].constructorsContent.length > 1) {
        this.data.constructionPlanStageList[index].constructorsContent.splice(index1, 1)
        this.rules.constructionPlanStageList[index].constructorsContent.splice(index1, 1)
      }
    },
    addMaterial(index, index1) {
      // console.log(index, 'addMaterial')
      if (index1 == 1) {
        this.data.stageOneContent.splice(index + 1, 0, {
          name: '',
          num: '',
          cost: ''
        })
        this.rules.stageOneContent.splice(index + 1, 0, {
          name: [{ required: true, message: '请输入用料名称', trigger: 'blur' }],
          num: [
            { required: true, message: '请输入数量', trigger: 'blur' },
            { type: 'number', message: '数量必须为数字值', trigger: 'blur' }
          ],
          cost: [
            { required: true, message: '请输入用料成本', trigger: 'blur' },
            { min: 1, max: 7, message: '长度在 1 到 7 个字符', trigger: 'blur' },
            { pattern: /^\d+(\.\d+)?$/, message: '用料成本必须为数字值', trigger: 'blur' }
          ]
        })
      } else if (index1 == 2) {
        this.data.stageTwoContent.splice(index + 1, 0, {
          name: '',
          num: '',
          cost: ''
        })
        this.rules.stageTwoContent.splice(index + 1, 0, {
          name: [{ required: true, message: '请输入用料名称', trigger: 'blur' }],
          num: [
            { required: true, message: '请输入数量', trigger: 'blur' },
            { type: 'number', message: '数量必须为数字值', trigger: 'blur' }
          ],
          cost: [
            { required: true, message: '请输入用料成本', trigger: 'blur' },
            { min: 1, max: 7, message: '长度在 1 到 7 个字符', trigger: 'blur' },
            { pattern: /^\d+(\.\d+)?$/, message: '用料成本必须为数字值', trigger: 'blur' }
          ]
        })
      } else if (index1 == 3) {
        this.data.stageThreeContent.splice(index + 1, 0, {
          name: '',
          num: '',
          cost: ''
        })
        this.rules.stageThreeContent.splice(index + 1, 0, {
          name: [{ required: true, message: '请输入用料名称', trigger: 'blur' }],
          num: [
            { required: true, message: '请输入数量', trigger: 'blur' },
            { type: 'number', message: '数量必须为数字值', trigger: 'blur' }
          ],
          cost: [
            { required: true, message: '请输入用料成本', trigger: 'blur' },
            { min: 1, max: 7, message: '长度在 1 到 7 个字符', trigger: 'blur' },
            { pattern: /^\d+(\.\d+)?$/, message: '用料成本必须为数字值', trigger: 'blur' }
          ]
        })
      } else if (index1 == 4) {
        this.data.stageFourContent.splice(index + 1, 0, {
          name: '',
          num: '',
          cost: ''
        })
        this.rules.stageFourContent.splice(index + 1, 0, {
          name: [{ required: true, message: '请输入用料名称', trigger: 'blur' }],
          num: [
            { required: true, message: '请输入数量', trigger: 'blur' },
            { type: 'number', message: '数量必须为数字值', trigger: 'blur' }
          ],
          cost: [
            { required: true, message: '请输入用料成本', trigger: 'blur' },
            { min: 1, max: 7, message: '长度在 1 到 7 个字符', trigger: 'blur' },
            { pattern: /^\d+(\.\d+)?$/, message: '用料成本必须为数字值', trigger: 'blur' }
          ]
        })
      } else if (index1 == 5) {
        this.data.stageFiveContent.splice(index + 1, 0, {
          name: '',
          num: '',
          cost: ''
        })
        this.rules.stageFiveContent.splice(index + 1, 0, {
          name: [{ required: true, message: '请输入用料名称', trigger: 'blur' }],
          num: [
            { required: true, message: '请输入数量', trigger: 'blur' },
            { type: 'number', message: '数量必须为数字值', trigger: 'blur' }
          ],
          cost: [
            { required: true, message: '请输入用料成本', trigger: 'blur' },
            { min: 1, max: 7, message: '长度在 1 到 7 个字符', trigger: 'blur' },
            { pattern: /^\d+(\.\d+)?$/, message: '用料成本必须为数字值', trigger: 'blur' }
          ]
        })
      }
    },
    reduceMaterial(index, index1) {
      // console.log(index, index1, 'reduceMaterial')
      if (index1 == 1) {
        if (this.data.stageOneContent.length > 1) {
          this.data.stageOneContent.splice(index, 1)
          this.rules.stageOneContent.splice(index, 1)
        }
      } else if (index1 == 2) {
        if (this.data.stageTwoContent.length > 1) {
          this.data.stageTwoContent.splice(index1, 1)
          this.rules.stageTwoContent.splice(index1, 1)
        }
      } else if (index1 == 3) {
        if (this.data.stageThreeContent.length > 1) {
          this.data.stageThreeContent.splice(index1, 1)
          this.rules.stageThreeContent.splice(index1, 1)
        }
      } else if (index1 == 4) {
        if (this.data.stageFourContent.length > 1) {
          this.data.stageFourContent.splice(index1, 1)
          this.rules.stageFourContent.splice(index1, 1)
        }
      } else if (index1 == 5) {
        if (this.data.stageFiveContent.length > 1) {
          this.data.stageFiveContent.splice(index1, 1)
          this.rules.stageFiveContent.splice(index1, 1)
        }
      }
    },
    handlerRules() {
      // console.log('afsdfafasdf')
      for (let i = 1; i < this.data.constructionPlanStageList.length; i++) {
        this.rules.constructionPlanStageList.push(this.rules.constructionPlanStageList[0])
      }
      for (let i = 0; i < this.data.constructionPlanStageList.length; i++) {
        if (this.data.constructionPlanStageList[i].constructorsContent) {
          let len = this.data.constructionPlanStageList[i].constructorsContent.length
          for (let j = 1; j < len; j++) {
            this.rules.constructionPlanStageList[i].constructorsContent.push(
              this.rules.constructionPlanStageList[i].constructorsContent[0]
            )
          }
        }
      }
    },
    companyNameList() {
      companyNameList({ type: 'engineering' }).then((res) => {
        this.options = res.data.data
      })
    },
    submitForm(data) {
      this.$refs[data].validate((valid) => {
        if (valid) {
          // alert('submit!')
          this.$emit('getBuildPlan', [this.data1, true])
        } else {
          // console.log('error submit!!')
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.$emit('handlerCancle')
    },
    getCompany(data) {
      // console.log(data, '1111')
      this.options.forEach((item, index) => {
        if (item.id === data) {
          this.data.companyName = item.companyName
          this.companyInfo = item
        }
      })
    },
    handleAddPeople() {
      this.dialogVisible = true
    },
    children(val) {
      this.dialogVisible = val
    },
    chooseList(val) {
      this.peopleList = val
      this.data.userList = val
      // console.log(val)
    },
    getCompanyBack() {
      if (this.companyBack) {
        // console.log(this.$store.getters.companyDetails, 'getCompanyBack')
        let companyBack = this.$store.getters.companyDetails
        this.data.companyName = companyBack.companyName
        this.data.createCompany = companyBack.id
        this.companyInfo = companyBack
      }
    },
    handlerWholeBearPeople(data) {
      this.peopleList.forEach((item) => {
        if (item.id == data) {
          this.data.wholeBearPeopleName = item.realName
        }
      })
    },
    handlerBearPeople(data, index) {
      // console.log(data, index)
      this.peopleList.forEach((item) => {
        if (item.id == data) {
          this.data.constructionPlanStageList[index].bearPeopleName = item.realName
        }
      })
    },
    cancelInvite(val) {
      this.isShowInvite = val
    }
  },
  computed: {
    data1() {
      let data = { ...this.data }
      let res = {
        id: data.id,
        itemCode: data.itemCode,
        createCompany: data.createCompany,
        companyName: data.companyName,
        companyDescribe: data.companyDescribe,
        wholeBearPeopleId: data.wholeBearPeopleId, //总负责人
        wholeBearPeopleName: data.wholeBearPeopleName,
        totalCost: data.totalCost * 10000,
        totalDuration: data.totalDuration,
        totalPeople: data.totalPeople,
        constructionPlanStageList: [],
        constructionStage: data.constructionStage,
        stageFive: data.stageFive,
        stageFiveContent: [],
        stageFour: data.stageFour,
        stageFourContent: [],
        stageOne: data.stageOne,
        stageOneContent: [],
        stageThree: data.stageThree,
        stageThreeContent: [],
        stageTwo: data.stageTwo,
        stageTwoContent: [],
        userList: data.userList
      }
      if (data.constructionPlanStageList) {
        data.constructionPlanStageList.forEach((item, index) => {
          res.constructionPlanStageList.push({
            stageCode: item.stageCode,
            bearPeopleId: item.bearPeopleId,
            bearPeopleName: item.bearPeopleName,
            constructorsContent: item.constructorsContent,
            constructorsCost: item.constructorsCost * 10000,
            constructorsDifficulty: item.constructorsDifficulty,
            constructorsPeopleNum: item.constructorsPeopleNum,
            costChangeRange: item.costChangeRange * 10000,
            difficultySolution: item.difficultySolution,
            expectedPeriod: item.expectedPeriod,
            stageName: item.stageName
          })
        })
      }
      data.stageOneContent.forEach((item, index) => {
        res.stageOneContent.push({
          name: item.name,
          num: item.num,
          cost: item.cost * 10000
        })
      })
      data.stageTwoContent.forEach((item, index) => {
        res.stageTwoContent.push({
          name: item.name,
          num: item.num,
          cost: item.cost * 10000
        })
      })
      data.stageThreeContent.forEach((item, index) => {
        res.stageThreeContent.push({
          name: item.name,
          num: item.num,
          cost: item.cost * 10000
        })
      })
      data.stageFourContent.forEach((item, index) => {
        res.stageFourContent.push({
          name: item.name,
          num: item.num,
          cost: item.cost * 10000
        })
      })
      data.stageFiveContent.forEach((item, index) => {
        res.stageFiveContent.push({
          name: item.name,
          num: item.num,
          cost: item.cost * 10000
        })
      })
      return res
    },
    buildPlan1() {
      let res = {}
      if (this.buildPlan) {
        // console.log(this.buildPlan);
        let data = { ...this.buildPlan }
        res = {
          id: data.id,
          itemCode: data.itemCode,
          createCompany: data.createCompany,
          companyName: data.companyName,
          companyDescribe: data.companyDescribe,
          wholeBearPeopleId: data.wholeBearPeopleId, //总负责人
          wholeBearPeopleName: data.wholeBearPeopleName,
          totalCost: String(data.totalCost / 10000),
          totalDuration: data.totalDuration,
          totalPeople: data.totalPeople,
          constructionPlanStageList: [],
          constructionStage: data.constructionStage,
          stageFive: data.stageFive,
          stageFiveContent: [],
          stageFour: data.stageFour,
          stageFourContent: [],
          stageOne: data.stageOne,
          stageOneContent: [],
          stageThree: data.stageThree,
          stageThreeContent: [],
          stageTwo: data.stageTwo,
          stageTwoContent: [],
          userList: data.userList
        }
        if (data.constructionPlanStageList) {
          data.constructionPlanStageList.forEach((item, index) => {
            res.constructionPlanStageList.push({
              stageCode: item.stageCode,
              bearPeopleId: item.bearPeopleId,
              bearPeopleName: item.bearPeopleName,
              constructorsContent: item.constructorsContent,
              constructorsCost: String(item.constructorsCost / 10000),
              constructorsDifficulty: item.constructorsDifficulty,
              constructorsPeopleNum: item.constructorsPeopleNum,
              costChangeRange: String(item.costChangeRange / 10000),
              difficultySolution: item.difficultySolution,
              expectedPeriod: item.expectedPeriod,
              stageName: item.stageName
            })
          })
          data.stageOneContent.forEach((item, index) => {
            res.stageOneContent.push({
              name: item.name,
              num: item.num,
              cost: String(item.cost / 10000)
            })
          })
          data.stageTwoContent.forEach((item, index) => {
            res.stageTwoContent.push({
              name: item.name,
              num: item.num,
              cost: String(item.cost / 10000)
            })
          })
          data.stageThreeContent.forEach((item, index) => {
            res.stageThreeContent.push({
              name: item.name,
              num: item.num,
              cost: String(item.cost / 10000)
            })
          })
          data.stageFourContent.forEach((item, index) => {
            res.stageFourContent.push({
              name: item.name,
              num: item.num,
              cost: String(item.cost / 10000)
            })
          })
          data.stageFiveContent.forEach((item, index) => {
            res.stageFiveContent.push({
              name: item.name,
              num: item.num,
              cost: String(item.cost / 10000)
            })
          })
        }
        this.peopleList = res.userList
      }
      return res
    }
  },
  watch: {
    data: {
      deep: true,
      handler() {
        this.$emit('getBuildPlan', [this.data1, false])
      }
    },
    buildPlan: {
      deep: true,
      handler(data) {
        if (data) {
          this.data = this.buildPlan1
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/buildingProgram/buildingProgram.scss';
</style>

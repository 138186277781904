<template>
  <div class="mapContainer">
    <div id="container"></div>
    <div class="control">
      <div class="topControl">
        <div class="searchControl" v-if="searchControl">
          <el-input
            id="tipinput"
            size="small"
            v-model="input"
            suffix-icon="el-icon-search"
            placeholder="请输入城市、公安站，地铁站"
          ></el-input>
          <el-button type="primary" size="small" @click="input = ''">清空</el-button>
        </div>
        <div class="layersControl" v-if="layersControl">
          <ul>
            <li>
              <el-button type="primary" size="small" @click="handlerSatellite()">卫星</el-button>
            </li>
            <li>
              <el-button type="primary" size="small" @click="handlerRoal()">路书</el-button>
            </li>
          </ul>
        </div>
        <div class="surroundingControl" v-if="surroundingControl">
          <div class="top-nav-tabs">
            <ul>
              <li
                :class="{ type_1: type_1 == index }"
                v-for="(item, index) in surroundingType"
                :key="index"
                @click="handlerSurroundingType(index)"
              >
                <h4>{{ item.dictValue }}</h4>
              </li>
              <div class="showSurrounding" @click="handlerOpen1()">
                <i :class="showSurrounding == false ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"></i>
              </div>
            </ul>
          </div>
          <div class="main" v-if="showSurrounding">
            <ul>
              <li v-if="type_1 == 0">
                <el-tabs v-model="activeName" @tab-click="handleClick">
                  <el-tab-pane label="地铁" name="first">
                    <div id="plane0"></div>
                  </el-tab-pane>
                  <el-tab-pane label="公交" name="second"><div id="plane1"></div></el-tab-pane>
                  <el-tab-pane label="停车场" name="third"><div id="plane2"></div></el-tab-pane>
                  <el-tab-pane label="加油站" name="fourth"><div id="plane3"></div></el-tab-pane>
                </el-tabs>
              </li>
              <li>
                <div id="plane4" v-if="type_1 == 1"></div>
              </li>
              <li>
                <div id="plane5" v-if="type_1 == 2"></div>
              </li>
              <li>
                <div id="plane6" v-if="type_1 == 3"></div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="controlPanel">
        <ul v-if="editModel">
          <li>
            <div class="openEdit" v-if="edit">
              <el-button type="primary" size="mini" @click="handlerOpen()">编辑</el-button>
            </div>
            <div class="edit" v-else>
              <div class="title">
                <h3>覆盖物</h3>
              </div>
              <div class="main">
                <el-radio-group v-model="editOverlayType" @change="changeOverflyType()">
                  <el-radio label="marker">画点</el-radio>
                  <el-radio label="polyline">画折线</el-radio>
                  <el-radio label="polygon">画多边形</el-radio>
                  <el-radio label="rectangle">画矩形</el-radio>
                  <el-radio label="circle">画圆</el-radio>
                </el-radio-group>
                <div class="bottom">
                  <el-button type="primary" size="mini" @click="handlerClear()">清除</el-button>
                  <el-button type="primary" size="mini" @click="handlerSave()">保存</el-button>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import map_0 from "./mixins/map_0.js";
window._AMapSecurityConfig = {
  securityJsCode: '5afce96d63848e70d78f33b3cdd46674'
}
export default {
  name: 'MapContainer',
  mixins: [map_0],
}
</script>
<style lang="scss" scoped>
.mapContainer {
  position: relative;
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 100%;
}

#container {
  position: absolute;
  padding: 0px;
  margin: 0px;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.control {
  .topControl {
    height: 70%;
    position: absolute;
    top: 20px;
    right: 120px;
    z-index: 2;
    display: flex;
    .searchControl {
      display: flex;
      height: 32px;
      margin-right: 20px;
    }
    .layersControl {
      ul {
        display: flex;
        li {
          margin-right: 20px;
        }
      }
    }
    .surroundingControl {
      width: 400px;
      height: 100%;
      .top-nav-tabs {
        background-color: #fff;
        ul {
          width: 100%;
          box-sizing: border-box;
          display: flex;
          background-color: rgba($color: #3a71ff, $alpha: 0.1);
          border-bottom: 2px solid #3a71ff;
          li {
            padding: 0 20px;
            height: 40px;
            line-height: 40px;
            position: relative;
            h4 {
              text-align: center;
              font-size: 16px;
              font-family: Source Han Sans CN-Medium, Source Han Sans CN;
              font-weight: 500;
            }
          }
          .type_1 {
            background-color: rgba($color: #3a71ff, $alpha: 1);
          }
          .showSurrounding {
            cursor: pointer;
            position: absolute;
            right: 0;
            width: 40px;
            height: 40px;
            line-height: 40px;
            text-align: center;
          }
        }
      }
      .main {
        width: 100%;
        height: 100%;
        overflow: overlay;
        background-color: #fff;
        ul {
          width: 100%;
          li {
            width: 100%;
            ::v-deep .el-tabs__item {
              padding: 0 20px;
            }
            ::v-deep .el-tabs__header {
              margin: 0 0;
            }
          }
        }
      }
    }
  }
  .controlPanel {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 2;
    ul {
      li {
        .openEdit {
        }
        .edit {
          width: 300px;
          padding: 10px 10px;
          border-radius: 10px 10px;
          background-color: #fff;
          .title {
            h3 {
              font-size: 16px;
              font-weight: 600;
              color: #101010;
              line-height: 20px;
            }
          }
          .main {
            line-height: 40px;
            .el-radio {
              line-height: 30px;
            }
            .bottom {
              width: 100%;
              margin-top: 20px;
              display: flex;
              justify-content: space-around;
            }
          }
        }
      }
    }
  }
}
</style>

import request from '@/router/axios'

// 项目进度总览接口
export const progressOverView = () => {
  return request({
    url: `/api/arch-zshb/itemProgress/overview`,
    method: 'get'
  })
}

//项目列表
export const progressList = (params) => {
  return request({
    url: `/api/arch-zshb/itemProgress/list`,
    method: 'get',
    params: {
      ...params
    }
  })
}

// 根据项目code查询顶部项目进度异常,数据统计,数据详细信息
export const getItemProgressDetailsByItemCode = (itemCode) => {
  return request({
    url: `/api/arch-zshb/itemProgress/getItemProgressDetailsByItemCode`,
    method: 'get',
    params: {
      itemCode
    }
  })
}

// 根据项目code查询项目施工进度列表
export const getEngineeringProgressListByItemCode = (itemCode) => {
  return request({
    url: `/api/arch-zshb/itemProgress/getEngineeringProgressListByItemCode`,
    method: 'get',
    params: {
      itemCode
    }
  })
}

// 施工进度确认
export const constructionProgressConfirmation = (data) => {
  return request({
    url: `/api/arch-zshb/itemProgress/confirmationOfConstructionProgress`,
    method: 'post',
    data
  })
}

// 根据项目code查询项目施工动态列表
export const getEngineeringDynamicListByItemCode = (itemCode) => {
  return request({
    url: `/api/arch-zshb/itemProgress/getEngineeringDynamicListByItemCode`,
    method: 'get',
    params: {
      itemCode
    }
  })
}

// 根据项目code查询参与方信息 /arch-zshb/itemProgress/getParticipantInformationByItemCode
export const getParticipantInformationByItemCode = (itemCode) => {
  return request({
    url: `/api/arch-zshb/itemProgress/getParticipantInformationByItemCode`,
    method: 'get',
    params: {
      itemCode
    }
  })
}

// 终端
import request from '@/router/axios'

// 查询所有户型
export const getHouseType = (params) => {
  return request({
    url: '/api/arch-zshb/realEstate/findAllHouseTypeByRealEstateId',
    method: 'get',
    params,
  })
}

// 查询楼
export const getBuilding = (params) => {
  return request({
    url: '/api/arch-zshb/houseSell/findBuildingByRealEstateId',
    method: 'get',
    params,
  })
}

// 单元
export const getUnitTower = (params) => {
  return request({
    url: '/api/arch-zshb/houseSell/tower',
    method: 'get',
    params,
  })
}

// 保存贷款方案
export const saveLoanProject = (params) => {
  return request({
    url: '/api/arch-zshb/house/loan/save',
    method: 'post',
    data:{...params},
  })
}

// 提交合同
export const submitContract = (params) => {
  return request({
    url: '/api/arch-zshb/house/deal/submit',
    method: 'post',
    data:{...params},
  })
}

// 终端: (取消)收藏房屋
export const houseCollect = (houseId,type) => {
  return request({
    url: `/api/arch-zshb/realEstate/collect?houseId=${houseId}&type=${type}`,
    method: 'post',
  })
}
<template>
  <div class="sift">
    <div class="top">
      <ul>
        <li class="title">报名状态：</li>
        <li class="main">
          <span
            :class="{ select: index == select1 }"
            v-for="(item, index) in checkData"
            :key="index"
            @click="handlerCheck(index)"
            >{{ item.dictValue }}</span
          >
        </li>
      </ul>
    </div>
    <div class="bottom">
      <ul class="dp-left">
        <li class="condition">
          <p class="title">项目预计金额：</p>
          <div class="price">
            <el-input
              placeholder="万元"
              size="mini"
              prefix-icon="el-icon-search"
              v-model="siftData.minPrice"
              @blur="handlerPrice"
            ></el-input>
            <span>-</span>
            <el-input
              placeholder="万元"
              size="mini"
              prefix-icon="el-icon-search"
              v-model="siftData.maxPrice"
              @blur="handlerPrice"
            ></el-input>
          </div>
        </li>
      </ul>
      <ul class="dp-right">
        <li class="search">
          <el-input v-model="input" size="mini" placeholder="请输入项目名称"></el-input>
          <el-button type="primary" size="mini" @click="poolSearch()" icon="el-icon-search"></el-button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// import { businessDictionary } from '@/api/base/business.js'
export default {
  name: 'applySift',
  data() {
    return {
      input: '',
      checkData: [
        {
          dictkey: null,
          dictValue: '全部'
        },
        {
          dictkey: 1,
          dictValue: '已报名'
        },
        {
          dictkey: 2,
          dictValue: '已选定'
        },
        {
          dictkey: 3,
          dictValue: '已落选'
        }
      ],
      siftData: {
        keyWord: '',
        flag: null,
        minPrice: null,
        maxPrice: null
      },
      select1: 0
    }
  },
  props: {},
  methods: {
    poolSearch() {
      this.siftData.keyWord = this.input
    },
    handlerCheck(index) {
      this.select1 = index
      this.siftData.flag = this.checkData[index].dictkey
    },
    handlerPrice() {
      let data = {
        keyWord: this.siftData.keyWord,
        flag: this.siftData.flag,
        minPrice: Boolean(this.siftData.minPrice) == false ? null : this.siftData.minPrice * 10000,
        maxPrice: Boolean(this.siftData.maxPrice) == false ? null : this.siftData.maxPrice * 10000
      }
      // console.log(data)
      this.$emit('getSiftData', data)
    }
  },
  created() {},
  watch: {
    'siftData.keyWord': {
      deep: true,
      handler() {
        let data = {
          keyWord: this.siftData.keyWord,
          flag: this.siftData.flag,
          minPrice: Boolean(this.siftData.minPrice) == false ? null : this.siftData.minPrice * 10000,
          maxPrice: Boolean(this.siftData.maxPrice) == false ? null : this.siftData.maxPrice * 10000
        }
        // console.log(data)
        this.$emit('getSiftData', data)
      }
    },
    'siftData.flag': {
      deep: true,
      handler() {
        let data = {
          keyWord: this.siftData.keyWord,
          flag: this.siftData.flag,
          minPrice: Boolean(this.siftData.minPrice) == false ? null : this.siftData.minPrice * 10000,
          maxPrice: Boolean(this.siftData.maxPrice) == false ? null : this.siftData.maxPrice * 10000
        }
        // console.log(data)
        this.$emit('getSiftData', data)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/design/applySift.scss';
</style>

<template>
  <el-form
    label-width="130px"
    size="mini"
  >
    <el-row>
      <i class="icon"></i>
      <span class="nav">公司简介</span>
    </el-row>
    <div>
      <el-form-item label="公司名称">
        <span>{{formData.companyName}}</span>

      </el-form-item>
      <el-form-item label="公司描述">
        <span>{{formData.companyDesc}}</span>
      </el-form-item>
    </div>
    <el-row>
      <i class="icon"></i>
      <span class="nav">物业方案</span>
    </el-row>
    <el-form-item label="物业服务方案">
      <span>{{formData.servicePlan}}</span>
    </el-form-item>
    <el-form-item label="物业盈利方案">
      <span>{{formData.profitPlan}}</span>
    </el-form-item>
    <el-row>
      <i class="icon"></i>
      <span class="nav">基础设施建设</span>
    </el-row>
    <div class="baseBuild">
      <div
        class="baseBuild-content"
        v-for="(item,index) in importServices"
        :key="index"
      >
        <el-form-item label="引入服务种类">
          <span>{{item.category}}</span>
        </el-form-item>
        <el-form-item label="成本">
          <span>{{item.cost}}万</span>
        </el-form-item>
        <el-form-item label="预计收益">
          <span>{{item.expect}}万/年</span>
        </el-form-item>
      </div>
    </div>
    <el-row>
      <i class="icon"></i>
      <span class="nav">物业工作阶段</span>
    </el-row>
    <div>
      <el-form-item label="总负责人">
        <span>{{formData.generalPrincipalName}}</span>
      </el-form-item>
      <el-form-item label="负责人">
        <span>{{formData.principalName}}</span>
      </el-form-item>
      <el-form-item label="物业服务启动成本">
        <span>{{startUpCost}}</span>
      </el-form-item>
    </div>
  </el-form>
</template>
  
<script>
import { mapGetters } from 'vuex'
import { fmtText } from '@/utils/filters'
export default {
  props: {
    itemOperationPlanVO: {
      type: Object,
      default: () => { }
    }
  },
  data () {
    return {
      formData: {
        companyName: '',
        companyDesc: '',
        servicePlan: '',
        profitPlan: '',
        importServices: [],
        generalPrincipalName: '',
        principalName: '',
        startUpCost: '',
      },
    }
  },
  watch: {
    itemOperationPlanVO: {
      handler (newVal, oldVal) {
        if(newVal){
          this.formData = newVal
        }
      },
      deep: true,
      //立即执行
      immediate: true
    }
  },
  computed: {
    ...mapGetters(['dictionaries']),
    importServices () {
      const arrayData = []
      this.formData.importServices?.map((item, index) => {
        return arrayData.push({
          category: fmtText(this.dictionaries['import_services'], item.category),
          cost: item.cost / 10000,
          expect: item.expect / 10000
        })
      })
      return arrayData
    },
    startUpCost () {
      return (this.formData.startUpCost / 10000) + '万' || 0 + '万'
    }
  },
  async created () {
    await this.$store.dispatch('dictionaries', 'import_services')

  },
}
</script>
  
<style lang="scss" scoped>
.el-form {
  padding: 0 60px 0 30px;
  .el-row {
    margin: 16px 0;
    height: 30px;
    line-height: 30px;
    .icon {
      display: inline-block;
      width: 4px;
      height: 12px;
      background-color: #3a71ff;
      margin-right: 10px;
    }
    .nav {
      font-size: 14px;
      color: #101010;
      font-weight: 500;
    }
  }
  .baseBuild {
    &-content {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    ::v-deep .el-form-item {
      .el-form-item__label {
        min-width: 100px;
      }
      .el-form-item__content {
        min-width: 100px;
      }
    }
  }
}
.el-form-item {
  .el-input {
    width: 240px;
  }
}

::v-deep .el-textarea__inner {
  background-color: rgba($color: #f2f3f5, $alpha: 0.7);
}
</style>
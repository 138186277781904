<template>
  <div>
    <div
      class="tabContent"
      v-show="showImg == true"
    ></div>
    <div
      class="imging"
      v-show="showImg == false"
    >
      <div class="uploadPictures">
        <div v-if="dialogImageUrl">
          <img
            style="width: 100%; height: 350px"
            :src="dialogImageUrl"
            alt=""
          />
          <div class="maskIcon">
            <span @click="handleRemove(dialogImageUrl)">
              <i class="el-icon-delete"></i>
            </span>
            <span @click="handlePictureCardPreview(dialogImageUrl)">
              <i class="el-icon-full-screen"></i>
            </span>
          </div>
        </div>
        <span v-else>暂无图片</span>
      </div>

      <el-dialog :visible.sync="dialogVisible">
        <img
          width="100%"
          :src="dialogImageUrl"
          alt=""
        />
      </el-dialog>
    </div>
    <div :class="[showImg == true ? 'upload2' : 'upload']">
      <el-upload
        :file-list="datalist"
        :headers="config"
        :action="`${baseURL}` + '/blade-resource/oss/endpoint/put-file-attach'"
        list-type="picture-card"
        ref="files"
        :on-change="handleChange"
        :on-success="handleSuccess"
        :drag="showImg == true ? Boolean(true) : Boolean(false)"
      >
        <i
          slot="default"
          class="el-icon-plus"
        >
          <div v-if="showImg == true">点击或拖拽设计文件到这里</div>
          <div v-else>点击上传</div>
        </i>
        <div
          slot="file"
          slot-scope="{ file }"
          @click="handle(file)"
        >
          <img
            class="el-upload-list__item-thumbnail"
            :src="file.url"
            alt=""
          />
        </div>
      </el-upload>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { getToken } from '@/utils/auth'
import { baseUrl } from '@/config/env'
export default {
  props: {
    photos: {
      type: Array,
      default: function () {
        return []
      }
    },
    tablabel: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters(['token']),
    config () {
      return { 'Blade-Auth': 'bearer ' + getToken() }
    }
  },
  watch: {
    photos: {
      handler (newval, oldval) {
        this.datalist.length = 0
        if (newval?.length > 0) {
          this.showImg = false
          this.datalist = []
          newval.forEach((item) => {
            this.dialogImageUrl = item.link
            this.datalist.push({
              url: item.link,
              attachId: item.id,
              type: item.type,
              file: item.link,
              link: item.link
            })
          })
        } else {
          this.datalist = []
          this.dialogImageUrl = ''
          this.showImg = true
        }
      },
      deep: true
    }
  },
  data () {
    return {
      data: [],
      disabled: true,
      operation: sessionStorage.getItem('operation'),
      baseURL: baseUrl,
      tabPosition: 'right',
      showImg: true,
      dialogImageUrl: '',
      dialogVisible: false,
      datalist: [],
      attachList: [],
      upload2: [],
      upload3: [],
      upload4: [],
      upload5: [],
      upload6: [],
      upload7: [],
      upload8: [],
      upload9: [],
      upload10: [],
      upload11: []
    }
  },
  methods: {
    handleSuccess (response, file, fileList) {
      this.showImg = false
      this.attachList = []
      this.attachList = this.attachList.concat(this.datalist)
      // this.datalist.forEach((item, index) => {
      //   if (item.uid == file.uid) {
      //     // 与正确顺序的UID进行比对，添加进将要上传后台的数组
      //     this.attachList.push({ ...response.data, ...item, url: response.data.link, file: item.url })
      //     // this.$emit('attachList', this.attachList)
      //   }
      // })
      this.attachList = this.attachList.map((item) => {
        return { ...response.data, ...item, url: response.data.link, file: item.url}
      })
      this.attachList.forEach((item) => {
        this.$set(item, 'type', this.tablabel)
      })
      switch (this.tablabel) {
        case '2':
          file.type = '2'
          this.upload2 = this.attachList
          this.$emit('upload2', this.upload2)
          break
        case '3':
          file.type = '3'
          this.upload3 = this.attachList
          this.$emit('upload3', this.upload3)
          break
        case '4':
          file.type = '4'
          this.upload4 = this.attachList
          this.$emit('upload4', this.upload4)
          break
        case '5':
          file.type = '5'
          this.upload5 = this.attachList
          this.$emit('upload5', this.upload5)
          break
        case '6':
          file.type = '6'
          this.upload6 = this.attachList
          this.$emit('upload6', this.upload6)
          break
        case '7':
          file.type = '7'
          this.upload7 = this.attachList
          this.$emit('upload7', this.upload7)
          break
        case '8':
          file.type = '8'
          this.upload8 = this.attachList
          this.$emit('upload8', this.upload8)
          break
        case '9':
          file.type = '9'
          this.upload9 = this.attachList
          this.$emit('upload9', this.upload9)
          break
        case '10':
          file.type = '10'
          this.upload10 = this.attachList
          this.$emit('upload10', this.upload10)
          break
        case '11':
          file.type = '11'
          this.upload11 = this.attachList
          this.$emit('upload11', this.upload11)
          break
      }
    },
    handleRemove (file) {
      this.$nextTick(() => {
        this.datalist.map((item, index) => {
          if (item.url === file) {
            this.datalist.splice(index, 1)
          }
          this.dialogImageUrl = this.datalist?.length > 0 ? this.datalist[this.datalist?.length - 1].url : ''
        })
      })
      if (this.datalist?.length == 1) {
        this.showImg = true
      }
      this.$refs.files.handleRemove(file)
      this.attachList = this.attachList.filter(function (item) {
        return item.file != file
      })
      switch (this.tablabel) {
        case '3':
          this.upload3 = this.attachList
          this.$emit('upload3', this.upload3)
          break
        case '2':
          this.upload2 = this.attachList
          this.$emit('upload2', this.upload2)
          break
        case '4':
          this.upload4 = this.attachList
          this.$emit('upload4', this.upload4)
          break
        case '5':
          this.upload5 = this.attachList
          this.$emit('upload5', this.upload5)
          break
        case '6':
          this.upload6 = this.attachList
          this.$emit('upload6', this.upload6)
          break
        case '7':
          this.upload7 = this.attachList
          this.$emit('upload7', this.upload7)
          break
        case '8':
          this.upload8 = this.attachList
          this.$emit('upload8', this.upload8)
          break
        case '9':
          this.upload9 = this.attachList
          this.$emit('upload9', this.upload9)
          break
        case '10':
          this.upload10 = this.attachList
          this.$emit('upload10', this.upload10)
          break
        case '11':
          this.upload11 = this.attachList
          this.$emit('upload11', this.upload11)
          break
      }
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file
      this.dialogVisible = true
    },
    handle (file) {
      this.dialogImageUrl = file.url
    },
    handleChange (file, fileLists) {
      this.dialogImageUrl = file.url
      this.datalist = fileLists
    }
  },
  mounted () {
    // if (this.operation == '查看') {
    //   this.disabled = false
    //   // console.log('查看')
    // } else if (this.operation == '进入项目概念设计') {
    //   // console.log('进入项目概念设计')
    //   this.disabled = true
    // }
  }
}
</script>
<style lang="scss" scoped>
.tabContent {
  position: relative;
  width: 70%;
  box-sizing: border-box;
  /* width: 90%; */
  height: 400px;
  background: #f5f9fa;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  border: 1px solid #b8c4d1;
  margin-left: 17%;
  display: flex;

  /* justify-content: space-between; */
  .item {
    box-sizing: border-box;
    width: 50%;
    padding-top: 8%;
    padding-left: 200px;

    ::v-deep .el-upload-dragger {
      border: none;
      background-color: #f5f9fa;
      /* width: auto; */
      width: 200px;
      height: 100px;
    }

    img {
      width: 50px;
      height: 50px;
    }
  }

  .item2 {
    box-sizing: border-box;
    width: 50%;
    border-left: 1px dashed #b8c4d1;
    padding-top: 95px;
    padding-top: 102px;
    padding-left: 265px;

    div {
      color: #999999;
      font-size: 14px;
    }

    img {
      margin-left: 2px;
      width: 50px;
      height: 50px;
    }
  }

  .el-icon-plus {
    font-size: 50px;
    color: #b8c4d1;
  }
}

.imging {
  margin-left: 20%;
}

.uploadPictures {
  width: 80%;
  height: 360px;
  position: relative;
}

.maskIcon {
  width: 100%;
  height: 25px;
  position: absolute;
  bottom: 2%;
  right: 0%;
  background: rgba(16, 16, 16, 0.6);
  text-align: right;
  color: #fff;

  .el-icon-delete {
    margin-right: 20px;
  }

  .el-icon-full-screen {
    margin-right: 20px;
  }
}

.upload {
  margin-left: 20%;

  ::v-deep .el-upload--picture-card {
    width: 159px;
    height: 105px;
  }

  ::v-deep .el-upload-list--picture-card .el-upload-list__item {
    width: 159px;
    height: 105px;
  }

  ::v-deep .el-upload-list--picture-card .el-upload-list__item-thumbnail {
    width: 159px;
    height: 105px;
  }

  ::v-deep .el-icon-plus {
    margin-top: -10px;
  }

  .el-icon-plus {
    font-size: 40px;
    color: #d0d7de;

    div {
      margin-top: 10px;
      font-size: 14px;
      color: #999999;
    }
  }
}

.upload2 {
  position: absolute;
  left: 38%;
  top: 35%;

  ::v-deep .el-upload--picture-card {
    border: none;
    width: 0;
  }

  ::v-deep .el-upload-dragger {
    border: none;
    background: #f5f9fa;
  }

  .el-icon-plus {
    font-size: 62px;
    color: #d0d7de;

    div {
      margin-top: 10px;
      font-size: 14px;
      color: #999999;
    }
  }
}

/*1366的设备*/
@media (max-width: 1366px) {
  .tabContent {
    width: 80% !important;

    .item2 {
      padding-left: 150px !important;
    }
  }

  ::v-deep .el-upload-dragger {
    width: 191px !important;
  }

  ::v-deep .el-upload-dragger {
    width: 356px !important;
  }

  .uploadPictures {
    width: 100%;
  }
}

/*1440的设备*/
@media (max-width: 1440px) {
  .tabContent {
    width: 80% !important;
  }

  .item2 {
    padding-left: 162px !important;
  }

  ::v-deep .el-upload-dragger {
    width: 294px !important;
  }

  .uploadPictures {
    width: 100%;
  }
}

/*1024的设备*/
@media (max-width: 1024px) {
  .tabContent {
    .item2 {
      padding-left: 76px !important;
    }
  }

  .tabContent {
    width: 80% !important;
  }

  ::v-deep .el-upload-dragger {
    width: 191px !important;
  }

  ::v-deep .el-upload-list__item {
    width: 150px !important;
  }

  .uploadPictures {
    width: 100%;
  }
}
</style>

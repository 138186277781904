<template>
  <div class="perfectinfo">
    <div class="perfectinfo-top">
      <h2>创建企业/团队</h2>
    </div>
    <div class="perfectinfo-content">
      <div class="perfectinfo-content-list">
        <img class="perfectinfo-content-list-close" src="@/assets/img/commons/icon-pop-close.png" alt="" @click="close">
        <div class="perfectinfo-content-list-title">
          <el-steps :active="active" status="process" align-center>
            <el-step class="phone" title="填写手机号">
              <i slot="icon"></i>
            </el-step>
            <el-step class="gray" title="完善信息">
              <i slot="icon"></i>
            </el-step>
            <el-step class="finish" title="注册成功">
              <i slot="icon"></i>
            </el-step>
          </el-steps>
        </div>
        <div class="perfectinfo-content-list-from">
          <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="130px"
            class="demo-ruleForm">
            <el-form-item label="手机号码" class="perfectinfo-content-list-from-phone" prop="phone">
              <el-input size="small" disabled type="text" v-model="ruleForm.phone" placeholder="请输入手机号">
                <template slot="append">
                  <span class="append-solid">|</span>
                  <span>+86</span>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item class=" perfectinfo-content-list-from-code" label="图片验证码" v-if="this.website.captchaMode"
              prop="code">
              <el-row :span="24">
                <el-col :span="19" class="login-form-border-col">
                  <el-input size="small" v-model="ruleForm.code" auto-complete="off" placeholder="请输入图形验证码">
                  </el-input>
                </el-col>
                <el-col :span="5" class="login-form-border-col" style="margin-bottom: 0px !important">
                  <div class="login-form-border perfectinfo-content-list-from-code-imgd">
                    <img :src="ruleForm.image" class="login-form-border perfectinfo-content-list-from-code-imgd-img"
                      @click="refreshCode" />
                  </div>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item class=" perfectinfo-content-list-from-smsCode" prop="smsCode" label="短信验证码">
              <el-input size="small" v-model="ruleForm.smsCode" auto-complete="off" placeholder="请输入短信验证码">
                <template slot="append">
                  <span @click="handleSend" class="msg-text" :class="[{ display: msgKey }]">{{ msgText }}</span>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item size="small" label prop="checked" class="perfectinfo-content-list-from-checked">
              <el-checkbox v-model="ruleForm.checked" label name="checked" required="true">
                <a href="#">
                  <a href="#" class="perfectinfo-content-list-from-checked-xing">*</a>我已同意<span
                    class="perfectinfo-content-list-from-checked-span">《筑数合宝服务协定》</span></a>
              </el-checkbox>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <div class="perfectinfo-operation">
      <el-button size="small" class="perfectinfo-operation-submit" type="primary" @click="submitForm()">下一步
      </el-button>
    </div>
  </div>
</template>

<script>
// import { roleList } from '@/api/role/index'
import { isvalidatemobile } from '@/utils/validate'
import { mapGetters } from 'vuex'
import { checkPhone } from '@/api/personnel/index'
import { sendSms } from '@/api/user'
import { getCaptcha } from '@/api/user'
export default {
  name: 'perfect',
  data() {
    const validatePhone = (rule, value, callback) => {
      if (isvalidatemobile(value)[0]) {
        callback(new Error(isvalidatemobile(value)[1]))
      } else {
        callback()
      }
    }
    return {
      active: 0,//步骤
      tenantMode: this.website.tenantMode,
      ruleForm: {
        //手机号码
        phone: '',
        // 验证码的值
        code: '',
        //短信验证码
        smsCode: '',
        // 验证码的索引
        codeKey: '',
        // 预加载白色背景
        image: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
      },
      //手机验证码文本
      msgText: '',
      //手机验证码刷新时间
      msgTime: '',
      msgKey: false,
      rules: {
        phone: [{ required: true, trigger: 'blur', validator: validatePhone }],
        code: [{ required: true, trigger: 'blur', message: '验证码不能为空' }],
        smsCode: [{ required: true, trigger: 'blur', message: '手机验证码不能为空' }]
      },
      pictureIdList: [] // 上传照片列表
    }
  },
  computed: {
    ...mapGetters(['token']),
    ...mapGetters(['tagWel', 'userInfo', 'companyList']),
    config() {
      return {
        MSGINIT: this.$t('login.msgText'),
        MSGSCUCCESS: this.$t('login.msgSuccess'),
        MSGTIME: 60
      }
    }
  },
  created() {
    this.refreshCode()
    this.msgText = this.config.MSGINIT
    this.msgTime = this.config.MSGTIME
  },
  mounted() {
    this.ruleForm.phone = this.userInfo && this.userInfo.account || ''
  },
  methods: {
    uploadPhoto(file) {
      this.pictureIdList = file
    },
    // 提交
    submitForm() {
      if (!this.ruleForm.checked) {
        this.$message.warning('请同意协议')
        return
      }
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid && this.ruleForm.checked) {
          let params = {
            ...this.ruleForm
          }
          await checkPhone(params)
          this.$store.dispatch('PREFECT_STATUS', 'false')
          this.$router.push({ name: 'improveInformation' })
        } else {
          return false
        }
      })
    },
    // 刷新验证码
    refreshCode() {
      if (this.website.captchaMode) {
        getCaptcha().then((res) => {
          const data = res.data
          this.ruleForm.codeKey = data.key
          this.ruleForm.image = data.image
        })
      }
    },
    // 验证码 发送验证码
    async handleSend() {
      if (this.ruleForm.phone === '') {
        this.$message.error('请输入手机号')
        return
      }
      if (this.msgKey) return
      this.msgText = this.msgTime + this.config.MSGSCUCCESS
      this.msgKey = true
      if (this.msgKey) {
        let params = { phone: this.ruleForm.phone }
        const newCode = await sendSms(params)
      }
      const time = setInterval(() => {
        this.msgTime--
        this.msgText = this.msgTime + this.config.MSGSCUCCESS
        if (this.msgTime === 0) {
          this.msgTime = this.config.MSGTIME
          this.msgText = this.config.MSGINIT
          this.msgKey = false
          clearInterval(time)
        }
      }, 1000)
    },
    close() {
      this.$router.push({ path: '/' })
    }
  }
}
</script>

<style lang="scss" scoped>
.perfectinfo {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: #edeff6;
  z-index: 106;
  display: flex;
  flex-direction: column;

  &-top {
    display: flex;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    height: 100px;
    background: #edeff6;

    h2 {
      font-size: 20px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #101010;
      border-left: 5px solid #3a71ff;
      padding-left: 10px;
    }
  }

  &-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    &-list {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 70%;
      min-width: 596px;
      background-color: #fff !important;
      box-shadow: 0 0 5px #999;
      box-sizing: border-box;
      padding: 0 10px;

      &-close {
        position: absolute;
        right: 0px;
        padding: 5px;
        width: 15px;
        height: 15px;
      }

      &-title {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 30px 0px 30px 0px;



        // el-steps 样式覆盖
        ::v-deep .el-steps {
          padding-top: 10px;
          width: 50%;

          .phone .el-step__icon {
            background-size: 100% 100%;
            background-image: url("~@/assets/img/perfect/icon-general-phone.png");
          }

          .gray .el-step__icon {
            background-size: 100% 100%;
            background-image: url("~@/assets/img/perfect/icon-general-message-gray.png");
          }

          .finish .el-step__icon {
            background-size: 100% 100%;
            background-image: url("~@/assets/img/perfect/icon-general-finish-gray.png");
          }

          // 图片设置
          .el-step__icon {
            background: #fff;
            transition: .15s ease-out;
            opacity: 1;
            width: 40px;
            height: 40px;
            border: none;
          }


          .el-step__title {
            font-size: 14px;
          }

          //字体颜色 
          .el-step__title.is-process {
            font-weight: 700;
            color: #3A71FF !important;
            font-family: Source Han Sans CN-Medium, Source Han Sans CN;
            font-weight: 500;
          }

          // 进度条颜色
          .el-step.is-horizontal .el-step__line {
            height: 3px;
            top: 20px;
          }

          .el-step__icon-inner {
            // color: #3a71ff;
          }
        }
      }

      &-from {
        padding: 80px 0px 0px 0px;
        width: 100%;

        .el-form-item {
          min-width: 393px;
        }


        ::v-deep .el-input__inner {
          background-color: #F2F3F5;
          border: none;
        }

        ::v-deep .el-input-group__append {
          background-color: #F2F3F5;
          border: none;

          .append-solid {
            height: 10px;
            padding-right: 20px;
          }
        }

        ::v-deep &-avatar .el-form-item__content {
          margin-left: 0px !important;
          line-height: 40px;
          position: relative;
          font-size: 14px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        &-avatar {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding-bottom: 30px;

          img {
            border-radius: 50%;
            border: 1px solid #999;
            width: 60px;
            height: 60px;
            margin-bottom: 10px;
          }
        }

        &-code {
          &-imgd {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            background-color: #F2F3F5;
            border-radius: 0px 4px 4px 0px;
            box-sizing: border-box;
            margin-top: 5px;
            height: 30px;

            &-img {
              width: 99%;
              height: 100%;
              border: 1px solid #DCDFE6;
              border-radius: 0px 4px 4px 0px;
            }
          }
        }

        &-phone,
        &-smsCode {
          ::v-deep .el-form-item__label {
            line-height: 30px;
          }
        }

        &-checked {
          &-xing {
            color: red;
          }

          &-span {
            color: #3a71ff;
          }
        }

        .el-form {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .el-form-item {
            width: 60%;
          }
        }
      }
    }
  }

  &-operation {
    height: 200px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    &-submit {
      width: 370px;
      background-color: #3a71ff;
    }
  }
}

.msg-text {
  display: block;
  width: 60px;
  font-size: 12px;
  text-align: center;
  color: #3a71ff;
  border-left: 1px solid #999999;
  padding: 0 25px 0px 10px;
  cursor: pointer;
}

.msg-text.display {
  color: #ccc;
}
</style>

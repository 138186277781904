// 融资
import request from '@/router/axios'

// 融资池banner推送
export const getPoolBanner = (params) => {
  return request({
    url: '/api/arch-zshb/item/financing/pool/banner',
    method: 'get',
    params: {
      ...params
    }
  })
}

// 融资池数据
export const financePoolList = (params) => {
  return request({
    url: '/api/arch-zshb/item/financing/pool/list',
    method: 'get',
    params: {
      ...params
    }
  })
}

// 项目详情
export const progranDetail = (params) => {
  return request({
    url: '/api/arch-zshb/item/financing/detail',
    method: 'get',
    params: {
      ...params
    }
  })
}

// 融资池报名
export const getPoolApplyList = (params) => {
  return request({
    url: '/api/arch-zshb/item/financing/pool/registerList',
    method: 'get',
    params
  })
}

// 报名-取消报名
export const applyStatus = (params) => {
  return request({
    url: '/api/arch-zshb/item/financing/pool/register',
    method: 'post',
    params: {
      ...params
    }
  })
}


// 收藏-取消收藏
export const collectStatus = (params) => {
  return request({
    url: '/api/arch-zshb/item/financing/pool/collect',
    method: 'post',
    params: {
      ...params
    }
  })
}

// 集成方案
export const allPlan = (params) => {
  return request({
    url: '/api/arch-zshb/item/plan/allPlan',
    method: 'get',
    params
  })
}

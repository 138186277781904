import request from '@/router/axios'
import website from '@/config/website'

// 账户密码登录
export const loginByUsername = (tenantId, deptId, roleId, companyId, phone, username, password, type, key, code) =>
  request({
    url: '/api/blade-auth/oauth/token',
    method: 'post',
    headers: {
      'Tenant-Id': tenantId,
      'Dept-Id': website.switchMode ? deptId : '',
      'Role-Id': website.switchMode ? roleId : '',
      'Captcha-Key': key,
      'Captcha-Code': code
    },
    params: {
      tenantId,
      username,
      password,
      phone,
      companyId,
      grant_type: website.captchaMode ? 'captcha' : 'password',
      scope: 'all',
      type
    }
  })

// 手机号登录
export const loginVerificationCode = (tenantId, deptId, roleId, phone, code) =>
  request({
    url: '/api/blade-auth/oauth/token',
    method: 'post',
    headers: {
      'Tenant-Id': tenantId,
      'Content-Type': 'application/x-www-form-urlencoded',
      'Dept-Id': website.switchMode ? deptId : '',
      'Role-Id': website.switchMode ? roleId : ''
    },
    params: {
      tenantId,
      phone,
      code,
      grant_type: 'sms_code'
    }
  })

//根据第三方信息登录
export const loginBySocial = (tenantId, source, code, state) =>
  request({
    url: '/api/blade-auth/oauth/token',
    method: 'post',
    headers: {
      'Tenant-Id': tenantId
    },
    params: {
      tenantId,
      source,
      code,
      state,
      grant_type: 'social',
      scope: 'all'
    }
  })

//根据第三方信息登录
export const loginBySocial1 = (tenantId, source, code, state) => {
  let formData = new FormData()
  formData.append('tenantId', tenantId)
  formData.append('source', source)
  formData.append('code', code)
  formData.append('state', state)
  formData.append('grant_type', 'social')
  formData.append('scope', 'all')
  return request({
    url: '/api/blade-auth/oauth/token',
    method: 'post',
    headers: {
      'Tenant-Id': tenantId
    },
    data: formData
  })
}

//根据单点信息登录
export const loginBySso = (state, code) =>
  request({
    url: '/api/blade-auth/oauth/token',
    method: 'post',
    headers: {
      'Tenant-Id': state
    },
    params: {
      tenantId: state,
      code,
      grant_type: 'authorization_code',
      scope: 'all',
      redirect_uri: website.redirectUri
    }
  })

// 刷新token接口
export const refreshToken = (refresh_token, tenantId, deptId, roleId) => {
  let formData = new FormData()
  formData.append('tenantId', tenantId)
  formData.append('refresh_token', refresh_token)
  formData.append('grant_type', 'refresh_token')
  formData.append('scope', 'all')
  return request({
    url: '/api/blade-auth/oauth/token',
    method: 'post',
    headers: {
      'Tenant-Id': tenantId,
      'Dept-Id': website.switchMode ? deptId : '',
      'Role-Id': website.switchMode ? roleId : ''
    },
    data: formData
  })
}

export const registerGuest = (form, oauthId) =>
  request({
    url: '/api/blade-user/register-guest',
    method: 'post',
    params: {
      tenantId: form.tenantId,
      name: form.name,
      account: form.account,
      password: form.password,
      oauthId
    }
  })

export const getButtons = () =>
  request({
    url: '/api/blade-system/menu/buttons',
    method: 'get'
  })

// 验证码接口
export const getCaptcha = () =>
  request({
    url: '/api/blade-auth/oauth/captcha',
    method: 'get',
    authorization: false
  })

// 退出接口
export const logout = () =>
  request({
    url: '/api/blade-auth/oauth/logout',
    method: 'get',
    authorization: false
  })

// 获取用户信息接口
export const getUserInfo = () =>
  request({
    url: '/api/blade-auth/oauth/user-info',
    method: 'get'
  })

export const sendLogs = (list) =>
  request({
    url: '/api/blade-auth/oauth/logout',
    method: 'post',
    data: list
  })

export const clearCache = () =>
  request({
    url: '/api/blade-auth/oauth/clear-cache',
    method: 'get',
    authorization: false
  })

// 企业获取企业列表接口
export const companyRoleList = (username) => {
  return request({
    url: '/api/arch-zshb/company/company-role-list',
    method: 'get',
    params: { username }
  })
}

// 查询单个企业
export const companyOne = (phone) => {
  return request({
    url: '/api/arch-zshb/company/current-company',
    method: 'get',
    params: { phone }
  })
}

// 获取验证码接口
export const sendSms = (params) => {
  return request({
    authorization: false,
    url: '/api/blade-auth/oauth/captcha/send-sms',
    method: 'get',
    params: params
  })
}

// 完善信息接口
export const improveInformation = (params) => {
  return request({
    url: '/api/arch-zshb/company/sava',
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Tenant-Id': '000000'
    },
    data: {
      addressCityCode: params.addressCityCode,
      addressCountyCode: params.addressCountyCode,
      addressProvinceCode: params.addressProvinceCode,
      addressStreetCode: params.addressStreetCode,
      companyName: params.companyName,
      legalRepresentative: params.legalRepresentative,
      attachLink: params.attachLink,
      companyType: '',
      createDate: params.createDate,
      contactName: params.contactName,
      creditCode: params.creditCode,
      deptId: params.deptId,
      registeredCapital: params.capital,
      labelList: params.labelList,
      email: params.email,
      personnelSize: params.staffsize,
      roleId: params.role.roleId
    }
  })
}

// 注册接口
export const register = (roleId, code, phone, password, confirmPassword) => {
  return request({
    authorization: false,
    url: '/api/blade-user/register',
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Tenant-Id': '000000',
      'Role-Id': website.switchMode ? roleId : ''
    },
    data: {
      tenantId: '000000',
      roleId,
      code,
      phone,
      password,
      confirmPassword
    }
  })
}

// 重设密码
export const fogotUser = (params) => {
  return request({
    authorization: false,
    url: '/api/blade-user/forgot-password',
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Tenant-Id': '000000'
    },
    data: {
      code: params.code,
      confirmPassword: params.passwordTwo,
      password: params.password,
      phone: params.phone,
      tenantId: '000000'
    }
  })
}

// 查询个人信息
export const personalInformation = () => {
  return request({
    url: '/api/blade-user/info',
    method: 'get'
  })
}

// 修改个人信息
export const updateInfo = (data, roleId) => {
  return request({
    url: '/api/blade-user/update-info',
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Tenant-Id': '000000',
      'Dept-Id': '',
      'Role-Id': website.switchMode ? roleId : ''
    },
    data
  })
}

// 微信登陆   /blade-auth/oauth/render/wechat_open
export const getWechatOpen = () => {
  return request({
    authorization: false,
    url: '/api/blade-auth/oauth/render/wechat_open',
    method: 'get'
  })
}

// 绑定手机号
export const checkOutPhone = (params) =>
  request({
    url: '/api/blade-auth/oauth/token',
    method: 'post',
    headers: {
      'Tenant-Id': '000000',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Dept-Id': '',
      'Role-Id': ''
    },
    params: {
      tenantId: '000000',
      phone: params.phone,
      code: params.code,
      grant_type: 'sms_code'
    }
  })

// 微信关联手机号
export const userBind = (data) => {
  return request({
    authorization: false,
    url: '/api/blade-user/bind',
    method: 'post',
    headers: {
      'Tenant-Id': '000000',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Dept-Id': '',
      'Role-Id': '',
      'Blade-Auth': ''
    },
    params: data
  })
}

import request from '@/router/axios'

// 劳务分类:列表查询
export const classList = (params) => {
  return request({
    url: '/api/arch-zshb/labour/class/homePageClass',
    method: 'get',
    params
  })
}

// 获取劳保个人信息
export const userLabour = () => {
  return request({
    url: '/api/arch-zshb/userLabour/get',
    method: 'get'
  })
}
// 劳务首页-热门搜索
export const hotSearch = () => {
  return request({
    url: '/api/arch-zshb/labour/class/hotSearchClass',
    method: 'get'
  })
}

// 劳务首页: 获取劳务个人信息
export const userInfo = () => {
  return request({
    url: '/api/arch-zshb/userLabour/get',
    method: 'get'
  })
}

// 劳务首页: 热门推荐
export const hotSuggest = (areaCityCode) => {
  return request({
    url: '/api/arch-zshb/recruitWorkers/hot',
    method: 'get',
    params: { areaCityCode }
  })
}

// 劳务找活干: 搜索(分页查询)
export const WorkersSearch = (params) => {
  return request({
    url: '/api/arch-zshb/recruitWorkers/search',
    method: 'get',
    params
  })
}

// 劳务找工人: 搜索(分页查询)
export const pageList = (params) => {
  return request({
    url: '/api/arch-zshb/userLabour/pageList',
    method: 'get',
    params
  })
}

// 劳务找活干: 详情
export const WorkersDetail = (params) => {
  return request({
    url: '/api/arch-zshb/recruitWorkers/detail',
    method: 'get',
    params
  })
}

// 劳务找活干: 详情-相似职位
export const similar = (id) => {
  return request({
    url: '/api/arch-zshb/recruitWorkers/similar',
    method: 'get',
    params: { id }
  })
}

// 通过code查询对应的市
export const getAreaByCode = (code) => {
  return request({
    url: '/api/arch-zshb/recruitWorkers/getAddress',
    method: 'get',
    params: { code }
  })
}

// 查询所有省市
export const getSelectCity = (code) => {
  return request({
    url: '/api/blade-system/region/select',
    method: 'get',
    params: { code }
  })
}

// 完善信息或修改信息
export const savaOrUpdate = (data) => {
  return request({
    url: '/api/arch-zshb/userLabour/savaOrUpdate',
    method: 'post',
    data
  })
}

// 劳务系统: 发布招工
export const recruitWorkersSave = (data) => {
  return request({
    url: '/api/arch-zshb/recruitWorkers/save',
    method: 'post',
    data
  })
}

// 劳务系统: 我的招工信息
export const myWorkersList = (params) => {
  return request({
    url: '/api/arch-zshb/recruitWorkers/page',
    method: 'get',
    params
  })
}

// 劳务系统: 修改状态
export const recruitWorkersStatus = (params) => {
  return request({
    url: '/api/arch-zshb/recruitWorkers/status',
    method: 'put',
    params
  })
}

//  劳务系统: 详情
export const recruitWorkers = (id) => {
  return request({
    url: '/api/arch-zshb/recruitWorkers/detail',
    method: 'get',
    params: { id }
  })
}

// 劳务系统: 删除招工
export const recruitWorkersDelete = (id) => {
  return request({
    url: `/api/arch-zshb/recruitWorkers/delete`,
    method: 'delete',
    params: {
      id
    }
  })
}

// 劳务系统: 修改招工信息
export const recruitWorkersUpdate = (data) => {
  return request({
    url: '/api/arch-zshb/recruitWorkers/update',
    method: 'put',
    data
  })
}

// 劳务系统: 重新发布
export const republish = (id) => {
  return request({
    url: '/api/arch-zshb/recruitWorkers/republish',
    method: 'post',
    params: {
      id
    }
  })
}

// 劳务系统: 获取好工作列表
export const searchLabourList = (params) => {
  return request({
    url: '/api/arch-zshb/recruitWorkers/search',
    method: 'get',
    params
  })
}

// 劳务系统:  获取好师傅收藏列表
export const userLabourList = (params) => {
  return request({
    url: '/api/arch-zshb/userLabour/collect/userLabourList',
    method: 'get',
    params
  })
}

// 劳务系统:  收藏-取消收藏
export const collect = (params) => {
  return request({
    url: '/api/arch-zshb/recruitWorkers/collect',
    method: 'post',
    params
  })
}

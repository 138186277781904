<template>
  <div class="planDetail">
    <div class="container">
      <el-tabs
        v-model="activeName"
        @tab-click="handleClick"
      >
        <el-tab-pane
          label="方案封面"
          name="planCover"
          v-if="planShow.includes(0)"
        >
          <plan-cover :coverList="detailList" />
        </el-tab-pane>
        <el-tab-pane
          label="地产方案"
          name="plotPlan"
          v-if="planShow.includes(1)"
        >
          <plot-plan :detailList="detailList.itemPropertyPlan" />
        </el-tab-pane>
        <el-tab-pane
          label="设计方案"
          name="designPlan"
          v-if="planShow.includes(2)"
        >
          <design-plan :data="detailList?.itemDesignPlanVO" />
        </el-tab-pane>
        <el-tab-pane
          label="施工方案"
          name="buildPlan"
          v-if="planShow.includes(3)"
        >
          <build-plan :itemConstructionPlanVo="detailList?.itemConstructionPlanVo" />
        </el-tab-pane>
        <el-tab-pane
          label="销售方案"
          name="salePlan"
          v-if="planShow.includes(4)"
        >
          <salePlan :itemSellingPlanVO="detailList?.itemSellingPlanVO" />
        </el-tab-pane>
        <el-tab-pane
          label="物业方案"
          name="operationPlan"
          v-if="planShow.includes(5)"
        >
          <operation-plan :itemOperationPlanVO="detailList?.itemOperationPlanVO" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import planCover from './planCover.vue'
import plotPlan from './plotPlan.vue'
import designPlan from './designPlan.vue'
import buildPlan from './buildPlan.vue'
import salePlan from './sellPlan.vue'
import operationPlan from './operatorPlan.vue'
export default {
  name: 'planDetail',
  components: {
    planCover,
    plotPlan,
    designPlan,
    buildPlan,
    salePlan,
    operationPlan
  },

  data () {
    return {
      activeName: 'planCover',
      data: ['planCover', 'plotPlan', 'designPlan', 'buildPlan', 'salePlan', 'operationPlan']
    }
  },
  props: {
    planShow: {
      type: Array,
      default: () => {
        return [0, 1, 2, 3, 4, 5]
      }
    },
    detailList: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  watch: {
    detailList: {
      handler (newval, oldval) {
        // console.log('fu',newval);
      },
      deep: true,
      //立即执行
      immediate: true
    }
  },
  created () {
    this.activeName = this.data[this.planShow[0]]
    // console.log(this.activeName)
  },
  methods: {
    handleClick (tab, event) {
      // console.log(tab, event)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/planDetail/planDetail.scss';
</style>

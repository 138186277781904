<template>
  <el-dialog
    title="邀请企业入驻"
    :visible.sync="isShowInvite"
    width="30%"
    center
    :before-close="cancelBtn"
    :append-to-body="true"
  >
    <el-divider></el-divider>
    <span class="text">请复制链接分享给参与方：<span class="link">{{message}}</span></span>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        @click="cancelBtn"
        size="mini"
        class="cancel"
      >取 消</el-button>
      <el-button
        @click="copyBtn"
        size="mini"
        class="confirm"
      >复制链接</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    isShowInvite: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      message: ''
    }
  },
  mounted () {
    this.message = window.location.host
  },
  methods: {
    cancelBtn () {
      this.$emit('cancelInvite', false)
    },
    copyBtn () {
      this.$copyText(this.message).then((e) => {
        this.$message.success('复制成功')
      }, (e) => {
        this.$message.success('复制失败，请稍后重试')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
::v-deep .el-dialog__body {
  padding: 0 20px;
  text-align: center !important;
  font-size: 16px;
  height: 100px;
  .text {
    display: inline-block;
    padding: 30px 0;
    font-size: 14px;
    color: rgba($color: #101010, $alpha: 0.8);
    .link {
      color: #101010;
    }
  }
}
::v-deep .el-dialog__footer {
  padding-bottom: 50px;
  .cancel {
    width: 80px;
    height: 35px;
    display: inline-block;
    margin: 10px 50px;
    color: rgba($color: #000000, $alpha: 0.6);
    font-size: 14px;
    border: 1px solid rgba($color: #000000, $alpha: 0.3);
    border-radius: 8px;
    cursor: pointer;
  }
  .confirm {
    width: 80px;
    height: 35px;
    display: inline-block;
    border-radius: 8px;
    margin: 10px 50px;
    background-color: #3a71ff;
    color: #ffffff;
    cursor: pointer;
  }
}
</style>
<template>
  <div>
    <div class="floorPic">
      <div class="left">
        <div class="top">
          <img :src="imageUrl">
          <div class="mask"><i class="el-icon-full-screen"></i></div>
        </div>
        <div class="bottom">
          <div
            class="bottom-left"
            @click="toLeft"
          ><i class="el-icon-arrow-left"></i></div>
          <div class="bottom-middle">
            <ul class="imgs">
              <li
                id="pic"
                v-for="(item,index) in picList"
                :key="index"
                @click="overPic(item)"
              >
                <img :src="item.link">
              </li>
            </ul>
          </div>
          <div
            class="bottom-right"
            @click="toRight"
          ><i class="el-icon-arrow-right"></i></div>
        </div>
      </div>
      <div class="right">
        <el-tabs
          tab-position="left"
          @tab-click="handleClick"
          v-model="activeName"
        >
          <el-tab-pane
            label="整体效果图"
            name="first"
          ></el-tab-pane>
          <el-tab-pane
            label="单体效果图"
            name="second"
          ></el-tab-pane>
          <el-tab-pane
            label="建筑施工图"
            name="third"
          ></el-tab-pane>
          <el-tab-pane
            label="结构域施工图"
            name="fourth"
          ></el-tab-pane>
          <el-tab-pane
            label="给排水施工图"
            name="fifth"
          ></el-tab-pane>
          <el-tab-pane
            label="电气施工图"
            name="sixth"
          ></el-tab-pane>
          <el-tab-pane
            label="空调施工图"
            name="seventh"
          ></el-tab-pane>
          <el-tab-pane
            label="人防施工图"
            name="eighth"
          ></el-tab-pane>
          <el-tab-pane
            label="样板间"
            name="ninth"
          ></el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!-- <shrink-pic></shrink-pic> -->
  </div>
</template>

<script>
import { allAttachment } from '@/api/designManagement/index.js'
// import ShrinkPic from './shrinkPic.vue'
export default {
  props: {
    designId: {
      type: String,
      default: ''
    }
  },
  watch: {
    designId: {
      handler (newVal, oldVal) {
        if (newVal) {
          this.designId = newVal
          this.getPhoto()
        }
      },
      deep: true,
      // 立即执行
      immediate: true
    }
  },
  data () {
    return {
      allphotos: {},
      picList: [],  // 整体效果图
      activeName: 'first',
      imageUrl: '',
      imgsX: 0 //图片选择位置参数
    }
  },
  // comments: { ShrinkPic },
  methods: {
    getPhoto () {
      allAttachment(this.designId, 5).then((res) => {
        this.allphotos = res.data.data
        this.picList = this.allphotos[2]||[]
        this.imageUrl = this.picList[0]?.link
      })
    },
    handleClick (tab, event) {
      if (tab.name === 'first') {
        this.picList = this.allphotos[2] !== undefined ? this.allphotos[2] : []
        this.imgsX = 0
      } else if (tab.name === 'second') {
        this.picList = this.allphotos[3] !== undefined ? this.allphotos[3] : []
        this.imgsX = 0
      } else if (tab.name === 'third') {
        this.picList = this.allphotos[4] !== undefined ? this.allphotos[4] : []
        this.imgsX = 0
      } else if (tab.name === 'fourth') {
        this.picList = this.allphotos[5] !== undefined ? this.allphotos[5] : []
        this.imgsX = 0
      } else if (tab.name === 'fifth') {
        this.picList = this.allphotos[6] !== undefined ? this.allphotos[6] : []
        this.imgsX = 0
      } else if (tab.name === 'sixth') {
        this.picList = this.allphotos[7] !== undefined ? this.allphotos[7] : []
        this.imgsX = 0
      } else if (tab.name === 'seventh') {
        this.picList = this.allphotos[8] !== undefined ? this.allphotos[8] : []
        this.imgsX = 0
      } else if (tab.name === 'eighth') {
        this.picList = this.allphotos[9] !== undefined ? this.allphotos[9] : []
        this.imgsX = 0
      } else if (tab.name === 'ninth') {
        this.picList = this.allphotos[11] !== undefined ? this.allphotos[11] : []
        this.imgsX = 0
      }
      if (this.picList.length > 0) {
        this.imageUrl = this.picList[0]?.link
      } else {
        this.imageUrl = ''
      }
      let imgs = document.getElementsByClassName('imgs')[0]
      imgs.style.transform = `translate(${this.imgsX}%, ${0}px)`
    },
    overPic (item) {
      this.imageUrl = item.link
    },
    toLeft () {
      let imgs = document.getElementsByClassName('imgs')[0]
      // let img_num = this.picList.length
      let blockW = (23 + 8 / 3)
      let imgsX = this.imgsX
      imgsX += blockW
      if (imgsX > 0) {
        return
      } else {
        this.imgsX = imgsX
      }
      imgs.style.transform = `translate(${this.imgsX}%, ${0}px)`
    },
    toRight () {
      let imgs = document.getElementsByClassName('imgs')[0]
      let img_num = this.picList.length
      let blockW = (23 + 8 / 3)
      let imgsW = (img_num - 4) * blockW
      let imgsX = this.imgsX
      imgsX += -blockW
      if (imgsX < -imgsW) {
        return
      } else {
        this.imgsX = imgsX
      }
      imgs.style.transform = `translate(${this.imgsX}%, ${0}px)`
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .floorPic {
  margin-left: 100px;
  display: flex;
  .left {
    flex: 3;
    .top {
      height: 500px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
      }
      .mask {
        width: 100%;
        height: 30px;
        background-color: rgba($color: #101010, $alpha: 0.6);
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 99;
        i {
          color: #ffffff;
          position: absolute;
          right: 20px;
          top: 10px;
        }
      }
    }
    .bottom {
      margin-top: 20px;
      height: 100px;
      display: flex;
      justify-content: space-between;
      &-left {
        width: 30px;
        background-color: rgba($color: #101010, $alpha: 0.6);
        line-height: 100px;
        text-align: center;
        margin-right: 20px;
        cursor: pointer;
        i {
          color: #ffffff;
        }
      }
      &-right {
        width: 30px;
        background-color: rgba($color: #101010, $alpha: 0.6);
        line-height: 100px;
        text-align: center;
        margin-left: 20px;
        cursor: pointer;
        i {
          color: #ffffff;
        }
      }
      &-middle {
        width: 100%;
        position: relative;
        overflow: hidden;
        height: 100%;
        ul {
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          white-space: nowrap;
          transition: 0.2s all;
          li {
            display: inline-block;
            width: 23%;
            height: 100%;
            margin-right: calc(8% / 3);
            img {
              width: 100%;
              height: 100%;
              vertical-align: bottom;
            }
            &:nth-last-child(1) {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
  .right {
    flex: 1;
    width: 200px;
    height: 500px;
    background-color: #ffffff;
    box-shadow: 0px 0px 5px 2px rgba($color: #000000, $alpha: 0.1);
    border-radius: 2px;
    margin-left: 20px;
    .el-tabs {
      height: 100% !important;
      padding: 0 30px;
      .el-tabs__header {
        float: none;
        margin: 0;
        .el-tabs__item {
          text-align: center;
          margin: 20px 0;
          border-radius: 4px;
          height: 30px;
          line-height: 30px;
          color: #999999;
          &:hover {
            color: #3a71ff !important;
          }
        }
        .el-tabs__active-bar {
          background-color: #ffffff !important;
        }
      }
      .el-tabs__nav {
        margin: 0;
      }
      .is-active {
        color: #3a71ff !important;
        background-color: rgba($color: #3a71ff, $alpha: 0.2);
      }

      .el-tabs__nav-wrap::after {
        content: none;
      }
    }
  }
}
</style>
// 客户管理接口
import request from '@/router/axios'

// 客户池/我的客户查询
export const getCustomerPool = (params, isMine) => {
  return request({
    url: `/api/arch-zshb/customer/page/${isMine}`,
    method: 'get',
    params
  })
}

// 我的客户总览
export const getReport = () => {
  return request({
    url: `/api/arch-zshb/customer/report`,
    method: 'get'
  })
}

// 客户池批量获取或单独获取接口
export const batchCatch = (ids) => {
  return request({
    url: `/api/arch-zshb/customer/batchCatch`,
    method: 'post',
    data: ids
  })
}

//客户池新增客户
export const newCustomers = (data) => {
  return request({
    url: `/api/arch-zshb/customer/insert`,
    method: 'post',
    data
  })
}

//客户池客户详情
export const customerPoolDetails = (id) => {
  return request({
    url: `/api/arch-zshb/customer/get/${id}`,
    method: 'get'
  })
}

// 我的客户释放或批量释放
export const batchRelease = (ids) => {
  return request({
    url: `/api/arch-zshb/customer/batchRelease`,
    method: 'post',
    data: ids
  })
}

//新增跟进客户
export const insert = (data) => {
  return request({
    url: `/api/arch-zshb/customerContactRecord/insert`,
    method: 'post',
    data
  })
}

// 我的客户修改
export const update = (data) => {
  return request({
    url: `/api/arch-zshb/customer/update`,
    method: 'put',
    data
  })
}

// 进入成交状态
export const transaction = (customerId) => {
  return request({
    url: `/api/arch-zshb/customer/deal/start/${customerId}`,
    method: 'post'
  })
}

// 获取楼盘列表 获取楼盘列表
export const propertyList = (companyId) => {
  return request({
    url: `/api/arch-zshb/customer/realEstate/list/${companyId}`,
    method: 'get'
  })
}

// 获取楼栋列表
export const realEstateList = (realEstateId) => {
  return request({
    url: `/api/arch-zshb/customer/house/list/${realEstateId}`,
    method: 'get'
  })
}

// 获取单元列表
export const unitList = (realEstateId, building) => {
  return request({
    url: `/api/arch-zshb/customer/house/list/${realEstateId}/${building}`,
    method: 'get'
  })
}

// 获取房屋列表
export const houseList = (realEstateId, building, unitNo) => {
  return request({
    url: `/api/arch-zshb/customer/house/list/${realEstateId}/${building}/${unitNo}`,
    method: 'get'
  })
}

//终端: 根据门牌号查询户型
export const getHouseTypeList = (realEstateId, houseNumber, building, unitNo) => {
  return request({
    url: `/api/arch-zshb/houseSell/getHouseType`,
    method: 'get',
    params: {
      realEstateId,
      building,
      unitNo,
      houseNumber
    }
  })
}

// 查询购房者信息
export const getListByName = (name) => {
  return request({
    url: `/api/blade-user/query/listByName`,
    method: 'get',
    params: {
      name
    }
  })
}

// 提交房屋合同
export const submitContract = (data) => {
  return request({
    url: `/api/arch-zshb/house/deal/submit`,
    method: 'post',
    data
  })
}

// 获取成交状态
export const statusDisplays = (id) => {
  return request({
    url: `/api/arch-zshb/customer/status/display/list/${id}`,
    method: 'get'
  })
}

// 导入全局组件
import addressSelect from '@/views/land/components/addressSelect.vue'
import addressSelect1 from '@/views/information/components/addressSelect1.vue'
import addressDialog from '@/views/perfect/components/addressDialog.vue'
import perfect from '@/views/perfect/index.vue'
import ldShow from '@/views/land/mall/landDetail/ldShow.vue'
import popNotification from '@/components/popNotification/index.vue'
import applySift from '@/views/design/pool/components/applySift.vue'
import MapContainer from './Map/MapContainer.vue'
import Map3D from "./Map/Map3D.vue"
import buildingProgram from './IntegrationSolutions/buildingProgram/buildingProgram.vue'
import planDetail from './planDetail/planDetail.vue'
import mapPlugin from './Map/mapPlugin.vue'
import peopleSelect from '@/views/message/center/approvals/components/peopleSelect.vue'
// 添加入数组
const arr = [
  perfect,
  addressDialog,
  addressSelect,
  addressSelect1,
  ldShow,
  popNotification,
  MapContainer,
  Map3D,
  buildingProgram,
  planDetail,
  mapPlugin,
  applySift,
  peopleSelect,
]

export default {
  install(Vue) {
    arr.forEach((item) => {
      Vue.component(item.name, item)
    })
  }
}

// 阿里矢量图标库配置
const iconfontVersion = ['567566_pwc3oottzol']
const iconfontUrl = `//at.alicdn.com/t/font_$key.css`

let baseUrl = ''
const codeUrl = `${baseUrl}/code`
const env = process.env
console.info('检测到的环境：', env)
if (env.NODE_ENV === 'development') {
  //baseUrl = `http://192.168.31.40:30001` // 开发环境地址
  baseUrl = `http://192.168.31.40:30001` // 开发环境地址
} else if (env.NODE_ENV === 'production') {
  // baseUrl = `https://api.zhushuhebao.com` // 生产环境地址
  baseUrl = `http://192.168.31.96:81` // 生产环境地址
} else if (env.NODE_ENV === 'test') {
  baseUrl = `http://192.168.31.51:81` // 测试环境地址
}
module.exports = { baseUrl, iconfontUrl, iconfontVersion, codeUrl, env }

/**
 *个人信息状态
 */

import { getStore, setStore } from '@/storage/index'

const personal = {
  state: {
    personal: getStore({ name: 'personal' }) || {}
  },
  actions: {},
  mutations: {
    //存储信息
    SET_PERSONAL: (state, personal) => {
      console.log('personal', personal)
      state.personal = personal
      // 将状态存储到 localStorage
      setStore({ name: 'personal', content: state.personal })
    },
    //清除个人信息
    CLEAR_PERSONAL: (state, personal) => {
      state.personal = personal
      // 将状态存储到 localStorage
      setStore({ name: 'personal', content: state.personal })
    }
  }
}

export default personal

import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import common from './modules/common'
import tags from './modules/tags'
// import logs from './modules/logs'
import dict from './modules/dict'
import land from './modules/land'
import getters from './getters'
import dictionaries from './modules/dictionaries'
import title from './modules/title'
import perfect from './modules/perfect'
import personal from './modules/personal'
import laborPerson from './modules/laborPerson'
import jurisdiction from './modules/jurisdiction'
Vue.use(Vuex)
const store = new Vuex.Store({
  modules: {
    user,
    common,
    dictionaries,
    title,
    tags,
    dict,
    land,
    perfect,
    personal,
    laborPerson,
    jurisdiction
  },
  getters
})

export default store

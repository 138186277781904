// 业务字典
import request from '@/router/axios';

// 获取建筑类型，地块用途等业务字典值
export const businessDictionary= (params) => {
  return request({
    url: '/api/blade-system/dict-biz/dictionary',
    method: 'get',
    params: {
      ...params
    }
  })
}

/**
 *面包屑路由存储字典
 */

import { getStore, setStore } from '@/storage/index'

const title = {
  state: {
    title: getStore({ name: 'title' }) || {},
    title1: getStore({ name: 'title1' }) || [],
    title2: getStore({ name: 'title2' }) || []
  },
  actions: {
    //业务字典
    setTitle({ commit }, title) {
      commit('TITLE', title)
    },
    setTitle1({ commit }, title1) {
      commit('TITLE1', title1)
    },
    setTitle2({ commit }, title2) {
      commit('TITLE2', title2)
    }
  },
  mutations: {
    //业务字典
    TITLE: (state, title) => {
      state.title = title
      // 将字典存储到 localStorage
      setStore({ name: 'title', content: state.title })
    },
    TITLE1: (state, title1) => {
      state.title1 = title1
      setStore({ name: 'title1', content: state.title1 })
    },
    TITLE2: (state, title2) => {
      state.title2 = title2
      setStore({ name: 'title2', content: state.title2 })
    }
  }
}

export default title

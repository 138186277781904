/**
 *存储劳务完善信息状态
 */

import { getStore, setStore } from '@/storage/index'

const laborPerson = {
  state: {
    laborStatus: getStore({ name: 'laborStatus' }) || '',
    currentCityCode: getStore({ name: 'currentCityCode' }) || '',
    currentCity: getStore({ name: 'currentCity' }) || ''
  },
  mutations: {
    //完善信息状态清理
    CLEAR_LABORSTATUS: (state, laborStatus) => {
      state.laborStatus = laborStatus
      // 将状态存储到 localStorage
      setStore({ name: 'laborStatus', content: state.laborStatus })
    },
    //完善信息状态存储
    SET_LABORSTATUS: (state, laborStatus) => {
      state.laborStatus = laborStatus
      // 将状态存储到 localStorage
      setStore({ name: 'laborStatus', content: state.laborStatus })
    },
    // 存储城市code
    SET_CURRENTCITYCODE: (state, currentCityCode) => {
      state.currentCityCode = currentCityCode
      // 将状态存储到 localStorage
      setStore({ name: 'currentCityCode', content: state.currentCityCode })
    },
    // 清除城市的code
    CLEAR_CURRENTCITYCODE: (state, currentCityCode) => {
      state.currentCityCode = currentCityCode
      // 将状态存储到 localStorage
      setStore({ name: 'currentCityCode', content: state.currentCityCode })
    },
    // 存储城市的名字
    SET_CURRENTCITY: (state, currentCity) => {
      state.currentCity = currentCity
      // 将状态存储到 localStorage
      setStore({ name: 'currentCity', content: state.currentCity })
    },
    // 清除城市的名字
    CLEAR_CURRENTCITY: (state, currentCity) => {
      state.currentCity = currentCity
      // 将状态存储到 localStorage
      setStore({ name: 'currentCity', content: state.currentCity })
    }
  }
}

export default laborPerson

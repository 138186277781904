<template>
  <div>
    <!-- <el-button type="text" @click="dialogVisible = true">点击打开 Dialog</el-button> -->

    <el-dialog title="添加人员" :visible.sync="dialogVisible" width="40%" center :before-close="handleClose" append-to-body>
      <div class="content">
        <div class="left">
          <el-input v-model="search" placeholder="搜索名字..." style="width: 200px" size="mini"
            @input="handleSearch"></el-input>
          <div class="searchList" v-if="showSearch">
            <div v-for="(item, index) in this.searchData" :key="index" @click="handleChoose(item)">
              {{ item.realName }}
            </div>
          </div>
          <div class="list-content">
            <div class="left-list" v-for="(item, index) in this.chooseList" :key="index">
              <div class="item">{{ item.realName }}</div>
              <i class="el-icon-close" @click="handleDelete(item)"></i>
            </div>
          </div>
          <div>
            <span slot="footer" class="dialog-footer">
              <el-button @click="handleSure" class="btn1">确 定</el-button>
              <el-button @click="handleCancel" class="btn2">取 消</el-button>
            </span>
          </div>
        </div>
        <div class="right">
          <div class="nav" @click="handleIcon">
            <div class="nav-content">
              <img :src="companyInfo.attachLink" />
              <div>{{ companyInfo.companyName }}</div>
            </div>
            <div>
              <i :class="showList == false ? 'el-icon-caret-bottom' : 'el-icon-caret-top'"></i>
            </div>
          </div>
          <div class="list" v-show="showList">
            <div class="list-item" v-for="(item, index) in list" :key="index" @click="handlepeoleList(item)">
              <img :src="item.avatar" />
              <div>{{ item.realName }}</div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { personnelList } from '@/api/base/region'
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      require: true
    },
    peopleList: {
      type: Array,
      default: function () {
        return []
      }
    },
    companyInfo: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {
      showSearch: false,
      chooseList: [],
      search: '',
      showList: false,
      centerDialogVisible: false,
      searchData: [],
      list: []
    }
  },
  watch: {
    dialogVisible: {
      handler: function (newval, oldval) { }
    },
    peopleList: {
      handler: function (newval, oldval) {
        this.chooseList = JSON.parse(JSON.stringify(newval))
      }
    }
  },
  mounted() { },
  methods: {
    //点击列表的某一项选中
    handlepeoleList(item) {
      this.chooseList.push({ realName: item.realName, id: item.id })
      let map = new Map();
      for (let item of this.chooseList) {
        if (!map.has(item.realName)) {
          map.set(item.realName, item);
        };
      };
      this.chooseList = [...map.values()];
    },
    //点击遮罩层关闭
    handleClose() {
      this.$emit('children', false)
      this.search = ''
      this.showSearch = false
    },
    //弹窗确定
    handleSure() {
      this.$emit('children', false)
      this.$emit('chooseList', this.chooseList)
      this.search = ''
      this.showSearch = false
    },
    //取消弹窗
    handleCancel() {
      this.$emit('children', false)
      this.search = ''
      this.showSearch = false
      // this.chooseList = []
    },
    //删除
    handleDelete(val) {
      this.chooseList = this.chooseList.filter(function (item) {
        return item != val
      })
    },
    //模糊搜索
    handleSearch(val) {
      if (this.search == '') {
        this.showSearch = false
      } else {
        this.searchData = this.list.filter((item) => {
          if (item.realName.includes(val)) {
            return item
          }
        })
        this.showSearch = true
      }
    },
    //右侧列表显示隐藏
    async handleIcon() {
      const { data } = await personnelList(this.companyInfo.id)
      this.list = data.data
      this.showList = !this.showList
    },
    //模糊搜索选中数据
    handleChoose(item) {
      this.chooseList.push({ realName: item.realName, id: item.id })
      let map = new Map();
      for (let item of this.chooseList) {
        if (!map.has(item.realName)) {
          map.set(item.realName, item);
        };
      };
      this.chooseList = [...map.values()];
      this.showSearch = false
      this.search = ''
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-dialog__header {
  border-bottom: 1px solid #edeff6;
}

::v-deep .el-dialog__body {
  height: 300px;
}

::v-deep .el-input .el-input--mini {}

.content {
  width: 100%;
  display: flex;
  box-sizing: border-box;

  .left {
    position: relative;
    width: 50%;
    border-right: 1px solid #ebebeb;
    box-sizing: border-box;

    .searchList {
      top: 10%;
      left: 0;
      position: absolute;
      z-index: 999;
      width: 60%;
      max-height: 200px;
      overflow-y: scroll;
      background-color: #eee;

      div {
        padding: 8px 10px;
        font-size: 12px;
      }

      div:hover {
        background-color: #ecf1ff;
      }
    }

    .list-content {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }

    .left-list {
      margin-right: 5px;
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      width: 80px;
      background-color: #ebebeb;
      box-sizing: border-box;
      padding: 5px 5px;

      .el-icon-close {
        margin-top: 2px;
      }

      .item {
        text-align: center;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #101010;
      }
    }

    .dialog-footer {
      position: absolute;
      top: 95%;
      left: 10%;

      .el-button {
        margin-right: 20px;
      }

      .btn1 {
        background-color: #3a71ff;
        color: #fff;
      }

      .btn2 {
        border: 1px solid #3a71ff;
        color: #3a71ff;
      }
    }
  }

  .right {
    width: 50%;
    height: 280px;
    padding: 0 20px;

    .nav {
      display: flex;
      justify-content: space-between;
      cursor: pointer;

      .nav-content {
        display: flex;
        justify-content: space-between;
        color: #101010;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        font-size: 16px;

        img {
          width: 50px;
          height: 20px;
          margin-right: 10px;
        }
      }
    }

    .list {
      margin-top: 30px;
      height: 220px;
      overflow-y: scroll;

      .list-item {
        display: flex;
        padding: 5px;
        color: #101010;
        margin-bottom: 2px;
        cursor: pointer;

        img {
          width: 30px;
          height: 30px;
          border-radius: 3px;
          margin-right: 20px;
        }
      }
    }

    .list-item:hover {
      background-color: #ecf1ff;
    }
  }
}
</style>

//设计
import request from '@/router/axios'

// 设计池banner推送
export const getPoolBanner = (params) => {
  return request({
    url: '/api/arch-zshb/item/plan/pool/banner',
    method: 'get',
    params: {
      ...params
    }
  })
}

// 项目池数据
export const designPoolList = (params) => {
  return request({
    url: '/api/arch-zshb/item/plan/pool/list',
    method: 'get',
    params: {
      ...params
    }
  })
}

// 项目详情
export const programDetail = (params) => {
  return request({
    url: '/api/arch-zshb/item/plan/detail',
    method: 'get',
    params: {
      ...params
    }
  })
}

// 项目池报名
export const getPoolApplyList = (params) => {
  return request({
    url: '/api/arch-zshb/item/plan/pool/registerList',
    method: 'get',
    params: {
      ...params
    }
  })
}

// 报名-取消报名
export const applyStatus = (params) => {
  return request({
    url: '/api/arch-zshb/item/plan/pool/register',
    method: 'post',
    data: {
      ...params
    }
  })
}

// 收藏-取消收藏
export const collectStatus = (params) => {
  return request({
    url: '/api/arch-zshb/item/plan/pool/collect',
    method: 'post',
    params: {
      ...params
    }
  })
}

//查询 报名 方案详情
export const getplan = (id) => {
  return request({
    url: '/api/arch-zshb/item/plan/register/plan',
    method: 'get',
    params: {
      id
    }
  })
}

//  项目池 修改报名方案  /arch-zshb/item/plan/pool/update/plan
export const poolRegister = (params) => {
  return request({
    url: '/api/arch-zshb/item/plan/pool/update/plan',
    method: 'post',
    data: {
      ...params
    }
  })
}

//  项目池:政策信息查询
export const itemPolicy = (params) => {
  return request({
    url: '/api/arch-zshb/hot/news/itemPolicy',
    method: 'get',
    params
  })
}

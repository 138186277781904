/**
 *存储完善信息状态
 */

import { getStore, setStore } from '@/storage/index'

const perfect = {
  state: {
    skip: getStore({ name: 'skip' }) || '',
    status: getStore({ name: 'status' }) || 'false'
  },
  actions: {
    //完善信息还是创建企业状态存储
    PREFECT_STATUS({ commit }, status) {
      commit('STATUS', status)
    },
    //完善信息状态存储
    SET_IMPROVE({ commit }, skip) {
      commit('SKIP', skip)
    }
  },
  mutations: {
    // 分辨是完善信息还是创建企业状态存储
    STATUS: (state, status) => {
      state.status = status
      // 将状态存储到 localStorage
      setStore({ name: 'status', content: state.status })
    },
    // 分辨是完善信息还是创建企业状态清理
    CLEAR_STATUS: (state, status) => {
      state.status = status
      // 将状态存储到 localStorage
      setStore({ name: 'status', content: state.status })
    },
    //完善信息状态清理
    CLEAR_IMPROVE: (state, skip) => {
      state.skip = skip
      // 将状态存储到 localStorage
      setStore({ name: 'skip', content: state.skip })
    },
    //完善信息状态存储
    SKIP: (state, skip) => {
      state.skip = skip
      // 将状态存储到 localStorage
      setStore({ name: 'skip', content: state.skip })
    }
  }
}

export default perfect

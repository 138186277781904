// 抵押物
import request from '@/router/axios'

// 总览
export const getOverflowData = (params) => {
  return request({
    url: '/api/arch-zshb/finance/report',
    method: 'get',
    params: {
      ...params
    }
  })
}

// 分页查询
export const getPawnList = (params) => {
  return request({
    url: '/api/arch-zshb/finance/list',
    method: 'get',
    params: {
      ...params
    }
  })
}

// 详情
export const getPawnDetail = (params) => {
  return request({
    url: '/api/arch-zshb/finance/detail',
    method: 'get',
    params: {
      ...params
    }
  })
}

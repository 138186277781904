import request from '@/router/axios'

// 投资管理 总览
export const investReport = () => {
  return request({
    url: `/api/arch-zshb/invest/report`,
    method: 'get'
  })
}
// 投资管理 投资倾向
export const investInclination = () => {
  return request({
    url: `/api/arch-zshb/invest/inclination`,
    method: 'get'
  })
}
// 投资管理 项目列表
export const investList = () => {
  return request({
    url: `/api/arch-zshb/invest/list`,
    method: 'get'
  })
}
import request from '@/router/axios'

// 获取融资总览
export const financingOverView = (cycle) => {
  return request({
    url: `/api/arch-zshb/item/financing/report/${cycle}`,
    method: 'get'
  })
}

// 获取融资项目列表
export const projectlList = (params) => {
  return request({
    url: '/api/arch-zshb/item/financing/list',
    method: 'get',
    params
  })
}

// 增加融资项目列表
export const addFinancing = (data) => {
  return request({
    url: '/api/arch-zshb/item/financing/save',
    method: 'post',
    data
  })
}

// 删除融资项目列表
export const delFinancing = (id) => {
  return request({
    url: '/api/arch-zshb/item/financing/remove',
    method: 'delete',
    params: { id }
  })
}

// 修改融资项目列表
export const updateFinancing = (data) => {
  return request({
    url: '/api/arch-zshb/item/financing/update',
    method: 'put',
    data
  })
}

// 查看融资项目详情
export const detailFinancing = (id) => {
  return request({
    url: '/api/arch-zshb/item/financing/detail',
    method: 'get',
    params: { id }
  })
}

// 查询选择融资项目
export const financingPlan = () => {
  return request({
    url: '/api/arch-zshb/item/plan/item',
    method: 'get'
  })
}

// 查询抵押物
export const mortgage = (assetsId) => {
  return request({
    url: '/api/arch-zshb/item/financing/mortgage',
    method: 'get',
    params: { assetsId }
  })
}

// 查询报名企业列表
export const registerList = (params) => {
  return request({
    url: '/api/arch-zshb/item/financing/register/company',
    method: 'get',
    params
  })
}

// 查询收藏企业列表
export const collectList = (params) => {
  return request({
    url: '/api/arch-zshb/item/financing/collect/company',
    method: 'get',
    params
  })
}

// 选中中标人
export const checkPartner = (id) => {
  return request({
    url: '/api/arch-zshb/item/financing/checkPartner',
    method: 'put',
    params: { id }
  })
}

// 暂停取消暂停项目融资
export const cancelPause = (params) => {
  return request({
    url: '/api/arch-zshb/item/financing/update/status',
    method: 'put',
    params
  })
}

// 查询融资时间-报名-收藏-数量
export const financingAllNum = (id) => {
  return request({
    url: '/api/arch-zshb/item/financing/num',
    method: 'get',
    params: { id }
  })
}

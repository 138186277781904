import request from '@/router/axios'

// 项目设计管理 总览
export const designPlanReport = () => {
  return request({
    url: `/api/arch-zshb/item/designPlan/report`,
    method: 'get'
  })
}
// 项目设计管理 列表查询
export const plansAndAudits = (data) => {
  return request({
    url: `/api/arch-zshb/item/designPlan/list/plansAndAudits`,
    method: 'post',
    data
  })
}
// 项目设计管理 分页查询:项目列表
export const designPlanList = (params) => {
  return request({
    url: `/api/arch-zshb/item/designPlan/list`,
    method: 'get',
    params
  })
}

// 项目设计管理 需求确认
export const demandConfirm = (data) => {
  return request({
    url: `/api/arch-zshb/item/designPlan/demand/confirm`,
    method: 'post',
    data
  })
}
// 项目设计管理 开始评审
export const auditStart = (id) => {
  return request({
    url: `/api/arch-zshb/item/designPlan/audit/start/${id}`,
    method: 'get'
  })
}

// 项目设计管理 修改
export const designPlanUpdate = (data) => {
  return request({
    url: `/api/arch-zshb/item/designPlan/update`,
    method: 'put',
    data
  })
}
// 附件查询
export const attachment = (id, queryType, type) => {
  return request({
    url: `api/arch-zshb/attach/${id}/${queryType}/${type}`,
    method: 'get'
  })
}
// 附件查询所有
export const allAttachment = (id, queryType) => {
  return request({
    url: `api/arch-zshb/attach/${id}/${queryType}`,
    method: 'get'
  })
}

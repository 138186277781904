// 地图功能封装
import * as turf from '@turf/turf'
import AMapLoader from '@amap/amap-jsapi-loader'

export default {
  data() {
    return {
      map: null,
      AMap: null,
      mapCenter: null, //中心点
      mouseTool: null, //鼠标工具插件对象
      city: '', //城市，省市县名
      input: '', //搜索框
      satelliteControl: false, //卫星图层控制
      roalControl: false, //路网控制
      edit: true, //编辑面板控制
      overlays: [], //已保存覆盖物
      editOverlays: [], //新增覆盖物
      editOverlayType: '', //新增覆盖物类型
      surroundingType: [
        {
          dictkey: 1,
          dictValue: '交通'
        },
        {
          dictkey: 2,
          dictValue: '商业'
        },
        {
          dictkey: 3,
          dictValue: '学校'
        },
        {
          dictkey: 4,
          dictValue: '医院'
        }
      ], //周边
      type_1: 0, //周边搜索类型
      showSurrounding: true, //隐藏周边面板
      activeName: 'first', //周边二级搜索类型
      placeSearch: null, //poi地图搜索定位对象
      textData: []
    }
  },
  props: {
    // 中心点,可以使城市或者坐标，城市高于坐标，城市支持省、市、区（县）名，支持城市adcode、citycode，如遇重名的情况，会按城市编码表顺序返回第一个。
    center: {
      type: Array,
      default: () => {
        return null
      }
    },
    address: {
      type: String,
      default: '山西省晋城市泽州县周村镇'
    },
    // 详细地理位置
    detailAddress: {
      type: String,
      default: null
    },
    // 初始化缩放级别
    zoom: {
      type: Number,
      default: 14
    },
    // 标记点
    marker: {
      type: Array,
      default: () => {
        return []
      }
    },
    // 折线
    polyline: {
      type: Array,
      default: () => {
        return [
          // {
          //   color: 'red',
          //   points: [
          //     [116.368904, 39.913423],
          // [116.382122, 39.901176],
          // [116.387271, 39.912501],
          // [116.398258, 39.9046]
          //   ]
          // },
          // {
          //   color: 'white',
          //   points: [
          //     [116.3689, 39.91342],
          //     [116.38212, 39.90117],
          //     [116.38727, 39.9125],
          //     [116.39825, 39.904]
          //   ]
          // }
        ]
      }
    },
    // 多边形
    polygon: {
      type: Array,
      default: () => {
        return [
          // {
          //   color: 'red',
          //   points: [
          //     [116.371587, 39.928234],
          //     [116.382122, 39.918775],
          //     [116.36964, 39.917182],
          //     [116.362609, 39.924676]
          //   ]
          // },
          // {
          //   color: 'blue',
          //   points: [
          //     [116.3689, 39.91342],
          //     [116.38212, 39.90117],
          //     [116.38727, 39.9125],
          //     [116.39825, 39.904]
          //   ]
          // }
        ]
      }
    },
    // 圆形
    circle: {
      type: Array,
      default: () => {
        return [
          // {
          //   center: [116.371587, 39.928234],
          //   radius: 1000,
          //   color: 'red'
          // },
          // {
          //   center: [116.39825, 39.904],
          //   radius: 1000,
          //   color: 'red'
          // }
        ]
      }
    },
    // 矩形
    rectangle: {
      type: Array,
      default: () => {
        return [
          // {
          //   southWest: [116.356449, 39.859008],
          //   northEast: [116.417901, 39.893797],
          //   color: 'red'
          // },
          // {
          //   southWest: [116.371587, 39.928234],
          //   northEast: [116.39825, 39.904],
          //   color: 'white'
          // }
        ]
      }
    },
    texts: {
      type: Array,
      default: () => {
        return null
      }
    },
    // 编辑模式
    editModel: {
      type: Boolean,
      default: false
    },
    // 3d控件
    control3D: {
      type: Boolean,
      default: false
    },
    // 搜索
    searchControl: {
      type: Boolean,
      default: false
    },
    // 图层控件
    layersControl: {
      type: Boolean,
      default: false
    },
    // 周边
    surroundingControl: {
      type: Boolean,
      default: false
    }
  },
  created() {},
  mounted() {
    AMapLoader.reset()
    this.initAMap()
  },
  methods: {
    initAMap() {
      AMapLoader.load({
        key: 'b0994daf461a68b2817f4aa36167ca82', // 申请好的Web端开发者Key，首次调用 load 时必填
        version: '2.0', //指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [
          'AMap.ToolBar', //地图工具条插件，可以用来控制地图的缩放和平移
          'AMap.ControlBar', //组合了旋转、倾斜、复位、缩放在内的地图控件，在3D地图模式下会显示
          'AMap.MouseTool', //鼠标工具，矢量图形编辑
          'AMap.AutoComplete', //输入提示插件
          'AMap.PlaceSearch', //POI搜索插件
          'AMap.Geocoder' //地理编码与逆地理编码
        ], //需要使用的的插件列表，如比例尺'AMap.Scale'等
        AMapUI: {
          // 是否加载 AMapUI，缺省不加载
          version: '1.1',
          plugins: [] // 需要加载的 AMapUI ui插件
        },
        Loca: {
          // 是否加载 Loca， 缺省不加载
          version: '2.0'
        }
      })
        .then((AMap) => {
          this.map = new AMap.Map('container', {
            //设置地图容器id
            rotateEnable: true,
            pitchEnable: true,
            resizeEnable: true,
            pitch: 0,
            rotation: -15,
            viewMode: '3D', //开启3D视图,默认为关闭
            buildingAnimation: true, //楼块出现是否带动画
            expandZoomRange: true,
            terrain: true, // 开启地形图
            zoom: this.zoom,
            zooms: [2, 22] //地图显示的缩放级别范围, 默认为 [2, 20] ，取值范围 [2 ~ 30]
            // mapStyle: 'amap://styles/whitesmoke' //设置地图的显示样式
          })
          this.AMap = AMap
          // 设置中心点和缩放级别
          this.setCenter()
          // 预设城市
          // this.gotoCity()
          // 地址转经纬标记点
          this.addressToPoint()
          // 点标记
          this.addMarker(this.marker)
          // 圆形区域
          this.addCircle(this.circle)
          // 折线
          this.addPolyline(this.polyline)
          // 多边形
          this.addPolygon(this.polygon)
          // 矩形
          this.addRectangle(this.rectangle)
          // 图层设置
          this.map.add(this.addSatellite())
          this.map.add(this.addRoal())
          // 3d插件
          this.mapConfig()
          // 根据覆盖物范围调整视野
          this.setFitView()
          // 获取地图位置信息
          // this.getMapsEvent()
          // 搜索
          this.search(AMap)
          // 周边
          this.getSurroundingData('地铁站', 'plane0')
        })
        .catch((e) => {
          // console.log(e)
        })
    },
    // 设置中心点和缩放级别
    setCenter() {
      // console.log(this.center, this.zoom)
      // 简写 var position = [116, 39];
      if (this.mapCenter) {
        // console.log(this.center, this.zoom)
        this.map.setCenter(this.mapCenter)
        // let point = [
        //   {
        //     icon: '',
        //     position: this.mapCenter,
        //     title: ''
        //   }
        // ]
        // this.map.remove(this.overlays)
        // this.addMarker(point)
      }
    },
    // 地块中心点
    setPlotCenter(data) {
      if (data.length >= 3) {
        let arr = []
        data.forEach((item) => {
          arr.push(turf.point(item))
        })
        var features = turf.featureCollection(arr)
        this.mapCenter = turf.center(features).geometry.coordinates
        this.map.setCenter(this.mapCenter)
      }
    },
    // 地图插件配置
    mapConfig() {
      if (this.editModel) {
        var controlBar = new AMap.ControlBar({
          position: {
            right: '10px',
            top: '10px'
          }
        })
        controlBar.addTo(this.map)

        var toolBar = new AMap.ToolBar({
          position: {
            position: 'absolute',
            right: '40px',
            top: '110px'
          }
        })
        toolBar.addTo(this.map)
      }
    },
    // 根据覆盖物范围调整视野
    setFitView(data) {
      if (data) {
        this.map.setFitView(data)
      } else if (!this.overlays) {
        this.map.setFitView()
      } else {
        // 传入覆盖物数组，仅包括polyline和marker1的情况
        this.map.setFitView(this.overlays)
      }
    },
    // 设置城市
    gotoCity() {
      if (this.address) {
        let reg = /^[0-9]+$/
        // console.log(reg.test('0531'),reg.test(this.address));
        let address = this.address
        // 数字
        if (reg.test(this.address)) {
          this.city = address
        } else {
          // 中文
          let index1 = address.lastIndexOf('县') !== -1 ? address.lastIndexOf('县') : address.lastIndexOf('区')
          let index2 = address.lastIndexOf('市')
          let index3 = address.lastIndexOf('省')
          if (index1 !== -1) {
            // console.log(address.slice(index2+1,index1+1));
            this.city = address.slice(index2 + 1, index1 + 1)
          } else if (index2 !== -1) {
            // console.log(address.slice(index3+1,index2+1));
            this.city = address.slice(index3 + 1, index2 + 1)
          } else if (index3 !== -1) {
            // console.log(address.slice(0,index3+1));
            this.city = address.slice(0, index3 + 1)
          } else {
            this.$message('地址格式错误！')
            this.city = '北京'
          }
        }
        var val = this.city
        this.map.setCity(val, () => {
          console.log(`已跳转至${val}`)
        })
      }
    },
    // 添加点、多点标记
    addMarker(data) {
      var markerList = []
      data.forEach((item, index) => {
        markerList.push(
          new AMap.Marker({
            icon: item.icon,
            position: item.position,
            title: item.title,
            style: {
              width: 10,
              height: 20
            }
          })
        )
      })
      this.overlays.push(...markerList)
      // 覆盖物初始化
      this.map.add(markerList)
      this.setFitView()
    },
    // 添加折线
    addPolyline(data) {
      var polylineList = []
      data.forEach((item, index) => {
        var path = []
        item.points.forEach((item1) => {
          // 折线的节点坐标数组，每个元素为 AMap.LngLat 对象
          path.push(new AMap.LngLat(item1[0], item1[1]))
        })
        polylineList.push(
          // 创建折线实例
          new AMap.Polyline({
            path: path,
            borderWeight: 10, // 线条宽度，默认为 1
            strokeColor: item.color || 'white', // 线条颜色
            lineJoin: 'round' // 折线拐点连接处样式
          })
        )
      })
      this.overlays.push(...polylineList)

      // 覆盖物初始化
      this.map.add(polylineList)
      this.setFitView()
    },
    // 多边形
    addPolygon(data) {
      if (this.polygon.length !== 0 && this.AMap) {
        var polygonList = []
        let selectOption = []
        data.forEach((item, index) => {
          if (item && item.points && item.points.length > 2) {
            var path = []
            item.points.forEach((item1) => {
              // 多边形轮廓线的节点坐标数组
              if (item1.length !== 0) {
                path.push(new this.AMap.LngLat(item1[0], item1[1]))
              }
            })
            // 创建多边形实例
            let option = new this.AMap.Polygon({
              path: path,
              fillColor: item.color || 'blue', // 多边形填充颜色
              fillTransparency: 0.2,
              borderWeight: 2, // 线条宽度，默认为 1
              strokeColor: item.color || 'blue', // 线条颜色
              fillOpacity: 0.2, //填充透明度
              strokeOpacity: 0.05
            })
            polygonList.push(option)
            if (item.color == 'red') {
              selectOption.push(option)
              this.setPlotCenter(item.points)
              // console.log(selectOption)
            }
          }
        })
        // 多边形轮廓线的节点坐标数组
        this.overlays.push(...polygonList)
        // 覆盖物初始化
        this.map.add(polygonList)
        this.setFitView(selectOption)
      }
    },
    // 添加圆形
    addCircle(data) {
      var circleList = []
      data.forEach((item) => {
        circleList.push(
          new AMap.Circle({
            center: new AMap.LngLat(item.center[0], item.center[1]), // 圆心位置
            radius: item.radius, //半径
            strokeColor: item.color || '#F33', //线颜色
            strokeOpacity: 1, //线透明度
            strokeWeight: 3, //线粗细度
            fillColor: item.color || '#ee2200', //填充颜色
            fillOpacity: 0.35 //填充透明度
          })
        )
      })

      this.overlays.push(...circleList)
      // 覆盖物初始化
      this.map.add(circleList)
      this.setFitView()
    },
    // 矩形
    addRectangle(data) {
      var rectangleList = []
      data.forEach((item) => {
        var southWest = new AMap.LngLat(item.southWest[0], item.southWest[1])
        var northEast = new AMap.LngLat(item.northEast[0], item.northEast[1])
        var bounds = new AMap.Bounds(southWest, northEast)
        rectangleList.push(
          new AMap.Rectangle({
            bounds: bounds,
            strokeColor: item.color || 'red',
            strokeWeight: 6,
            strokeOpacity: 0.5,
            strokeDasharray: [30, 10],
            // strokeStyle还支持 solid
            strokeStyle: 'dashed',
            fillColor: 'blue',
            fillOpacity: 0.5,
            cursor: 'pointer',
            zIndex: 50
          })
        )
      })
      this.overlays.push(...rectangleList)
      // 覆盖物初始化
      this.map.add(rectangleList)
      this.setFitView()
    },
    // 添加卫星图层
    addSatellite() {
      var layer = {}
      if (this.satelliteControl) {
        layer = new AMap.TileLayer.Satellite()
      }
      return layer
    },
    handlerSatellite() {
      this.satelliteControl = !this.satelliteControl
      this.initAMap()
    },
    // 添加路网
    addRoal() {
      var layer = {}
      if (this.roalControl) {
        layer = new AMap.TileLayer.RoadNet()
      }
      return layer
    },
    handlerRoal() {
      this.roalControl = !this.roalControl
      this.initAMap()
    },
    // 获取MapsEvent对象。该对象包含触发的对象目标、触发所在经纬度等信息
    getMapsEvent() {
      this.map.on('click', function (ev) {
        // 触发事件的对象
        var target = ev.target
        // 触发事件的地理坐标，AMap.LngLat 类型
        var lnglat = ev.lnglat
        // 触发事件的像素坐标，AMap.Pixel 类型
        var pixel = ev.pixel
        // 触发事件类型
        var type = ev.type
        console.log(target, 'lnglat:' + lnglat, 'pixel:' + pixel, type)
      })
    },
    // 编辑覆盖物
    draw(type) {
      switch (type) {
        case 'marker': {
          this.mouseTool.marker({
            //同Marker的Option设置
          })
          break
        }
        case 'polyline': {
          this.mouseTool.polyline({
            strokeColor: '#80d8ff'
            //同Polyline的Option设置
          })
          break
        }
        case 'polygon': {
          this.mouseTool.polygon({
            fillColor: '#00b0ff',
            strokeColor: '#80d8ff'
            //同Polygon的Option设置
          })
          break
        }
        case 'rectangle': {
          this.mouseTool.rectangle({
            fillColor: '#00b0ff',
            strokeColor: '#80d8ff'
            //同Polygon的Option设置
          })
          break
        }
        case 'circle': {
          this.mouseTool.circle({
            fillColor: '#00b0ff',
            strokeColor: '#80d8ff'
            //同Circle的Option设置
          })
          break
        }
      }
    },
    // 打开编辑
    handlerOpen() {
      this.edit = false
      this.addOverlay()
    },
    // 选择覆盖物类型
    changeOverflyType() {
      this.mouseTool.close() //关闭，并清除覆盖物
      this.addOverlay()
    },
    // 添加覆盖物
    addOverlay() {
      this.mouseTool = new AMap.MouseTool(this.map)
      //监听draw事件可获取画好的覆盖物
      this.mouseTool.on('draw', (e) => {
        this.editOverlays.push(e.obj)
        // console.log(this.editOverlays)
      })
      this.draw(this.editOverlayType)
    },
    // 清除编辑内容
    handlerClear() {
      this.map.remove(this.overlays)
      this.editOverlays = []
    },
    // 保存编辑内容
    handlerSave() {
      this.overlays.push(...this.editOverlays)
      // console.log(this.overlays)
      // console.log(this.overlays.length)
      this.setFitView()
      this.getMapKeyInfo()
      this.mouseTool.close() //关闭，并清除覆盖物
      this.editOverlays = []
      this.editOverlayType = ''
      this.edit = true
    },
    // 信息弹窗
    addInfo(data) {
      data.forEach((item) => {
        var infoWindow = new AMap.InfoWindow({
          anchor: 'bottom-center',
          content: item.string
        })
        infoWindow.open(this.map, item.center)
      })
    },
    // 创建纯文本标记
    addText(data) {
      if (this.texts && this.AMap) {
        let arr = []
        data.forEach((item) => {
          var text = new AMap.Text({
            text: item.string,
            anchor: 'center', // 设置文本标记锚点
            draggable: false,
            cursor: 'pointer',
            angle: 0,
            style: {
              padding: '.75rem 1.25rem',
              'margin-bottom': '1rem',
              'border-radius': '7.5rem',
              'background-color': 'white',
              width: '15rem',
              'border-width': 0,
              'box-shadow': '0 2px 6px 0 rgba(114, 124, 245, .5)',
              'text-align': 'center',
              'font-size': '12px',
              color: 'blue'
            },
            position: item.center
          })
          arr.push(text)
        })
        this.map.add(arr)
        this.textData.push(...arr)
      }
    },
    // 计算路径长度
    calculateLength(arr) {
      if (arr.length >= 2) {
        let dis = AMap.GeometryUtil.distanceOfLine(arr)
        // console.log(dis + '米(m)')
      }
    },
    // 计算区域面积
    calculateArea(arr) {
      if (arr.length >= 3) {
        let area = AMap.GeometryUtil.ringArea(arr)
        // console.log(area + '平方米(m^2)')
      }
    },
    // 获取地图中心点，缩放级别，覆盖物
    getMapKeyInfo() {
      let map = {
        center: this.mapCenter,
        zoom: this.mapZoom,
        overlays: this.overlays
      }
      this.$emit('getMapKeyInfo', map)
    },
    // 搜索定位
    search() {
      //输入提示
      var autoOptions = {
        input: 'tipinput'
      }
      var auto = new AMap.AutoComplete(autoOptions)
      var placeSearch = new AMap.PlaceSearch({
        map: this.map
      }) //构造地点查询类
      auto.on('select', select) //注册监听，当选中某条记录时会触发
      function select(e) {
        placeSearch.setCity(e.poi.adcode)
        placeSearch.search(e.poi.name) //关键字查询查询
      }
    },
    // 周边搜索
    handlerOpen1() {
      this.showSurrounding = !this.showSurrounding
      if (this.placeSearch) {
        this.placeSearch.render.clear()
      }
      let index = this.type_1
      if (index == 0) {
        this.activeName = 'first'
        this.getSurroundingData('地铁站', 'plane0')
      } else if (index == 1) {
        this.getSurroundingData('商业', 'plane4')
      } else if (index == 2) {
        this.getSurroundingData('学校', 'plane5')
      } else if (index == 3) {
        this.getSurroundingData('医院', 'plane6')
      }
    },
    handlerSurroundingType(index) {
      this.type_1 = index
      if (this.placeSearch) {
        this.placeSearch.render.clear()
      }
      if (index == 0) {
        this.activeName = 'first'
        this.getSurroundingData('地铁站', 'plane0')
      } else if (index == 1) {
        this.getSurroundingData('商业', 'plane4')
      } else if (index == 2) {
        this.getSurroundingData('学校', 'plane5')
      } else if (index == 3) {
        this.getSurroundingData('医院', 'plane6')
      }
    },
    handleClick(val) {
      // console.log(val.name)
      let name = val.name
      if (this.placeSearch) {
        this.placeSearch.render.clear()
      }
      if (name == 'first') {
        this.getSurroundingData('地铁站', 'plane0')
      } else if (name == 'second') {
        this.getSurroundingData('公交站', 'plane1')
      } else if (name == 'third') {
        this.getSurroundingData('停车场', 'plane2')
      } else if (name == 'fourth') {
        this.getSurroundingData('加油站', 'plane3')
      }
    },
    getSurroundingData(type, dom) {
      if (this.surroundingControl) {
        this.placeSearch = new AMap.PlaceSearch({
          type: type, // 兴趣点类别
          pageSize: 5, // 单页显示结果条数
          pageIndex: 1, // 页码
          // city: '010', // 兴趣点城市
          // citylimit: true, //是否强制限制在设置的城市内搜索
          map: this.map, // 展现结果的地图实例
          panel: dom, // 结果列表将在此容器中进行展示。
          autoFitView: true // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
        })
        let center = this.map.getCenter()
        var cpoint = center //中心点坐标
        this.placeSearch.searchNearBy('', cpoint, 500, function (status, result) {
          // console.log(status, result)
        })
      }
    },
    // 地址-->坐标
    addressToPoint() {
      if (this.detailAddress && this.AMap) {
        var geocoder = new this.AMap.Geocoder({
          city: '全国' //城市设为北京，默认：“全国”
        })
        let address = this.detailAddress
        let marker = new AMap.Marker()
        geocoder.getLocation(address, (status, result) => {
          if (status === 'complete' && result.geocodes.length) {
            // console.log(result.geocodes)
            let res = result.geocodes[0].location
            this.mapCenter = [res.lng, res.lat]
            this.setCenter()
            this.addPolygon(this.polygon)
          } else {
            console.error('根据地址查询位置失败')
          }
        })
      }
    }
  },
  beforeDestroy() {
    this.map?.destroy()
  },
  watch: {
    detailAddress: {
      handler() {
        this.addressToPoint()
      }
    },
    polygon: {
      deep: true,
      handler(newValue, oldValue) {
        if (this.overlays && this.map) {
          this.map.remove(this.overlays)
          this.overlays = []
        }
        // console.log(newValue,413241341);
        this.addPolygon(newValue)
      }
    },
    texts: {
      deep: true,
      handler() {
        if (this.textData && this.map) {
          this.map.remove(this.textData)
          this.textData = []
        }
        this.addText(this.texts)
      }
    }
  }
}

/**
 *业务字典接口
 */
import request from '@/router/axios'
// 获取字典
export const getDictionaries = (code) => {
  return request({
    url: '/api/blade-system/dict-biz/dictionary',
    method: 'get',
    params: {
      code
    }
  })
}

// 获取方案企业名称
export const companyNameList = (params) => {
  return request({
    url: 'api/arch-zshb/company/nameList',
    method: 'get',
    params
  })
}
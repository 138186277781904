import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './permission' // 权限
import axios from './router/axios'
import VueAxios from 'vue-axios'
import * as urls from '@/config/env'
import { loadStyle } from './utils/util'
import website from '@/config/website'
import { iconfontUrl, iconfontVersion } from '@/config/env'
import i18n from './lang' // Internationalization
import globalComponents from '@/components/index.js'

import './assets/scss/common.scss'
import crudCommon from '@/mixins/crud'
import '@/assets/icons/index.js'
import * as echarts from 'echarts'

import { Base64 } from 'js-base64'
Vue.prototype.$Base64 = Base64
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

// 注册全局crud驱动
window.$crudCommon = crudCommon
Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
})
// 全局注册
// Vue.use(window.VUE, {
//   size: 'small',
//   tableSize: 'small',
//   calcHeight: 65,
//   i18n: (key, value) => i18n.t(key, value)
// });
// 全局组件导入
Vue.use(globalComponents)

// 加载Vue拓展
Vue.use(router)
Vue.use(VueAxios, axios)

// 预渲染标题
// import MetaInfo from 'vue-meta-info'
// Vue.use(MetaInfo)
// 加载相关url地址
Object.keys(urls).forEach((key) => {
  Vue.prototype[key] = urls[key]
})

// 加载website
Vue.prototype.website = website
// 动态加载阿里云字体库
iconfontVersion.forEach((ele) => {
  loadStyle(iconfontUrl.replace('$key', ele))
})

//全局挂在echart
Vue.prototype.$echarts = echarts

Vue.config.productionTip = false
// mounted () {
//   document.dispatchEvent(new Event('render-event'))
// },
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
